import React from "react";
import Icon from "./Icon";
import colors from "../constants/colors";
import styles from "../constants/styles";

export default class StreamInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { img, SVG, title, subtitle, styleIcon, tag } = this.props;
    let {
      styleContainer,
      styleTextContainer,
      styleImg,
      styleTitle,
      styleTag,
      styleSubtitle,
    } = this.props;

    return (
      <div style={{ ...container, ...styleContainer }}>
        {styleImg && (
          <div style={{ ...styleIcon, borderRadius: 360 }}>
            <Icon
              height={styleImg.height}
              width={styleImg.width}
              img={img}
              SVG={SVG}
            />
          </div>
        )}
        <div
          style={{
            ...textContainer,
            ...styleTextContainer,
            ...textStyle,
            ...{ marginLeft: styleImg ? 12 : 0 },
          }}
        >
          <label
            style={{
              ...styles.text12,
              ...textStyle,
              ...{ marginBottom: 2 },
              ...styleTitle,
              ...{ height: "30%" },
            }}
          >
            {title}
          </label>
          <label
            style={{
              ...styles.text24Bold,
              ...textStyle,
              ...styleSubtitle,
              ...{ marginTop: 2 },
              ...{ height: "40%" },
            }}
          >
            {subtitle}
          </label>
          {tag && (
            <label
              style={{
                ...styles.text16,
                ...textStyle,
                ...styleTag,
                ...{ marginTop: 4, color: colors.blue },
                ...{ height: "30%" },
              }}
            >
              {tag}
            </label>
          )}
        </div>
      </div>
    );
  }
}

const container = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

const textContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "12px",
  height: "80%",
  width: "100%",
};

const textStyle = {
  position: "relative",
  overflow: "hidden",
};
