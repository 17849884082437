const parseErrors = (obj) => {
  if (!obj) {
    return "";
  }

  const result = Object.values(obj)
    .map((value) => {
      if (typeof value === "object") {
        return parseErrors(value);
      }
      return value;
    })
    .join("\n");

  return result;
};

export const parseAxiosError = (error) => {
  const axiosError =
    error?.response?.data?.error?.msg ||
    error?.response?.data?.debug?.message ||
    parseErrors(error?.data);
  if (axiosError) {
    return axiosError;
  }
  return error.message;
};
