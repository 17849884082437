import * as Yup from "yup";

export const model = {
  username: {
    name: "username",
    label: "username",
    placeholder: "usernamePlaceholder",
  },
  email: {
    name: "email",
    label: "Email",
    style: { marginTop: 40 },
    placeholder: "emailPlaceholder",
  },
  password: {
    name: "password",
    type: "password",
    label: "Password",
    style: { marginTop: 40 },
    placeholder: "maxSymbols",
  },
};

export const validationSchema = Yup.object().shape({
  [model.username.name]: Yup.string().required(),
  [model.password.name]: Yup.string().required().min(8).max(64),
  [model.email.name]: Yup.string().email().required(),
});

export const initialValues = {
  [model.username.name]: "",
  [model.password.name]: "",
  [model.email.name]: "",
};
