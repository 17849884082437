import { createSelector } from "reselect";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { URLS } from "../../api/constants";
import { tokens } from "../../utils/tokens";

import { apiQuery } from "../query";

const tagTypes = ["User"];

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: apiQuery,
  tagTypes,
  endpoints: (build) => ({
    me: build.query({
      query: () => ({
        url: URLS.ME,
        method: "get",
      }),
      invalidatesTags: tagTypes,
    }),
    login: build.mutation({
      query: ({ username, password }) => ({
        url: URLS.LOGIN,
        method: "post",
        body: { username, password },
      }),
      invalidatesTags: tagTypes,
    }),
    register: build.mutation({
      query: ({ email, username, password }) => ({
        url: URLS.REGISTER,
        method: "post",
        body: { email, username, password, role: "0" },
      }),
      invalidatesTags: tagTypes,
    }),
    delete: build.mutation({
      query: ({ userId, reason, feedback }) => ({
        method: "delete",
        body: { reason, feedback },
        url: `${URLS.DELETE_USER}/${userId}/`,
      }),
      invalidatesTags: tagTypes,
    }),
  }),
});

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    editUser: (state, { payload }) => ({ ...state, ...payload }),
    logout: (state) => ({ ...state, username: null, avatar: null, id: null }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.me.matchFulfilled,
      (state, { payload }) => ({ ...state, ...payload }),
    );
  },
});

const {
  actions: { logout, editUser },
} = userSlice;

export const logoutThunk = () => async (dispatch) => {
  try {
    await tokens.deleteTokens();
    dispatch(logout());

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const userStore = (store) => store.user;

export const selectUser = () => createSelector([userStore], (store) => store);
export const selectUserOverview = () =>
  createSelector([userStore], (store) => {
    const avatar = store?.img
      ? { uri: store?.img }
      : { uri: "https://i.imgur.com/An9lt5D.png" };

    return { username: store.username, avatar, id: store.id };
  });

export { logout, editUser };
export const {
  useMeQuery,
  useLazyMeQuery,
  useLoginMutation,
  useDeleteMutation,
  useRegisterMutation,
} = userApi;
