import React from "react";
import Header from "../../components/Header";
import StreamInfo from "../../components/StreamInfo";
import Status from "../../components/Status";
import Button from "../../components/Button";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";
import "../stream/Segment.css";
import colors from "../../constants/colors";
import styles from "../../constants/styles";
import api from "../../network/api";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import StreamInfoMain from "../../components/StreamInfoMain";
import utils from "../../utils/utils";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings.svg";

class VideoPage extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      leftItems: [],
      stream: {},
      meta: {},
      isApprovedChecked: false,
      isPendingChecked: false,
      commentsCount: 0,
      likesCount: 0,
      selected: 0,
    };
    this.isMobile = this.props.isMobile;
    this.offset = 0;
    this.hasMore = true;
    this.offsetRight = 0;
    this.hasMoreRight = true;
    this.stream_id = new URLSearchParams(window.location.search).get("id");
  }

  handleLeftResult = async (result) => {
    if (!result.error && result.length > 0) {
      this.hasMore = true;
      this.state.leftItems.push(...result);
      let data = this.state.leftItems;
      this.setState({ leftItems: data });
    } else {
      this.hasMore = false;
    }
  };

  loadMore = async () => {
    if (this.state.leftItems.length >= api.def_limit) {
      this.offset += api.def_limit;
      this.getLeftItems();
    }
  };

  async componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    let stream = await api.get_video(this.stream_id);
    this.setState({ stream: stream });
  };

  getLeftItems = async () => {};

  renderOverview = () => {
    const { t, isMobile } = this.props;
    return (
      <div style={{ paddingLeft: 80, paddingRight: 80, paddingTop: 40 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <StreamInfoMain
            onBackClick={this.goBack}
            styleImg={{ height: 104, width: 104 }}
            styleIcon={{ width: "20%" }}
            styleTitle={{ ...styles.text16, ...{ color: colors.gray } }}
            styleSubtitle={{ ...styles.text40Bold, ...{ marginTop: 12 } }}
            styleTag={styles.text16}
            isMobile={this.isMobile}
            img={this.state.stream.image_url}
            title={utils.getVideoSource(this.state.stream.source)}
            tag={this.state.stream.genre}
          />

          {isMobile ? (
            <Button
              variant={"light"}
              style={editStreamMobile}
              onClick={() => {
                this.props.history.push(
                  "/video_edit?id=" + this.state.stream.id,
                );
              }}
              SVG={SettingsIcon}
              title={t("editStream")}
            />
          ) : (
            <Button
              variant={"light"}
              style={editStream}
              onClick={() => {
                this.props.history.push(
                  "/video_edit?id=" + this.state.stream.id,
                );
              }}
              SVG={SettingsIcon}
            />
          )}
        </div>
      </div>
    );
  };

  renderInfo = () => {
    const { t, isMobile } = this.props;
    return (
      !this.state.stream.isBlocked && (
        <div style={this.isMobile ? liveContainerMobile : liveContainer}>
          <StreamInfo
            styleContainer={{ backgroundColor: "white", width: "100%" }}
            styleTitle={styles.text16Bold}
            styleSubtitle={{
              ...styles.text16,
              ...{ color: colors.gray, fontWeight: "normal" },
            }}
            title={this.state.stream.name}
            subtitle={this.state.stream.description}
          />
          <Button
            variant={"red"}
            onClick={() => window.open(this.state.stream.video, "_blank")}
            style={isMobile ? liveIndicatorMobile : liveIndicator}
            title={t("liveNow")}
          />
        </div>
      )
    );
  };

  handleChange = (index) =>
    this.setState({
      selected: index,
    });

  render() {
    const { t } = this.props;
    return (
      <div style={{ marginBottom: 80 }}>
        <Helmet>
          <title>{`${
            this.state.stream.name ? this.state.stream.name : ""
          } | ${t("title")}`}</title>
        </Helmet>

        <Header isMobile={this.isMobile} />
        {this.renderOverview()}
        {this.renderInfo()}
        {this.state.stream.isBlocked ? (
          <Status
            isMobile={this.isMobile}
            onClick={this.setApproved}
            status="blocked"
            text={this.state.stream.blocked_message}
          />
        ) : null}

        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}

export default withRouter(withTranslation()(VideoPage));

const liveContainer = {
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 24,
  paddingRight: 40,
  marginTop: 40,
  marginLeft: 80,
  marginRight: 80,
  height: 200,
  borderRadius: 4,
  backgroundColor: "white",
};
const liveContainerMobile = {
  display: "flex",
  flexDirection: "column",
  padding: 24,
  marginTop: 24,
  marginLeft: "5%",
  marginRight: "5%",
  minHeight: 112,
  borderRadius: 4,
  backgroundColor: "white",
};
const liveIndicator = {
  height: 32,
  width: 100,
  borderRadius: 4,
  alignSelf: "center",
};
const liveIndicatorMobile = {
  height: 32,
  width: 100,
  display: "inline-block",
  borderRadius: 4,
  marginTop: 24,
};

const editStream = {
  width: 120,
  marginLeft: "auto",
};
const editStreamMobile = {
  width: "100%",
  marginTop: 24,
};
