import React, { useState, useEffect } from "react";
import colors from "../constants/colors";
import Loader from "./Loader";

export default (props) => {
  let {
    src,
    styleImg,
    SVG,
    disabled,
    isLoading,
    variant,
    title,
    style,
    className,
    onClick,
  } = props;

  let background = colors.blue;
  if (variant === "red") {
    background = "#ED4545";
  } else if (variant === "light") {
    background = "#E0E9F6";
  } else if (variant === "light-red") {
    background = "#F8E7E7";
  }

  let backgroundHover = "#0047B3";
  if (variant === "red") {
    backgroundHover = "#C93131";
  } else if (variant === "light") {
    backgroundHover = "#C9DBF5";
  } else if (variant === "light-red") {
    backgroundHover = "#F8CECE";
  }

  let backgroundActive = "#003789";
  if (variant === "red") {
    backgroundActive = "#971F1F";
  } else if (variant === "light") {
    backgroundActive = "#A8C3EB";
  } else if (variant === "light-red") {
    backgroundActive = "#F5B2B2";
  }

  let color = "white";
  if (variant === "light") {
    color = "#0058DC";
  } else if (variant === "light-red") {
    color = "#ED4545";
  }

  const [styleBackground, setStyleBackground] = useState(background);

  useEffect(() => {
    setStyleBackground(background);
  }, [props, background]);

  if (isLoading) {
    return (
      <div
        className={className}
        style={{ ...btnStyle, ...style, ...{ background: "none" } }}
      >
        <Loader
          className={className}
          style={{ ...btnStyle, ...loaderStyle, ...{ background: "none" } }}
        />
      </div>
    );
  }

  return (
    <button
      className={className}
      onMouseDown={() => setStyleBackground(backgroundActive)}
      onMouseUp={() => setStyleBackground(background)}
      onMouseEnter={() => setStyleBackground(backgroundHover)}
      onMouseLeave={() => setStyleBackground(background)}
      style={{
        ...btnStyle,
        ...style,
        ...{
          color: color,
          background: styleBackground,
          opacity: disabled ? 0.5 : 1,
        },
      }}
      onClick={onClick}
    >
      {title}
      {src ? (
        <img style={{ ...imgStyle, ...styleImg }} src={src} alt="icon" />
      ) : null}
      {SVG ? <SVG style={{ ...imgStyle, ...styleImg }} /> : null}
    </button>
  );
};

const btnStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "center",
  alignItems: "center",
  height: 48,
  borderWidth: 0,
  borderRadius: 4,
  fontSize: 16,
  cursor: "pointer",
  outline: "none",
  fontWeight: 500,
  fontFamily: "AvenirNext-DemiBold",
  background: colors.blue,
  color: "white",
};

export const imgStyle = {
  marginRight: 6,
  height: 24,
  width: 24,
};

const loaderStyle = {
  width: 40,
};
