import React from "react";
import CommentItem from "./CommentItem";
import { ReactComponent as Send } from "../assets/images/send.svg";
import { ReactComponent as SendBlue } from "../assets/images/send-blue.svg";
import styles from "../constants/styles";
import BaseAlertableComponent from "./base/BaseAlertableComponent";
import api from "../network/api";
import Input from "./Input";
import InfiniteScroll from "react-infinite-scroller";

export default class Comments extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      items: [],
      managers: [],
      deleted: [],
    };
  }

  async componentDidMount() {
    let managers = await api.get_stream_managers_list(this.props.stream.id);
    this.setState({
      items: this.props.items,
      managers: managers,
    });
  }

  onDelete = (value) =>
    this.setState({
      customAlert: {
        msg: this.props.t("msgDeleteComment"),
        onSubmit: () => this.delete(value),
      },
    });

  onReport = (value) =>
    this.setState({
      customAlert: {
        msg: this.props.t("msgReportComment"),
        onSubmit: () => this.report(value),
      },
    });

  delete = async (value) => {
    let result = await api.delete_comment(value);
    if (!result.error) this.addDeleted(value);
    else this.setState({ errorMsg: result.error });
  };

  report = async (value) => {
    let result = await api.comments_report(value);
    if (!result.error)
      this.setState({ successMsg: this.props.t("commentReported") });
    else this.setState({ errorMsg: result.error });
  };

  send = async () => {
    let me = await api.get_me();
    let comment = {
      stream: this.props.stream.id,
      track: this.props.track_id,
      user: me.id,
      text: this.state.text,
    };
    let result = await api.stream_comment(comment);
    if (!result.error) {
      this.props.onUpdate(result);
      this.setState({ text: "" });
    }
  };

  handleComment = (event) => {
    this.setState({ text: event.target.value });
  };

  addDeleted = (item) => {
    let array = this.state.deleted;
    array.push(item);
    this.setState({ deleted: array });
  };

  render() {
    let { owner, managers } = this.props.stream;
    let { t, isMobile } = this.props;

    return (
      <div>
        <div style={isMobile ? inputContainerMobile : inputContainer}>
          <Input
            style={inputStyle}
            inputStyle={{ width: isMobile ? "100%" : "90%" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") this.send();
            }}
            onChange={this.handleComment}
            type={"text"}
            placeholder={"Leave Your Comment"}
            value={this.state.text}
          />
          {this.state.text.length <= 0 ? (
            <Send onClick={this.send} style={{ ...sendStyle, ...{} }} />
          ) : (
            <SendBlue
              onClick={this.send}
              style={{ ...sendStyle, ...{ cursor: "pointer" } }}
            />
          )}
        </div>
        <div style={{ marginTop: 24 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.props.loadMore}
            hasMore={this.props.hasMore}
          >
            {this.props.items &&
              this.props.items.map((el, index) => {
                return (
                  <CommentItem
                    t={this.props.t}
                    deleted={this.state.deleted.includes(el.id)}
                    isMobile={isMobile}
                    key={index.toString()}
                    onDelete={this.onDelete}
                    onReport={this.onReport}
                    isHost={el.user === owner}
                    isManager={
                      false /*(managers.every(item => this.state.managers.map(v => v.id).includes(item))
                                        && this.state.managers.every(item => item.user === el.user)) NOT WORKING */
                    }
                    item={el}
                  />
                );
              })}
          </InfiniteScroll>
          {this.props.items && this.props.items.length === 0 && (
            <label style={{ ...styles.text16, ...{ color: "#999999" } }}>
              {t("emptyComments")}
            </label>
          )}
          {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
        </div>
        {this.state.customAlert && this.renderCustomAlert()}
      </div>
    );
  }
}

const inputContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
};
const inputContainerMobile = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
};

const inputStyle = {
  width: "100%",
  height: 56,
};

const sendStyle = {
  height: 24,
  width: 24,
  position: "absolute",
  alignSelf: "center",
  right: 16,
  bottom: 12,
};
