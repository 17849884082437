import React from "react";

export default class Icon extends React.Component {
  render() {
    let { style, styleImg, height, width, img, SVG } = this.props;

    let background = img
      ? { backgroundColor: "transparent" }
      : { backgroundColor: "black" };
    return (
      <div style={{ ...imgContainer, ...{ height, width }, ...style }}>
        {img ? (
          <img
            style={{ ...imgStyle, ...{ height, ...background }, ...styleImg }}
            src={img}
            alt="icon"
          />
        ) : null}
        {SVG && !img ? (
          <div style={{ ...imgStyle, ...{ height } }}>
            <SVG style={{ ...imgStyle, ...{ height, width } }} />
          </div>
        ) : null}
      </div>
    );
  }
}

const imgContainer = {
  position: "relative",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  height: 64,
  width: 64,
  borderRadius: 360,
};

const imgStyle = {
  height: 64,
};
