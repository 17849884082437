import React from "react";
import { Helmet } from "react-helmet";
import styles from "../../constants/styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Terms extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t, isMobile } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {t("termsTitle")} | {t("title")}
          </title>
        </Helmet>
        <div
          style={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5%",
                  width: "90%",
                  marginBottom: 80,
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 80,
                  marginBottom: 80,
                  width: "50%",
                }
          }
        >
          <div style={{ display: "flex", alignItems: "center", marginTop: 40 }}>
            <label
              style={{ ...(isMobile ? styles.text24Bold : styles.text40Bold) }}
            >
              {t("termsTitle")}
            </label>
          </div>
          <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
            {t("terms.1")}
          </label>
          <label style={{ ...styles.text16, ...text }}>{t("terms.2")}</label>

          <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
            {t("terms.3")}
          </label>
          <label style={{ ...styles.text16, ...text }}>{t("terms.4")}</label>

          <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
            {t("terms.5")}
          </label>
          <label style={{ ...styles.text16, ...text }}>{t("terms.6")}</label>

          <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
            {t("terms.7")}
          </label>
          <label style={{ ...styles.text16, ...text }}>{t("terms.8")}</label>
        </div>
      </div>
    );
  }
}

const text = {
  marginTop: 24,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
};

export default withRouter(withTranslation()(Terms));
