import { createSelector } from "reselect";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: {},
  isReady: false,
  isPlaying: false,
  userLoading: false,
  isBuffering: false,
  isConnecting: false,
  isNetworkError: false,
  isIapConnected: false,
  currentStreamId: undefined,
  authorizationRequired: false,
  accountDeletedPopupRequired: false,
  teaTimer: { startDate: undefined, isTicking: false },
  playerTimer: { endDate: undefined, isTicking: false },
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setNetworkError: (state, { payload }) => {
      state.isNetworkError = payload;
    },
    setDeletedPopup: (state, { payload }) => {
      state.accountDeletedPopupRequired = payload;
    },
    setIapConnected: (state, { payload }) => {
      state.isIapConnected = payload;
    },
    setUserLoading: (state, { payload }) => {
      state.userLoading = payload;
    },
    setAuthorizationRequired: (state, { payload }) => ({
      ...state,
      authorizationRequired: payload,
    }),
    setSubscription: (state, { payload }) => ({
      ...state,
      subscription: payload,
    }),
    setPlaying: (state, { payload }) => ({
      ...state,
      isPlaying: payload,
    }),
    setReady: (state, { payload }) => ({
      ...state,
      isReady: payload,
    }),
    setBuffering: (state, { payload }) => ({
      ...state,
      isBuffering: payload,
    }),
    setConnecting: (state, { payload }) => ({
      ...state,
      isConnecting: payload,
    }),
    setCurrentStreamId: (state, { payload }) => ({
      ...state,
      currentStreamId: payload,
    }),
    setTeaTimer: (state, { payload }) => ({
      ...state,
      teaTimer: { ...state.teaTimer, ...payload },
    }),
    setPlayerTimer: (state, { payload }) => ({
      ...state,
      playerTimer: { ...state.playerTimer, ...payload },
    }),
  },
});

export const {
  actions: {
    setReady,
    setPlaying,
    setTeaTimer,
    setBuffering,
    setConnecting,
    setUserLoading,
    setPlayerTimer,
    setIapConnected,
    setDeletedPopup,
    setSubscription,
    setNetworkError,
    setCurrentStreamId,
    setAuthorizationRequired,
  },
} = appStateSlice;

const appStateStore = (store) => store.appState;

export const selectNetworkError = () =>
  createSelector([appStateStore], (store) => store.isNetworkError);

export const selectCurrentStreamId = () =>
  createSelector([appStateStore], (store) => store.currentStreamId);

export const selectIsBuffering = () =>
  createSelector([appStateStore], (store) => store.isBuffering);

export const selectIsConnecting = () =>
  createSelector([appStateStore], (store) => store.isConnecting);

export const selectIsPlaying = () =>
  createSelector([appStateStore], (store) => store.isPlaying);

export const selectIsReady = () =>
  createSelector([appStateStore], (store) => store.isReady);

export const selectSubscription = () =>
  createSelector([appStateStore], (store) => store.subscription);

export const selectTeaTimer = () =>
  createSelector([appStateStore], (store) => store.teaTimer);

export const selectPlayerTimer = () =>
  createSelector([appStateStore], (store) => store.playerTimer);

export const selectAuthorizationRequired = () =>
  createSelector([appStateStore], (store) => store.authorizationRequired);

export const selectUserLoading = () =>
  createSelector([appStateStore], (store) => store.userLoading);

export const selectIapConnected = () =>
  createSelector([appStateStore], (store) => store.isIapConnected);

export const selectAccountDeletedPopupRequired = () =>
  createSelector([appStateStore], (store) => store.accountDeletedPopupRequired);
