export default {
  getYoutubeId(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  },
  youtubeDurationToHMS(duration) {
    var a = duration.match(/\d+/g);

    if (
      duration.indexOf("M") >= 0 &&
      duration.indexOf("H") === -1 &&
      duration.indexOf("S") === -1
    ) {
      a = [0, a[0], 0];
    }

    if (duration.indexOf("H") >= 0 && duration.indexOf("M") === -1) {
      a = [a[0], 0, a[1]];
    }
    if (
      duration.indexOf("H") >= 0 &&
      duration.indexOf("M") === -1 &&
      duration.indexOf("S") === -1
    ) {
      a = [a[0], 0, 0];
    }

    duration = 0;

    if (a.length === 3) {
      duration = duration + parseInt(a[0]) * 3600;
      duration = duration + parseInt(a[1]) * 60;
      duration = duration + parseInt(a[2]);
    }

    if (a.length === 2) {
      duration = duration + parseInt(a[0]) * 60;
      duration = duration + parseInt(a[1]);
    }

    if (a.length === 1) {
      duration = duration + parseInt(a[0]);
    }
    let time = new Date(duration * 1000).toISOString().substr(11, 8);
    if (time.startsWith("00:")) time = time.substr(3);
    return time;
  },
  getParameterByName: (name, url) => {
    name = name.replace(/[\\[\\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  getStreamRole: (value) => {
    switch (value) {
      case "0":
        return "Radiostation";
      case "1":
        return "Dj";
      case "2":
        return "Rising Star";
      case "3":
        return "Podcast";
      default:
        return "";
    }
  },
  getVideoSource: (value) => {
    switch (value) {
      case "0":
        return "Youtube";
      case "1":
        return "Twitch";
      default:
        return "";
    }
  },
  getManagerRole: (value) => {
    switch (value) {
      case "0":
        return "Admin";
      case "1":
        return "Author";
      case "2":
        return "Dj";
      default:
        return "";
    }
  },
  getParam: (param) => new URLSearchParams(window.location.search).get(param),
  clearResponceMsg: (msg) => msg && msg.toString().replace(/[{}[\]\\"]/g, ""),
  getClearUrl: (url) => {
    let clearUrl;

    if (!url) return "";

    if (url.indexOf("//") <= 0) {
      return "";
    } else if (url.includes("/stats")) {
      clearUrl = url;
    } else {
      clearUrl = url.split("?")[0];
    }
    if (clearUrl.endsWith("index.html")) {
      clearUrl = clearUrl.substring(0, url.indexOf("index.html"));
    }
    if (!clearUrl.endsWith("/")) {
      clearUrl += "/";
    }
    return clearUrl;
  },
  shortNumber: (number, decPlaces) => {
    // 2 decimal places => 100, 3 => 1000, etc
    if (!number) return 0;
    if (!decPlaces) decPlaces = 1;
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "M", "B", "T"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return number;
  },
  getEndPoint: (url) => {
    if (!url) return "";

    if (url.indexOf("://") === -1) {
      return url;
    }
    const pathArray = url.split("/");
    var newPathname = "";
    for (var i = 3; i < pathArray.length; i++) {
      newPathname += "/";
      newPathname += pathArray[i];
    }
    return newPathname;
  },
};
