export default {
  text40Bold: {
    fontSize: 40,
    color: "#1B1B1B",
    fontWeight: "bold",
    fontFamily: "AvenirNext-Bold",
  },
  text24Bold: {
    fontSize: 24,
    color: "#1B1B1B",
    fontWeight: "bold",
    fontFamily: "AvenirNext-Bold",
  },
  text16Bold: {
    fontSize: 16,
    color: "#1B1B1B",
    fontWeight: "bold",
    fontFamily: "AvenirNext-Bold",
  },
  text16DemiBold: {
    fontSize: 16,
    color: "#1B1B1B",
    fontWeight: "normal",
    fontFamily: "AvenirNext-DemiBold",
  },
  text24: {
    fontSize: 24,
    color: "#1B1B1B",
    fontWeight: "normal",
    fontFamily: "AvenirNext-Medium",
  },
  text16: {
    fontSize: 16,
    color: "#1B1B1B",
    fontWeight: "normal",
    fontFamily: "AvenirNext-Medium",
  },
  text12: {
    fontSize: 12,
    color: "#1B1B1B",
    fontWeight: "normal",
    fontFamily: "AvenirNext-Medium",
  },
};
