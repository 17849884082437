import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import StreamItem from "../../components/StreamItem";
import VideoItem from "../../components/VideoItem";

import Footer from "../../components/Footer";
import TextButton from "../../components/TextButton";

import Header from "../../components/Header";
import roles from "../../constants/Roles";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { ReactComponent as VideoIcon } from "../../assets/images/Video.svg";
import { ReactComponent as EarsIcon } from "../../assets/images/ears.svg";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as StreamIcon } from "../../assets/images/radio.svg";
import { ReactComponent as PodcastIcon } from "../../assets/images/rss.svg";
import Button from "../../components/Button";
import Loading from "../../components/Loader";
import { isVideoEnabled } from "../../constants/config";
import styles from "../../constants/styles";
import {
  selectUserStreams,
  useLazyUserStreamsQuery,
} from "../../store/services/streams";
import { useMeQuery } from "../../store/services/user";
import "./MyStreams.css";

const streamLine = {
  display: "flex",
  flexWrap: "wrap",
  marginBottom: 48,
  marginLeft: 40,
  marginRight: 80,
  marginTop: 40,
};
const streamColumn = {
  display: "flex",
  marginBottom: 48,
  flexDirection: "column",
  marginLeft: "5%",
  marginRight: "5%",
};

const emptyContainer = {
  flex: 1,
  marginBottom: 48,
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const djStyle = {
  height: 220,
  width: 220,
  marginBottom: 40,
};

const djStyleMobile = {
  width: "100%",
  marginBottom: 40,
};

const MyStreams = ({ history, t, isMobile }) => {
  const { data: me } = useMeQuery();
  const [lastRole, setLastRole] = useState(
    localStorage.getItem("lastRole") ?? roles.podcast,
  );

  const [isSwitching, setSwitching] = useState(true);
  const [isEmpty, setEmpty] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [fetchStreams, { isFetching }] = useLazyUserStreamsQuery();
  const userStreams = useSelector(selectUserStreams());

  const currentData = useMemo(
    () => userStreams?.[lastRole] ?? [],
    [userStreams, lastRole],
  );

  useEffect(() => {
    const handle = async () => {
      if (me?.id) {
        setLoading(true);
        if (userStreams[lastRole] === undefined) {
          const { data } = await fetchStreams({
            userId: me?.id,
            role: lastRole,
          });
          setEmpty(!data?.length);
        } else {
          setEmpty(!userStreams[lastRole]?.length);
        }
        setLoading(false);
      }
      setSwitching(false);
    };

    handle();
  }, [me?.id, lastRole]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token === null) history.push("/login");
  }, [history]);

  const openCreateStream = () => {
    history.push(
      lastRole === roles.video
        ? "/createvideo"
        : `/createstream?role=${lastRole}`,
    );
  };

  const openStream = (el) => {
    setSwitching(true);
    switch (el.role) {
      case roles.dj:
        history.push(`/streamdj?id=${el.id}&role=${el.role}`);
        break;
      default:
        history.push(`/streamradiostation?id=${el.id}&role=${el.role}`);
        break;
    }
  };

  const openVideo = (el) => {
    history.push("/video?id=" + el.id);
  };

  const switchStreamType = (role) => {
    if (lastRole !== role) {
      localStorage.setItem("lastRole", role);
      setLastRole(role);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{t("titleHome")}</title>
      </Helmet>

      <Header history={history} isMobile={isMobile} notReturn={true} />

      <div
        className={isMobile ? "horizontal-offset-mobile" : "horizontal-offset"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "center" : "space-between",
            alignItems: "center",
          }}
        >
          <TextButton
            SVG={PodcastIcon}
            title={t("podcasts")}
            active={lastRole === roles.podcast}
            onClick={() => switchStreamType(roles.podcast)}
            style={
              isMobile
                ? { marginTop: 24, width: "100%" }
                : { width: 114, marginRight: 28 }
            }
          />

          <TextButton
            SVG={StreamIcon}
            title={t("streams")}
            style={{ ...{ width: isMobile ? "100%" : 100 } }}
            active={lastRole === roles.radiostation}
            onClick={() => switchStreamType(roles.radiostation)}
          />

          {isVideoEnabled && (
            <TextButton
              SVG={VideoIcon}
              title={t("videos")}
              active={lastRole === roles.video}
              onClick={() => switchStreamType(roles.video)}
              style={
                isMobile
                  ? { marginTop: 24, width: "100%" }
                  : { width: 100, marginLeft: 28 }
              }
            />
          )}

          <Button
            variant={"blue"}
            onClick={openCreateStream}
            style={
              isMobile
                ? { marginTop: 24, width: "100%" }
                : { width: 216, marginLeft: "auto" }
            }
            SVG={Plus}
            title={t("create")}
          />
        </div>
      </div>

      {(isFetching || isLoading) && (
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "65vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      )}

      {currentData?.length > 0 && (
        <div style={isMobile ? streamColumn : streamLine}>
          {currentData.map((el, index) =>
            lastRole === roles.video ? (
              <VideoItem
                t={t}
                item={el}
                id={el.id}
                isMobile={isMobile}
                key={index.toString()}
                onClick={() => openVideo(el)}
              />
            ) : (
              <StreamItem
                t={t}
                item={el}
                isMobile={isMobile}
                key={index.toString()}
                onClick={() => openStream(el)}
              />
            ),
          )}
        </div>
      )}

      {!isSwitching && !isFetching && isEmpty && (
        <div
          className={
            isMobile ? "my-streams-container-mobile" : "my-streams-container"
          }
          style={emptyContainer}
        >
          <div>
            <EarsIcon style={isMobile ? djStyleMobile : djStyle} />
          </div>
          <label style={{ ...styles.text24Bold }}>
            {t("streamWillBeHere")}
          </label>
          <label style={{ ...styles.text16, marginTop: 8 }}>
            {t("addStreamLabel")}
          </label>
        </div>
      )}

      <Footer t={t} />
    </div>
  );
};

export default withRouter(withTranslation()(MyStreams));
