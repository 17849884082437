import React from "react";
import colors from "../constants/colors";
import styles from "../constants/styles";
import api from "../network/api";

import { ReactComponent as Like } from "../assets/images/like-solid.svg";
import { ReactComponent as CommentsIcon } from "../assets/images/comments.svg";
import { ReactComponent as Eye } from "../assets/images/eye.svg";
import utils from "../utils/utils";

export default class VideoItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.fetchCommentsMeta();
  }

  fetchCommentsMeta = async () => {
    let json = await api.get_video_comments_size(this.props.id);
    if (!json.error && this._isMounted)
      this.setState({
        comments: json.response,
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let { image_url, name, isBlocked, likes, views } = this.props.item;
    console.log(this.props.item);
    let { isMobile } = this.props;
    let background = colors.white;
    let borderWidth = isBlocked ? 0 : 1;
    let borderColor = isBlocked ? "#ED4545" : "#E9E9E9";

    return (
      <div
        className={"clickable"}
        style={
          isMobile
            ? {
                ...containerMobile,
                ...{ background, borderWidth, borderColor },
              }
            : { ...container, ...{ background, borderWidth, borderColor } }
        }
        onClick={this.props.onClick}
      >
        <div style={imgContainer}>
          <img
            style={{
              width: "100%",
              height: "134%",
              position: "relative",
              top: "-17%",
              resizeMode: "center",
              alignSelf: "center",
            }}
            src={image_url}
            alt="icon"
          />
        </div>

        <label
          style={{
            color: colors.black,
            textAlign: 12,
            fontSize: 16,
            marginLeft: "5%",
            marginRight: "5%",
            marginTop: 12,
            marginBottom: 12,
            height: 52,
            width: "90%",
            overflow: "hidden",
          }}
        >
          {name}
        </label>

        <div style={statisticContainer}>
          <div style={statisticItem}>
            <Like style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {utils.shortNumber(likes.length)}
            </label>
          </div>

          <div style={{ ...statisticItem, ...statisticBorders }}>
            <CommentsIcon style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {utils.shortNumber(this.state.comments)}
            </label>
          </div>

          <div style={statisticItem}>
            <Eye style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {utils.shortNumber(views)}
            </label>
          </div>
        </div>
      </div>
    );
  }
}
const imgContainer = {
  width: "100%",
  height: 188,
  overflow: "hidden",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
};
const container = {
  height: 316,
  width: 400,
  display: "flex",
  flexDirection: "column",
  borderStyle: "solid",
  borderRadius: 16,
  marginLeft: 40,
  marginBottom: 40,
  cursor: "pointer",
  overflow: "hidden",
};

const containerMobile = {
  height: 316,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  borderStyle: "solid",
  borderRadius: 16,
  marginBottom: 24,
  cursor: "pointer",
  overflow: "hidden",
};

const statisticContainer = {
  height: 56,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  borderColor: "#E9E9E9",
  borderStyle: "solid",
  borderWidth: 1,
  borderLeft: 0,
  borderRight: 0,
};

const statisticItem = {
  width: "33.3%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const statisticImg = {
  height: 24,
  width: 24,
  marginRight: 8,
};

const statisticText = {
  fontSize: 16,
  color: "#1B1B1B",
  cursor: "pointer",
};

const statisticBorders = {
  borderColor: "#E9E9E9",
  borderStyle: "solid",
  borderWidth: 1,
  borderTop: 0,
  borderBottom: 0,
};
