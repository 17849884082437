import { createSelector } from "reselect";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastRole: {},
};

export const searchStateSlice = createSlice({
  name: "searchState",
  initialState,
  reducers: {
    setLastSearchRole: (state, { payload }) => ({
      ...state,
      lastRole: payload,
    }),
  },
});

const {
  actions: { setLastSearchRole },
} = searchStateSlice;

const searchState = (store) => store.searchState;

export const selectLastSearchRole = () =>
  createSelector([searchState], (store) => store.lastRole);

export { setLastSearchRole };
