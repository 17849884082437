module.exports = {
  white: "#fff",
  blue: "#0058DC",
  red: "red",
  lightBlue: "#E0E9F6",
  black: "#1B1B1B",
  trans: "#00000000",
  gray: "#999999",
  divider: "#E9E9E9",
};
