import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../assets/images/trash2.svg";
import ArrowBack from "../../components/ArrowBack";
import Input from "../../components/Input";
import Button from "../../components/Button";
import "./CreateStream.css";
import api from "../../network/api";
import StreamManageBase from "./StreamManageBase";
import styles from "../../constants/styles";
import colors from "../../constants/colors";
import DropDown from "../../components/DropDown";
import { withTranslation } from "react-i18next";
import CheckBox from "../../components/Checkbox";
import roles from "../../constants/Roles";

class EditStream extends StreamManageBase {
  constructor(props) {
    super(props);
    this.state.owner = "";
  }

  renderBlocked = () => {
    const { t } = this.props;
    return (
      <div
        onClick={() => this.setState({ isBlocked: !this.state.isBlocked })}
        style={{
          marginTop: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckBox checked={this.state.isBlocked} />
        <span style={{ ...styles.text16, marginLeft: 16 }}>
          {t(this.state.isPodcast ? "isBlockedPodcast" : "isBlocked")}
        </span>
      </div>
    );
  };
  refresh = async () => {
    let me = await api.get_me();
    let result = await api.get_stream_by_id(this.stream_id);
    let isPodcast = result.role === roles.podcast;
    this.setState({
      me: me,
      name: result.name,
      streamUrl: result.stream_url,
      isPodcast: isPodcast,
      streamUrls: isPodcast ? [] : [result.stream_url],
      parseUrl: result.parse_url,
      streamType: result.stream_type,
      description: result.description,
      genre: result.genre,
      role: result.role,
      img: { preview: result.img },
      web: result.webpage,
      facebook: result.facebook,
      twitter: result.twitter,
      instagram: result.instagram,
      isApproved: result.isApproved,
      errorMsg: undefined,
      successMsg: undefined,
      inviting: false,
      removing: false,
      isBlocked: result.isBlocked,
    });

    this.isOwner = result.owner === me.id;

    if (isPodcast) this.fetchPodcastTracks();

    this.fetchOwnerData(result.owner);
  };

  fetchPodcastTracks = async () => {
    const result = await api.get_podcast_tracks(this.stream_id);
    if (!result.error)
      this.setState({
        podcasts: result,
      });
    else
      this.setState({
        errorMsg: result.msg ? result.msg : result.error,
      });
  };

  fetchOwnerData = async (owner) => {
    const data = await api.get_user_by_id(owner);
    this.setState({ owner: data.email });
    this.refreshManagers();
  };

  submitStream = async () => {
    let {
      streamUrl,
      streamType,
      parseUrl,
      name,
      description,
      role,
      genre,
      imgFile,
      web,
      facebook,
      twitter,
      instagram,
      isBlocked,
      isPodcast,
      podcasts,
    } = this.state;

    let info = this.checkInfo();
    if (info) {
      this.setState({ errorMsg: info });
      return;
    }
    this.setState({ submitting: true });
    let me = await api.get_me();

    let data = new FormData();
    if (isPodcast) {
      role = role = roles.podcast;
      streamUrl = podcasts[0].url;
      streamType = "";
    }
    data.append("stream_id", this.stream_id);
    data.append("owner", me.id);
    data.append("stream_url", streamUrl);
    data.append("parse_url", parseUrl);
    data.append("name", name);
    data.append("description", description);
    data.append("role", role);
    data.append("genre", genre);
    data.append("webpage", web);
    data.append("stream_type", streamType);
    data.append("facebook", facebook);
    data.append("twitter", twitter);
    data.append("instagram", instagram);
    data.append("isBlocked", isBlocked ? 1 : 0);
    if (imgFile) data.append("img", imgFile);

    let result = await api.edit_stream(data);
    if (!result.error) {
      if (isPodcast && this.hasNewPodcastTracks) {
        result = await api.delete_podcast_tracks(this.stream_id);
        if (!result.error) {
          const tracks = podcasts.map((item) => ({
            ...item,
            stream: this.stream_id,
          }));
          result = await api.add_podcast_tracks(tracks);
        }
        if (!result.error) this.goBack();
        else
          this.setState({
            errorMsg: result.msg ? result.msg : result.error,
            submitting: false,
          });
      } else this.goBack();
    } else
      this.setState({
        errorMsg: result.msg ? result.msg : result.error,
        submitting: false,
      });
  };

  showDeleteDialog = () =>
    this.setState({
      customAlert: {
        msg: this.props.t(
          this.state.isPodcast ? "msgDeletePodcast" : "msgDeleteStream",
        ),
        onSubmit: this.delete,
      },
    });
  showLeaveDialog = () =>
    this.setState({
      customAlert: {
        msg: this.props.t(
          this.state.isPodcast ? "msgLeavePodcast" : "msgLeaveStream",
        ),
        onSubmit: this.leaveManager,
      },
    });
  delete = async () => {
    this.setState({ removing: true });
    let result = await api.delete_stream(this.stream_id);
    this.setState({ removing: false });
    if (!result.error) {
      this.props.history.push("/mystreams");
    } else {
      this.setState({ errorMsg: result.error });
    }
  };

  refreshManagers = async () => {
    let managers = await api.get_stream_managers_list(this.stream_id);
    let invites = await api.get_stream_invites(this.stream_id);

    let it = [];
    for (let i = 0; i < managers.length; i++) {
      let user = await api.get_user_by_id(managers[i].user);
      it.push({
        id: user.id,
        email: user.email,
        role: managers[i].role,
        isSend: true,
        isPending: managers[i].status !== "1",
      });
    }
    for (let i = 0; i < invites.length; i++) {
      it.filter((el) => el.email === invites[i].email).length === 0 &&
        it.push({
          id: undefined,
          email: invites[i].email,
          role: invites[i].role,
          isSend: true,
          isPending: true,
        });
    }
    this.setState({ managers: it });
  };

  submitManager = async (email, role) => {
    this.setState({ inviting: true });
    let result = await api.add_manager(this.stream_id, email, role);
    if (!result.error)
      this.setState({ successMsg: result.response, inviting: false });
    else this.setState({ errorMsg: result.error, inviting: false });
    this.refreshManagers();
  };

  addManager = () => {
    let { managers } = this.state;
    let newManager = { email: "", role: "0" };
    let it = [];
    managers.map((el) => it.push(el));
    it.push(newManager);
    this.setState({ managers: it });
  };

  removeManager = async (index) => {
    let { managers } = this.state;
    if (managers[index].email) {
      await api.delete_manager(this.stream_id, managers[index].email);
    }

    let it = managers.filter((el, i) => i !== index);
    this.setState({ managers: it });
  };

  leaveManager = async () => {
    this.setState({ removing: true });
    let result = await api.delete_manager(this.stream_id, this.state.me.email);
    this.setState({ removing: false });
    if (!result.error) {
      this.props.history.push("/mystreams");
    } else {
      this.setState({ errorMsg: result.error });
    }
  };

  renderHeader = () => {
    return (
      <div
        className={
          this.props.isMobile
            ? "my-streams-create-container-mobile"
            : "my-streams-create-container"
        }
      >
        <ArrowBack
          onClick={this.goBack}
          style={{ marginRight: this.isMobile ? 8 : 24 }}
        />
        <label style={{ ...styles.text40Bold }}>
          {this.props.t(this.state.isPodcast ? "Edit Podcast" : "editStream")}
        </label>
      </div>
    );
  };

  renderManagers = () => {
    let { managers, isApproved } = this.state;
    const { t } = this.props;
    return (
      <div>
        {isApproved && (
          <div
            style={
              this.isMobile
                ? {
                    width: "90%",
                    marginLeft: "5%",
                    display: "flex",
                    flexDirection: "column",
                  }
                : { paddingLeft: 80 }
            }
          >
            <div className={"create-stream-social-titile-container"}>
              <label style={{ ...styles.text24Bold }}>
                {t("streamManagers")}
              </label>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: this.isMobile ? "column" : "row",
              }}
            >
              {this.isMobile && (
                <label style={{ ...styles.text16 }}>{t("streamOwner")}</label>
              )}
              <Input
                name={"email"}
                disabled={true}
                inputStyle={{ color: colors.gray }}
                style={{ width: this.isMobile ? "100%" : 290 }}
                label={""}
                value={this.state.owner}
              />
              {!this.isMobile && (
                <label
                  style={{
                    ...styles.text16,
                    ...{ marginLeft: 40, alignSelf: "center" },
                  }}
                >
                  {t("streamOwner")}
                </label>
              )}
            </div>

            {managers.map((el, index) => {
              let { email, role, isSend, isPending } = el;
              let roles = [t("admin"), t("author"), t("dj")];
              return (
                <div
                  key={index}
                  style={
                    this.isMobile
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          marginTop: 24,
                        }
                      : { display: "flex", marginTop: 40 }
                  }
                >
                  <Input
                    name={"email"}
                    onChange={(e) => (email = e.target.value)}
                    disabled={isSend}
                    style={{ width: this.isMobile ? "100%" : 290 }}
                    inputStyle={{ color: isSend ? colors.gray : colors.black }}
                    label={""}
                    placeholder={t("email")}
                    value={isSend ? email : null}
                  />
                  {isSend ? (
                    <div style={{ display: "flex" }}>
                      <Input
                        name={"role"}
                        disabled={true}
                        style={
                          this.isMobile
                            ? { width: "86%" }
                            : { width: 290, marginLeft: 40 }
                        }
                        inputStyle={{ color: colors.gray }}
                        label={""}
                        placeholder={""}
                        value={roles[parseInt(role)]}
                      />
                      {this.isMobile && (
                        <TrashIcon
                          style={{
                            height: 36,
                            width: "10%",
                            padding: "0 2%",
                            alignSelf: "center",
                            marginTop: 10,
                          }}
                          onClick={() => this.removeManager(index)}
                        />
                      )}
                    </div>
                  ) : (
                    <DropDown
                      style={
                        this.isMobile
                          ? { width: "100%" }
                          : { width: 290, marginLeft: 40 }
                      }
                      options={roles}
                      onSelect={(key) => {
                        role = roles.indexOf(key.label);
                      }}
                      current={roles[parseInt(role)]}
                    />
                  )}
                  {!isSend && (
                    <div style={{ display: "flex" }}>
                      <Button
                        variant={"light"}
                        style={
                          this.isMobile
                            ? { marginTop: 10, width: "86%" }
                            : { width: 121, marginTop: 10, marginLeft: 40 }
                        }
                        title={t("invite")}
                        isLoading={this.state.inviting}
                        onClick={() => this.submitManager(email, role)}
                      />
                      {this.isMobile && (
                        <TrashIcon
                          style={{
                            height: 36,
                            width: "10%",
                            padding: "0 2%",
                            alignSelf: "center",
                            marginTop: 10,
                          }}
                          onClick={() => this.removeManager(index)}
                        />
                      )}
                    </div>
                  )}
                  {!this.isMobile && (
                    <TrashIcon
                      style={{
                        height: 24,
                        width: 24,
                        marginTop: 12,
                        marginLeft: 40,
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => this.removeManager(index)}
                    />
                  )}
                  {isPending && (
                    <label
                      style={{
                        ...styles.text16,
                        ...{
                          color: colors.gray,
                          marginLeft: this.isMobile ? 0 : 40,
                          marginTop: 12,
                          alignSelf: "center",
                        },
                      }}
                    >
                      {t("pending")}
                    </label>
                  )}
                </div>
              );
            })}
            {(this.state.errorMsg || this.state.successMsg) &&
              this.renderAlert()}
          </div>
        )}
      </div>
    );
  };

  renderButtons = () => {
    let { isApproved } = this.state;
    const { t } = this.props;
    return (
      <div style={{ marginTop: 40 }}>
        {isApproved && (
          <Button
            variant={"light"}
            onClick={this.addManager}
            style={
              this.isMobile
                ? { ...{ width: "90%", marginLeft: "5%" } }
                : { ...{ width: 211, marginLeft: 80 } }
            }
            title={t("addManager")}
          />
        )}
        <div
          className={
            this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
          }
          style={{ minHeight: 0 }}
        >
          <Button
            variant={"blue"}
            isLoading={this.state.submitting}
            onClick={this.submitStream}
            style={{ ...{ width: this.isMobile ? "100%" : 211 } }}
            styleImg={{ width: 16, height: 20, marginTop: 1 }}
            title={t(this.state.isPodcast ? "editPodcast" : "editStream")}
          />

          {this.renderTerms()}
        </div>
        <Button
          variant={"light-red"}
          isLoading={this.state.removing}
          style={{
            ...{
              width: this.isMobile ? "90%" : 211,
              marginLeft: this.isMobile ? "5%" : 80,
              marginTop: 40,
              marginBottom: 80,
            },
          }}
          onClick={this.isOwner ? this.showDeleteDialog : this.showLeaveDialog}
          title={
            this.isOwner
              ? t(this.state.isPodcast ? "deletePodcast" : "deleteStream")
              : t(this.state.isPodcast ? "leavePodcast" : "leaveStream")
          }
        />
      </div>
    );
  };
}

export default withRouter(withTranslation()(EditStream));
