import React from "react";
import StreamInfoMain from "./StreamInfoMain";
import Button from "./Button";
import { ReactComponent as SettingsIcon } from "../assets/images/settings.svg";

import colors from "../constants/colors";
import styles from "../constants/styles";
import { ReactComponent as Like } from "../assets/images/like-solid.svg";
import { ReactComponent as Heart } from "../assets/images/heart.svg";
import { ReactComponent as CommentsIcon } from "../assets/images/comments.svg";
import { ReactComponent as Headphones } from "../assets/images/headphones.svg";
import utils from "../utils/utils";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { id, img, name, role, genre, likes, favorites } = this.props.item;
    let { listeners } = this.props.meta;
    const { isMobile, notLike, t } = this.props;
    return (
      <div style={isMobile ? containerMobile : container}>
        <div style={{ display: "flex", width: isMobile ? "100%" : "50%" }}>
          <StreamInfoMain
            onBackClick={this.goBack}
            styleImg={{ height: 104, width: 104 }}
            styleIcon={{ width: "20%" }}
            styleTitle={{ ...styles.text16, ...{ color: colors.gray } }}
            styleSubtitle={{ ...styles.text40Bold, ...{ marginTop: 12 } }}
            styleTag={styles.text16}
            isMobile={isMobile}
            img={img}
            title={utils.getStreamRole(role)}
            subtitle={name}
            tag={genre}
          />
        </div>

        <div style={isMobile ? statsInfoMobile : statsInfo}>
          <div
            style={{ ...statisticContainer, width: isMobile ? "100%" : "80%" }}
          >
            <div
              style={{
                ...statisticItem,
                ...{ width: notLike ? "33.3%" : "25%" },
              }}
            >
              <Headphones style={statisticImg} />
              <label style={{ ...styles.text16, ...statisticText }}>
                {listeners ? listeners : 0}
              </label>
            </div>
            <div
              style={{
                ...statisticItem,
                ...statisticBorders,
                ...{ width: notLike ? "33.3%" : "25%" },
              }}
            >
              <CommentsIcon style={statisticImg} />
              <label style={{ ...styles.text16, ...statisticText }}>
                {this.props.comments}
              </label>
            </div>

            <div
              style={{
                ...statisticItem,
                ...statisticBorders,
                ...{ width: notLike ? "33.3%" : "25%" },
              }}
            >
              <Heart style={statisticImg} />
              <label style={{ ...styles.text16, ...statisticText }}>
                {favorites ? favorites.length : 0}
              </label>
            </div>

            {!notLike ? (
              <div
                style={{
                  ...statisticItem,
                  ...statisticBorders,
                  ...{ width: "25%" },
                }}
              >
                <Like style={statisticImg} />
                <label style={{ ...styles.text16, ...statisticText }}>
                  {likes ? likes.length : 0}
                </label>
              </div>
            ) : null}
          </div>
          {isMobile ? (
            <Button
              variant={"light"}
              style={editStreamMobile}
              onClick={() => {
                this.props.history.push(`/streamedit?id=${id}&role=${role}`);
              }}
              SVG={SettingsIcon}
              title={t("editStream")}
            />
          ) : (
            <Button
              variant={"light"}
              style={editStream}
              onClick={() => {
                this.props.history.push(`/streamedit?id=${id}&role=${role}`);
              }}
              SVG={SettingsIcon}
            />
          )}
        </div>
      </div>
    );
  }
}

const statsInfo = {
  display: "flex",
  justifyContent: "flex-end",
  width: "50%",
};
const statsInfoMobile = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
};
const container = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 80,
  paddingRight: 80,
  flexDirection: "row",
  minHeight: 160,
  borderWidth: 1,
  borderTopWidth: 0,
  borderStyle: "solid",
  borderColor: "#EDEDED",
};

const containerMobile = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "5%",
  paddingRight: "5%",
  flexDirection: "column",
  borderWidth: 1,
  borderTopWidth: 0,
  borderStyle: "solid",
  borderColor: "#EDEDED",
};

const statisticContainer = {
  display: "flex",
  height: 48,
  alignItems: "center",
  borderWidth: 1,
  borderStyle: "solid",
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderColor: colors.divider,
};

const statisticItem = {
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const statisticImg = {
  height: 24,
  width: 24,
  marginRight: 8,
};

const editStream = {
  width: "20%",
  marginLeft: 24,
};
const editStreamMobile = {
  width: "100%",
  marginTop: 24,
};
const statisticText = {
  fontSize: 16,
  color: "#1B1B1B",
};

const statisticBorders = {
  borderColor: "#E9E9E9",
  borderStyle: "solid",
  borderWidth: 1,
  borderTop: 0,
  borderRight: 0,
  borderBottom: 0,
};
