import React from "react";
import cx from "classnames";
import { ReactComponent as LoaderIcon } from "../../assets/images/loader.svg";

import "./style.scss";

const Loading = ({ className, style }) => {
  const loadingClass = cx("loading", className);

  return (
    <div className={loadingClass} style={style}>
      <LoaderIcon />
    </div>
  );
};

export default Loading;
