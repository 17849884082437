import React from "react";

export default class InputIcon extends React.Component {
  render() {
    let icon = this.props.icon;
    let SVG = this.props.svg;

    return (
      <div style={this.props.style}>
        {icon && !SVG ? (
          <img style={ic} src={icon} alt={"iconed-input"} />
        ) : (
          <SVG style={ic} />
        )}

        <input
          style={{ ...inputStyle, ...this.props.inputStyle }}
          onChange={this.props.onChange}
          type={this.props.type ? this.props.type : "text"}
          placeholder={this.props.placeholder}
          name={this.props.name}
          value={this.props.value}
        />
      </div>
    );
  }
}

const inputStyle = {
  paddingLeft: 44,
};

const ic = {
  position: "absolute",
  height: 24,
  width: 24,
  marginTop: 20,
  marginLeft: 12,
};
