import React from "react";
import { Alert } from "../";
import utils from "../../utils/utils";

export default class BaseAlertableComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: undefined,
      successMsg: undefined,
      customAlert: undefined,
    };
  }
  onSuccess = () => {};

  goBack = () => {
    this.props.history.goBack();
  };
  renderAlert = () => {
    const { t } = this.props;
    return (
      <Alert
        error={this.state.errorMsg !== undefined}
        success={this.state.successMsg !== undefined}
        title={this.state.successMsg ? this.state.successMsg : t("error")}
        message={utils.clearResponceMsg(this.state.errorMsg)}
        onConfirm={() => {
          if (this.state.successMsg !== undefined) {
            this.onSuccess();
          }
          this.setState({
            errorMsg: undefined,
            successMsg: undefined,
          });
        }}
      />
    );
  };

  renderCustomAlert = () => {
    const { t, isMobile } = this.props;
    return (
      this.state.customAlert && (
        <Alert
          isMobile={isMobile}
          title={
            this.state.customAlert.title
              ? this.state.customAlert.title
              : t("titleConfirmation")
          }
          message={this.state.customAlert.msg}
          onConfirm={this.state.customAlert.onSubmit}
          positiveBtn={{
            title: t("yes"),
            onClick: () => {
              this.state.customAlert.onSubmit();
              this.setState({
                customAlert: undefined,
              });
            },
          }}
          negativeBtn={{
            title: t("cancel"),
            onClick: () => {
              this.setState({
                customAlert: undefined,
              });
            },
          }}
        />
      )
    );
  };
}
