import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import FacebookButton from "../../components/FacebookButton";
import colors from "../../constants/colors";
import "./Auth.css";
import utils from "../../utils/utils";
import api from "../../network/api";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";
import Icon from "../../components/Icon";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";

class AcceptManager extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      stream: {},
      owner: {},
      role: "",
      email: "",
      login: "",
      password: "",
      step: 0,
    };

    this.secret = utils.getParam("secret");
  }

  async componentDidMount() {
    let invite = await api.get_stream_invites_by_secret(this.secret);
    let stream = await api.get_stream_by_id(invite.to_id);
    let owner = await api.get_user_by_id(stream.owner);
    this.setState({
      owner: owner,
      stream: stream,
      email: invite.email,
      role: invite.role,
    });
  }

  onSignup = async () => {
    const { t } = this.props;
    if (this.state.password.length < 8) {
      this.setState({ errorMsg: t("signPassLengthError") });
      return;
    }

    let result = await api.register(
      this.state.email,
      this.state.login,
      this.state.password,
    );
    if (!result.error) this.accept(result.token);
    else this.setState({ errorMsg: result.msg });
  };

  onLogin = async () => {
    let result = await api.login(this.state.email, this.state.password);
    if (!result.error) this.accept(result.token);
    else this.setState({ errorMsg: result.msg ? result.msg : result.error });
  };

  accept = async (token) => {
    localStorage.setItem("token", token);
    const { t } = this.props;
    let accept = await api.accept_manager_invite(this.secret);
    if (accept.error) this.setState({ errorMsg: t("inviteError") });

    this.setState({ isAuthorized: true });
  };

  openStreams = () => {
    this.props.history.push("/mystreams");
  };

  render() {
    let { step, owner, stream, role } = this.state;
    let { t } = this.props;

    if (this.state.isAuthorized) this.openStreams(); //return <Redirect to='mystreams' />
    return (
      <div style={{ display: "flex", marginTop: 80, marginBottom: 80 }}>
        <Helmet>
          <title>{t("titleAcceptInvite")}</title>
        </Helmet>
        <div style={{ display: "flex", marginLeft: 80 }}>
          <div className={"form"}>
            <Logo
              style={{ width: 274, height: 48, marginBottom: 80 }}
              alt={"logo-image"}
            />
            <div style={{ marginBottom: 40 }}>
              <label style={styles.text40Bold}>{t("joinUs")}</label>
              <div>
                <label
                  style={{
                    ...styles.text16,
                    ...{ marginTop: 8, lineHeight: 1.5 },
                  }}
                >
                  {t("userOffset")}
                  <span style={styles.text16Bold}>{owner.username + " "}</span>
                  {t("invitedOffset")}
                  <span style={styles.text16Bold}>{`"${stream.name}" `}</span>
                  {t("streamOffset")}
                  <span style={styles.text16Bold}>
                    {utils.getManagerRole(role)}
                  </span>
                </label>
              </div>
            </div>

            {step === 0 && (
              <Input
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    step === 0 ? this.onSignup() : this.onLogin();
                }}
                name={"username"}
                onChange={(event) =>
                  this.setState({ login: event.target.value })
                }
                placeholder={t("usernamePlaceholder")}
                label={t("username")}
              />
            )}

            <Input
              name={"email"}
              inputStyle={{ color: colors.gray }}
              disabled={true}
              style={{ marginTop: 40 }}
              placeholder={t("emailPlaceholder")}
              label={t("email")}
              value={this.state.email}
            />

            <Input
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  step === 0 ? this.onSignup() : this.onLogin();
              }}
              name={"password"}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              type={"password"}
              style={{ marginTop: 40 }}
              placeholder={t("maxSymbols")}
              label={t("password")}
            />
            <div className={"buttons-container"}>
              <Button
                variant={"blue"}
                onClick={step === 0 ? this.onSignup : this.onLogin}
                style={{ width: 400, height: 56 }}
                title={step === 0 ? t("signupAsManager") : t("acceptInvite")}
              />
            </div>
            <div className={"divider-container"}>
              <div className={"line"} />
              <label style={{ ...styles.text16, ...{ color: colors.gray } }}>
                or
              </label>
              <div className={"line"} />
            </div>

            <FacebookButton style={{ marginTop: 40 }} onSuccess={this.accept} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 80,
              }}
            >
              <label
                style={{
                  ...styles.text16,
                  ...{ color: "black", alignSelf: "center" },
                }}
              >
                {step === 0 ? t("haveAccount") : t("noAccount")}
              </label>
              <Button
                variant={"light"}
                onClick={() => this.setState({ step: step === 0 ? 1 : 0 })}
                style={{ width: 160 }}
                title={step === 0 ? t("login") : t("signup")}
              />
            </div>
          </div>
          <div style={{ height: 40 }} />
        </div>
        {!this.props.isMobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: 150,
            }}
          >
            <Icon
              style={{
                borderRadius: 0,
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
              }}
              img={stream.img}
            />
          </div>
        )}
        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}

export default withRouter(withTranslation()(AcceptManager));
