class Tokens {
  static instance = null;

  accessTokenKey = "token";
  refreshTokenKey = "refreshToken";

  constructor() {
    if (Tokens.instance) {
      return Tokens.instance;
    }

    Tokens.instance = this;
    return this;
  }

  readAccessToken() {
    const accessToken = localStorage.getItem(this.accessTokenKey);
    return accessToken;
  }

  saveAccessToken(token) {
    localStorage.setItem(this.accessTokenKey, token);
  }

  deleteAccessToken() {
    localStorage.removeItem(this.accessTokenKey);
  }

  readRefreshToken() {
    const refreshToken = localStorage.getItem(this.refreshTokenKey);
    return refreshToken;
  }

  saveRefreshToken(token) {
    localStorage.setItem(this.refreshTokenKey, token);
  }

  deleteRefreshToken() {
    localStorage.removeItem(this.refreshTokenKey);
  }

  readTokens() {
    const access = this.readAccessToken();
    const refresh = this.readRefreshToken();
    return { access, refresh };
  }

  saveTokens({ access, refresh }) {
    this.saveAccessToken(access);
    this.saveRefreshToken(refresh);
  }

  deleteTokens() {
    this.deleteAccessToken();
    this.deleteRefreshToken();
  }
}

const tokens = new Tokens();

export { tokens };
