import React from "react";

import Dropdown from "../../components/DropDown";
import ArrowBack from "../../components/ArrowBack";
import Input from "../../components/Input";
import Button from "../../components/Button";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";
import Header from "../../components/Header";
import "../stream/CreateStream.css";
import utils from "../../utils/utils";
import api from "../../network/api";
import "../../components/Dropdown.css";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";
import sources from "../../constants/Sources";
import colors from "../../constants/colors";
import Autocomplete from "react-autocomplete";

import CheckBox from "../../components/Checkbox";

export default class VideoManageBase extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      videoUrl: undefined,
      description: "",
      genres: [],
      tags: [],
      tagSuggestions: [],
      tagsInput: "",
      source: sources.youtube,
      checking: false,
      submitting: false,
      checkedTerms: false,
      isLive: true,
      views: 0,
      duration: undefined,
      image: undefined,
      isBlocked: false,
    };
    this.pageTitle = this.props.t("addVideo");
    this.isMobile = this.props.isMobile;
    this.stream_id = new URLSearchParams(window.location.search).get("id");
  }

  renderBlocked = () => {};
  async componentDidMount() {
    this.getGenres();
  }

  getGenres = async () => {
    let genres = await api.get_genres();
    let sorted = genres.sort((a, b) => a.order - b.order).map((el) => el.name);
    this.setState({
      genres: sorted,
    });
  };

  submit = async () => {
    let {
      videoUrl,
      source,
      name,
      description,
      image,
      isLive,
      duration,
      views,
      isBlocked,
    } = this.state;
    let info = this.checkInfo();
    if (info) {
      this.setState({ errorMsg: info });
      return;
    }

    this.setState({ submitting: true });

    let me = await api.get_me();

    let data = new FormData();
    data.append("owner", me.id);
    data.append("video", videoUrl);
    data.append("name", name);
    data.append("description", description);
    data.append("source", source);

    data.append("isLive", isLive);
    data.append("duration", duration);
    data.append("views", views);
    data.append("image_url", image);
    data.append("isBlocked", isBlocked);

    let result = await this.sendData(data);
    if (!result.error) this.goBack();
    else
      this.setState({
        errorMsg: result.msg ? result.msg : result.error,
        submitting: false,
      });
  };

  sendTags = async (videoId) => {
    let { tags } = this.state;
    if (tags && tags.length > 0) {
      const sendData = {
        tags: tags.join(),
        video: videoId.toString(),
      };
      await api.add_tags(sendData);
    }
  };

  sendData = async (data) => {
    let video = await api.create_video(data);
    this.sendTags(video.id);
    return video;
  };

  checkInfo = () => {
    let { name, videoUrl, description, tags, checkedTerms } = this.state;
    const { t } = this.props;
    let message = [];
    if (!name) message.push(t("streamEmptyNameError"));
    if (!description) message.push(t("streamEmptyDescriptionError"));
    if (!tags) message.push(t("streamEmptyGenreError"));
    if (!videoUrl) message.push(t("streamUrlTypeError"));
    if (!checkedTerms) message.push(t("streamTermsError"));
    return message.join("\n");
  };

  checkUrl = async () => {
    let { videoUrl } = this.state;
    const { t } = this.props;
    if (videoUrl) {
      this.setState({
        checking: true,
      });
      let result = await api.yt_video_info(utils.getYoutubeId(videoUrl));

      if (!result.error && result.snippet) {
        this.setState({
          name: result.snippet.title,
          description: result.snippet.description,
          isLive: result.snippet.liveBroadcastContent === "live",
          views: result.statistics.viewCount,
          image: result.snippet.thumbnails.high.url,
          duration: utils.youtubeDurationToHMS(result.contentDetails.duration),
          checking: false,
        });
      } else {
        this.setState({
          errorMsg: t("streamUrlConnectError"),
          checking: false,
        });
      }
    } else {
      this.setState({
        errorMsg: t("streamEmptyUrlError"),
      });
    }
  };

  goTerms = (id) => {
    this.props.history.push("/terms");
  };

  goBack = () => {
    this.props.history.goBack();
  };

  searchTags = async (name) => {
    let result = await api.search_tags(name);
    if (!result.error && result.length > 0) {
      this.setState({
        tagSuggestions: result.map((el) => el.name),
      });
    }
  };
  renderHeader = () => {
    return (
      <div
        className={
          this.isMobile
            ? "my-streams-create-container-mobile"
            : "my-streams-create-container"
        }
      >
        <ArrowBack
          onClick={this.goBack}
          style={{ marginRight: this.isMobile ? 8 : 24 }}
        />
        <label
          style={
            this.isMobile ? { ...styles.text24Bold } : { ...styles.text40Bold }
          }
        >
          {this.pageTitle}
        </label>
      </div>
    );
  };

  renderTerms = () => {
    const { t } = this.props;
    return (
      <div
        onClick={() =>
          this.setState({ checkedTerms: !this.state.checkedTerms })
        }
        style={
          this.isMobile
            ? {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: 24,
                alignSelf: "center",
                width: "100%",
              }
            : {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "10%",
                alignSelf: "center",
                width: "50%",
              }
        }
      >
        <CheckBox checked={this.state.checkedTerms} />
        <span style={{ ...styles.text16, marginLeft: 16 }}>
          {t("agreeTo")}
          <a href="/terms" target="_blank">
            {t("termsTitle")}
          </a>
        </span>
      </div>
    );
  };

  renderButtons = () => {
    const { t } = this.props;
    return (
      <div
        className={
          this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
        }
        style={{ marginBottom: 80, minHeight: 0 }}
      >
        <Button
          variant={"blue"}
          isLoading={this.state.submitting}
          onClick={this.submit}
          style={{ ...{ width: this.isMobile ? "100%" : 211 } }}
          styleImg={{ width: 16, height: 20, marginTop: 1 }}
          title={t("submit")}
        />

        {this.renderTerms()}
      </div>
    );
  };

  render() {
    let {
      name,
      description,
      videoUrl,
      tagsInput,
      tags,
      source,
      tagSuggestions,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {name ? name : t("createStream")} | {t("title")}
          </title>
        </Helmet>

        <Header isMobile={this.isMobile} />
        {this.renderHeader()}

        <div
          className={
            this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
          }
        >
          <Input
            name={"parse_url"}
            onChange={(e) => {
              this.setState({
                videoUrl: e.target.value,
              });
            }}
            style={this.isMobile ? { width: "100%" } : { width: "75%" }}
            label={t("streamUrl")}
            placeholder={t("max200Symbols")}
            value={videoUrl}
          />
          <Button
            variant={"light"}
            isLoading={this.state.checking}
            onClick={this.checkUrl}
            style={
              this.isMobile
                ? { ...{ width: "100%", marginTop: 24 } }
                : { ...{ width: "20%", marginLeft: "5%" } }
            }
            title={t("checkLink")}
          />
        </div>

        {name && (
          <div>
            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <Input
                name={"stream_name"}
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ width: "100%" }}
                label={t("streamName")}
                placeholder={t("max32Symbols")}
                value={name}
              />
            </div>

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <Input
                name={"description"}
                multiline
                onChange={(e) => this.setState({ description: e.target.value })}
                style={{ width: "100%", maxWidth: 620 }}
                inputStyle={{ minHeight: 120 }}
                label={t("description")}
                placeholder={t("descPlaceholder")}
                value={description}
              />
            </div>

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
              style={{ flexDirection: "column" }}
            >
              <Dropdown
                title={t("source")}
                style={{ width: "100%", marginBottom: 24 }}
                onSelect={(key) => this.setState({ sources: key.value })}
                options={[
                  { value: sources.youtube, label: t("youtube") },
                  { value: sources.twitch, label: t("twitch") },
                ]}
                current={utils.getVideoSource(source)}
              />
            </div>

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <div
                className={"container"}
                style={this.isMobile ? { width: "100%" } : { width: "75%" }}
              >
                <label style={{ ...styles.text16, ...{ color: colors.gray } }}>
                  {t("addTags")}
                </label>
                <Autocomplete
                  getItemValue={(item) => item}
                  items={tagSuggestions}
                  renderItem={(item, isHighlighted) => (
                    <label
                      style={{
                        ...styles.text16,
                        ...{
                          color: colors.gray,
                          width: "94%",
                          padding: "3%",
                          background: isHighlighted ? "lightgray" : "white",
                        },
                      }}
                    >
                      {item}
                    </label>
                  )}
                  wrapperStyle={{ width: "100%" }}
                  inputProps={{ style: { width: "inherit" } }}
                  value={tagsInput}
                  onChange={(e) => {
                    let value = e.target.value;
                    this.setState({
                      tagsInput: value,
                    });
                    let values = value.split(",");
                    if (values.length > 0)
                      this.searchTags(values[values.length - 1]);
                  }}
                  onSelect={(val) => {
                    let values = tagsInput.split(",");
                    values[values.length - 1] = val;
                    this.setState({
                      tagsInput: values.join() + ",",
                    });
                  }}
                />
              </div>
              <Button
                variant={"light"}
                onClick={() => {
                  tags.push(
                    ...tagsInput
                      .split(",")
                      .map((e) => e.trim())
                      .filter((e) => e && !tags.includes(e)),
                  );
                  this.setState({
                    tags: tags,
                    tagsInput: "",
                  });
                }}
                style={
                  this.isMobile
                    ? { ...{ width: "100%", marginTop: 24 } }
                    : { ...{ width: "20%", marginLeft: "5%" } }
                }
                title={t("add")}
              />
            </div>

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
              style={tagsContainer}
            >
              {tags.map((tag, index) => (
                <div style={tagStyle} key={index.toString()}>
                  <div>{tag}</div>
                  <button
                    onClick={() => {
                      tags.splice(index, 1);
                      this.setState({
                        tags: tags,
                      });
                    }}
                    className={"close-tag-btn"}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              {this.renderBlocked()}
            </div>
          </div>
        )}
        <div style={dividerContainer}>
          <div className={"create-stream-divider"} />
        </div>

        {this.renderButtons()}

        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
        {this.state.customAlert && this.renderCustomAlert()}
      </div>
    );
  }
}

const dividerContainer = {
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
  height: 40,
  justifyContent: "center",
};

const tagsContainer = {
  marginTop: 10,
  minHeight: 0,
  flexWrap: "wrap",
};

const tagStyle = {
  display: "flex",
  flexDirection: "row",
  margin: 10,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: colors.blue,
  borderRadius: 20,
  color: colors.white,
};
