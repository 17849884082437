import React from "react";
import styles from "../constants/styles";

export default (props) => {
  const { t } = props;
  return (
    <div style={footer}>
      <label style={{ ...styles.text16 }}>{t("copyright")}</label>
      <div style={rightFooterItem}>
        <a
          href="/privacy"
          target="_blank"
          style={{ ...styles.text16DemiBold, ...privacyLabel }}
        >
          {t("titlePrivacyPolicy")}
        </a>
        <label style={{ ...styles.text16DemiBold, ...marginHorizontal8 }}>
          &#8226;
        </label>
        <a
          href="/terms"
          target="_blank"
          style={{ ...styles.text16DemiBold, ...privacyLabel }}
        >
          {t("termsTitle")}
        </a>
      </div>
    </div>
  );
};

const privacyLabel = {
  cursor: "pointer",
};

const marginHorizontal8 = {
  marginRight: 8,
  marginLeft: 8,
};

const rightFooterItem = {
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
};
const footer = {
  position: "fixed",
  display: "flex",
  width: "90%",
  bottom: 0,
  alignItems: "center",
  height: 48,
  background: "#fff",
  paddingLeft: "5%",
  paddingRight: "5%",
  borderTop: "1px solid rgba(236, 236, 236, 1)",
};
