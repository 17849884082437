import { createSelector } from "reselect";
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { URLS } from "../../api/constants";

import { apiQuery } from "../query";

const genresAdapter = createEntityAdapter({
  selectId: (item) => item.id ?? "",
});

const tagTypes = ["Genres"];

export const genresApi = createApi({
  reducerPath: "genresApi",
  baseQuery: apiQuery,
  tagTypes,
  endpoints: (build) => ({
    list: build.query({
      query: () => ({
        url: URLS.GET_GENRES,
        method: "get",
      }),
      providesTags: tagTypes,
    }),
  }),
});

export const genresSlice = createSlice({
  name: "genres",
  initialState: genresAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      genresApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        genresAdapter.setAll(state, payload);
      },
    );
  },
});

const genresStore = (store) => store.genres;

export const selectGenre = (id) =>
  createSelector([genresStore], (store) =>
    id ? store?.entities[id] : undefined,
  );

export const { useListQuery, useLazyListQuery } = genresApi;
