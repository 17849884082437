import { withRouter } from "react-router-dom";
import "./CreateStream.css";
import api from "../../network/api";
import "../../components/Dropdown.css";
import { withTranslation } from "react-i18next";
import StreamManageBase from "./StreamManageBase";
import roles from "../../constants/Roles";

class CreateStream extends StreamManageBase {
  submitStream = async () => {
    let {
      streamUrl,
      streamType,
      parseUrl,
      name,
      description,
      role,
      genre,
      imgFile,
      img,
      web,
      facebook,
      twitter,
      instagram,
      isPodcast,
      podcasts,
    } = this.state;
    let info = this.checkInfo();
    if (info) {
      this.setState({ errorMsg: info });
      return;
    }
    this.setState({ submitting: true });
    let me = await api.get_me();

    let data = new FormData();
    if (isPodcast) {
      role = roles.podcast;
      streamUrl = podcasts[0].url;
      streamType = "";
    }
    data.append("owner", me.id);
    data.append("stream_url", streamUrl);
    data.append("parse_url", parseUrl);
    data.append("name", name);
    data.append("description", description);
    data.append("role", role);
    data.append("genre", genre);
    data.append("img", imgFile ?? img?.preview);
    data.append("stream_type", streamType);
    if (web) data.append("webpage", web);
    if (facebook) data.append("facebook", facebook);
    if (twitter) data.append("twitter", twitter);
    if (instagram) data.append("instagram", instagram);

    let result = await api.create_stream(data);
    if (!result.error) {
      if (isPodcast) {
        const streamId = result.id;
        const tracks = podcasts.map((item) => ({ ...item, stream: streamId }));
        result = await api.add_podcast_tracks(tracks);
        if (!result.error)
          me.isSuperAdmin
            ? this.openStream({ id: streamId, role: role })
            : this.goApprove(streamId);
        else
          this.setState({
            errorMsg: result.msg ? result.msg : result.error,
            submitting: false,
          });
      } else
        me.isSuperAdmin
          ? this.openStream({ id: result.id, role: role })
          : this.goApprove(result.id);
    } else
      this.setState({
        errorMsg: result.msg ? result.msg : result.error,
        submitting: false,
      });
  };

  openStream = (el) => {
    switch (el.role) {
      case roles.dj:
        this.props.history.push("/streamdj?id=" + el.id);
        break;
      default:
        this.props.history.push("/streamradiostation?id=" + el.id);
        break;
    }
  };
}

export default withRouter(withTranslation()(CreateStream));
