import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Dropzone from "react-dropzone";
import { ReactComponent as Trash } from "../assets/images/trash.svg";
import { ReactComponent as Drag } from "../assets/images/drag-illustration.svg";
import Icon from "./Icon";
import styles from "../constants/styles";
import colors from "../constants/colors";
import Button from "./Button";

export default class MyDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      crop: {
        unit: "%",
        width: 500,
        aspect: 1 / 1,
      },
      img: undefined,
      imgFile: undefined,
    };
    this.isMobile = props.isMobile;
  }

  onChangeImage = (img, file) => {
    this.props.onChangeImage(img, file);
  };

  onDrop = (file) => {
    file = file[0];
    console.log(file);
    console.log(URL.createObjectURL(file));
    this.setState({
      img: Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
      file: file,
    });
    this.onChangeImage(this.state.img, this.state.file);
    if (false) {
      this.handleOpenModal();
    }
  };

  onDelete = () => {
    this.setState({
      img: undefined,
      file: undefined,
    });
    this.onChangeImage(this.state.img, this.state.file);
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName.replace(".", "");
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  };

  blobToFile = (theBlob) => {
    theBlob.lastModifiedDate = new Date();
    return theBlob;
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const blob = await this.getCroppedImg(
        this.imageRef,
        crop,
        `${Math.random().toString(24)}.jpeg`,
      );

      let file = this.blobToFile(blob);

      this.setState({
        img: Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        file: new File([blob], `${Math.random().toString(24)}.jpeg`),
      });
      await this.onChangeImage(this.state.img, this.state.file);
      this.handleCloseModal();
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  onCropComplete = async (crop) => {
    //this.makeClientCrop(crop);
  };

  render() {
    let { img } = this.props;

    return (
      <div style={this.props.style}>
        <Dropzone
          accept="image/*"
          multiple={false}
          onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={"dropzone"}>
                  <Icon
                    style={{
                      backgroundColor: "#FBFBFB",
                      borderRadius: 180,
                    }}
                    height={360}
                    width={360}
                    img={img && img.preview}
                  />
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {img && img.preview ? (
                      <div
                        onClick={this.onDelete}
                        style={{
                          display: "flex",
                          height: 48,
                          width: 48,
                          borderRadius: 8,
                          backgroundColor: "#ED4545",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Trash />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Drag style={{ height: 48, width: 48 }} />
                        <label
                          style={{
                            ...styles.text16,
                            ...{
                              textAlign: "center",
                              lineHeight: 1.5,
                              marginTop: 24,
                              color: colors.gray,
                            },
                          }}
                        >
                          Drag or click here to upload cover{<br />} Recommended
                          sizes 1024x1024 px
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <Modal
          open={this.state.showModal}
          onClose={this.handleCloseModal}
          center
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", height: 88 }}>
              <label style={{ ...styles.text24Bold }}>Crop Image</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 24,
              }}
            >
              <ReactCrop
                src={img && img.preview ? img.preview : null}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: this.isMobile ? "column" : "row",
                minHeight: 96,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <Button
                  style={
                    this.isMobile
                      ? { width: "100%", marginTop: 24 }
                      : { width: 160, marginRight: 24 }
                  }
                  variant={"light-red"}
                  title={"Delete"}
                  onClick={async () => {
                    await this.onDelete();
                    await this.onDelete();
                    this.handleCloseModal();
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: this.isMobile ? "column" : "row",
                }}
              >
                <Button
                  style={
                    this.isMobile
                      ? { width: "100%", marginTop: 24 }
                      : { width: 160, marginLeft: 24 }
                  }
                  variant={"blue"}
                  title={"Save"}
                  onClick={async () => {
                    await this.makeClientCrop(this.state.crop);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
