import utils from "../utils/utils";
import ReactGA from "react-ga";
import { Strings } from "../constants/strings";
import { API_URL } from "../api/constants";

const url_radio = API_URL;

const api_add_comment = "add_comment/";
const api_track_comments = "track_comments/";
const api_stream_news_manage = "stream_news_manage/";
const api_stream_manage = "stream_manage/";
const api_stream_managers = "stream_managers/";
const api_stream_managers_list = "stream_managers_list/";
const api_stream_invites = "stream_invites/";
const stream_urls = "stream_urls/";
const api_stream_invites_by_secret = "stream_invites_by_secret/";
const api_stream_like = "like_stream/";
const api_track_like = "like_track/";
const api_track_by_name = "track_by_name/";
const api_managers = "managers/";
const api_track_by_id = "tracks/";
const api_stream_tracks = "stream_tracks/";
const api_genres = "genres/";

const api_register = "register/";
const api_login = "login/";
const api_facebook_login = "facebook_login/";
const api_change_password = "change_password/";
const api_change_email = "change_email/";
const api_confirm_email = "confirm_email/";
const api_upload_avatar = "upload_avatar/";
const api_me = "me/";
const api_users = "users/";
const api_streams = "streams/";
const api_user_streams = "user_streams/";
const api_stream_news = "stream_news/";
const api_news = "news/";
const api_stream_comments = "stream_comments/";
const api_comments_report = "report_comment/";
const api_send_email = "send_email/";
const api_add_to_favorites = "add_to_favorites/";
const api_reset_password = "reset_password/";
const api_proxy = "proxy/";
const api_change_username = "change_username/";
const api_change_user_info = "change_user_info/";

const api_create_video = "cleate_video/";
const api_edit_video = "edit_video/";
const api_user_videos = "user_videos/";
const api_videos = "videos/";
const api_comments_size = "comments_size/";
const api_add_tags = "add_tags/";
const api_search_tags = "search_tags/";
const api_relative_tags = "relative_tags/";
const api_add_podcast_tracks = "add_podcast_tracks/";
const api_delete_podcast_tracks = "delete_podcast_tracks/";
const api_get_podcast_tracks = "get_podcast_tracks/";

const DEF_SELECTION_LIMIT = 10;

export default {
  def_limit: DEF_SELECTION_LIMIT,
  get_podcast_tracks: async (streamId) => {
    const response = await fetch(url_radio + api_get_podcast_tracks + streamId);
    return await handleResponce(response);
  },
  delete_podcast_tracks: async (streamId) => {
    const response = await fetch(
      url_radio + api_delete_podcast_tracks + streamId,
      {
        method: "DELETE",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  add_podcast_tracks: async (data) => {
    const response = await fetch(url_radio + api_add_podcast_tracks, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  yt_video_info: async (videoId) => {
    let url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${Strings.YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`;
    let response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await handleResponce(response);
    if (result && result.items && result.items[0]) {
      return await result.items[0];
    } else {
      return { error: "Error" };
    }
  },
  send_email: async (message) => {
    let response = await fetch(
      url_radio + api_send_email + `?message=${message}`,
    );
    if (response.ok) return "ok";
    else return "error";
  },

  register: async (email, login, password) => {
    const data = {
      email: email,
      username: login,
      password: password,
      role: "0",
    };

    const response = await fetch(url_radio + api_register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },

  login: async (login, password) => {
    const data = {
      username: login,
      password: password,
    };

    const response = await fetch(url_radio + api_login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },

  facebook_login: async (access_token, email) => {
    let data = {
      access_token,
    };
    if (email) {
      data.email = email;
    }

    const response = await fetch(url_radio + api_facebook_login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },

  change_password: async (oldPassword, newPassword) => {
    const data = {
      oldPassword,
      newPassword,
    };

    const response = await fetch(url_radio + api_change_password, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  change_email: async (newEmail) => {
    const data = {
      newEmail,
    };

    const response = await fetch(url_radio + api_change_email, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  confirm_email: async () => {
    const response = await fetch(url_radio + api_confirm_email, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    return await handleResponce(response);
  },
  get_confirm_email: async (secret) => {
    const response = await fetch(
      url_radio + api_confirm_email + "?secret=" + secret,
    );
    return await handleResponce(response);
  },

  create_stream: async (data) => {
    const response = await fetch(url_radio + api_stream_manage, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });

    return await handleResponce(response);
  },
  create_video: async (data) => {
    const response = await fetch(url_radio + api_create_video, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });

    return response.ok ? await handleResponce(response) : { error: "Error" };
  },
  add_tags: async (data) => {
    const response = await fetch(url_radio + api_add_tags, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await handleResponce(response);
  },
  get_relative_tags: async (id, selector) => {
    const response = await fetch(
      url_radio + api_relative_tags + "?" + selector + "=" + id,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  search_tags: async (name) => {
    const response = await fetch(
      url_radio + api_search_tags + "?name=" + name,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  edit_video: async (id, data) => {
    const response = await fetch(url_radio + api_edit_video + id + "/", {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return { error: response.ok ? "" : "Not found" };
  },
  delete_video: async (id) => {
    const response = await fetch(url_radio + api_edit_video + id + "/", {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return { error: response.ok ? "" : "Not found" };
  },
  fetch_user_videos: async () => {
    const response = await fetch(url_radio + api_user_videos, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });

    return await handleResponce(response);
  },
  get_video: async (id) => {
    const response = await fetch(url_radio + api_videos + id + "/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return await handleResponce(response);
  },
  create_news: async (data) => {
    const response = await fetch(url_radio + api_stream_news_manage, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });

    return await handleResponce(response);
  },

  get_stream_invites: async (stream) => {
    const response = await fetch(url_radio + api_stream_invites + stream + "/");
    return await handleResponce(response);
  },

  get_stream_invites_by_secret: async (secret) => {
    const response = await fetch(
      url_radio + api_stream_invites_by_secret + secret + "/",
    );
    return await handleResponce(response);
  },

  get_stream_managers_list: async (stream) => {
    const response = await fetch(
      url_radio + api_stream_managers_list + stream + "/",
    );
    return await handleResponce(response);
  },

  delete_comment: async (id) => {
    const response = await fetch(url_radio + api_stream_comments + id + "/", {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return await handleResponce(response);
  },

  accept_manager_invite: async (secret) => {
    let data = {
      secret,
    };
    const response = await fetch(url_radio + api_stream_managers, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  add_manager: async (stream_id, email, role) => {
    let data = new FormData();
    data.append("stream_id", stream_id);
    data.append("email", email);
    data.append("role", role);

    const response = await fetch(url_radio + api_stream_managers, {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });

    return await handleResponce(response);
  },
  delete_manager: async (stream_id, email) => {
    let data = new FormData();
    data.append("stream_id", stream_id);
    data.append("email", email);

    const response = await fetch(url_radio + api_stream_managers, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return await handleResponce(response);
  },
  edit_stream: async (data) => {
    const response = await fetch(url_radio + api_stream_manage, {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return await handleResponce(response);
  },
  delete_stream: async (stream_id) => {
    let data = new FormData();
    data.append("stream_id", stream_id);

    const response = await fetch(url_radio + api_stream_manage, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return await handleResponce(response);
  },
  edit_news: async (data) => {
    const response = await fetch(url_radio + api_stream_news_manage, {
      method: "PUT",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return await handleResponce(response);
  },
  delete_news: async (data) => {
    const response = await fetch(url_radio + api_stream_news_manage, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
    });
    return await handleResponce(response);
  },

  add_avatar: async (avatar) => {
    var body = new FormData();
    body.append("avatar", avatar);

    const response = await fetch(url_radio + api_upload_avatar, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: body,
    });
    return await handleResponce(response);
  },
  delete_avatar: async () => {
    const response = await fetch(url_radio + api_upload_avatar, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return await handleResponce(response);
  },
  get_me: async () => {
    try {
      const response = await fetch(url_radio + api_me, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const result = await handleResponce(response);
      if (result.id) {
        ReactGA.set({
          userId: result.id,
        });
      } else {
        localStorage.removeItem("token");
      }
      return result;
    } catch {
      localStorage.removeItem("token");
      return { id: null };
    }
  },

  get_managers: async () => {
    const response = await fetch(url_radio + api_managers);
    return await handleResponce(response);
  },

  get_user_by_id: async (user) => {
    const response = await fetch(url_radio + api_users + user + "/");
    return await handleResponce(response);
  },

  get_stream_by_id: async (stream) => {
    const response = await fetch(url_radio + api_streams + stream + "/");
    return await handleResponce(response);
  },

  get_news_by_stream: async (streamId, offset, limit) => {
    let fetchLimit = limit ? limit : DEF_SELECTION_LIMIT;
    let offsetLimit = offset ? offset : 0;
    const response = await fetch(
      url_radio +
        api_stream_news +
        streamId +
        "?offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
    );
    return await handleResponce(response);
  },

  get_news_by_id: async (id) => {
    const response = await fetch(url_radio + api_news + id);
    return await handleResponce(response);
  },

  get_user_streams: async (userId, role) => {
    const response = await fetch(
      url_radio + api_user_streams + userId + "?role=" + role,
    );
    return await handleResponce(response);
  },

  get_track_comments: async (track, offset, limit) => {
    let fetchLimit = limit ? limit : DEF_SELECTION_LIMIT;
    let offsetLimit = offset ? offset : 0;
    const response = await fetch(
      url_radio +
        api_track_comments +
        track +
        "?offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  get_video_comments: async (video, offset, limit) => {
    let fetchLimit = limit ? limit : DEF_SELECTION_LIMIT;
    let offsetLimit = offset ? offset : 0;
    const response = await fetch(
      url_radio +
        api_stream_comments +
        video +
        "?video=1&offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
    );
    return await handleResponce(response);
  },
  get_video_comments_size: async (videoId) => {
    const response = await fetch(url_radio + api_comments_size + videoId);
    return await handleResponce(response);
  },
  get_stream_comments: async (stream, offset, limit) => {
    let fetchLimit = limit ? limit : DEF_SELECTION_LIMIT;
    let offsetLimit = offset ? offset : 0;
    const response = await fetch(
      url_radio +
        api_stream_comments +
        stream +
        "?offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  get_tracks: async (stream, offset, limit) => {
    let fetchLimit = limit ? limit : DEF_SELECTION_LIMIT;
    let offsetLimit = offset ? offset : 0;
    console.log(
      "API--------->api_stream_tracks: ",
      url_radio +
        api_stream_tracks +
        "?offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
    );
    const response = await fetch(
      url_radio +
        api_stream_tracks +
        stream +
        "?offset=" +
        offsetLimit +
        "&limit=" +
        fetchLimit,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      },
    );
    return await handleResponce(response);
  },
  get_genres: async () => {
    const response = await fetch(url_radio + api_genres);
    return await handleResponce(response);
  },
  get_track_by_id: async (id) => {
    const response = await fetch(url_radio + api_track_by_id + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return await handleResponce(response);
  },
  stream_like: async (id) => {
    const data = {
      id: id,
    };

    const response = await fetch(url_radio + api_stream_like, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  track_like: async (track_name) => {
    const data = {
      track_name: track_name,
    };
    const response = await fetch(url_radio + api_track_like, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  stream_comment: async (comment) => {
    /*comment
        stream
        track
        user
        text */
    const response = await fetch(url_radio + api_add_comment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(comment),
    });
    return await handleResponce(response);
  },
  comments_report: async (id) => {
    const data = {
      id: id,
    };

    const response = await fetch(url_radio + api_comments_report, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  add_to_favorites: async (stream_id) => {
    const data = {
      stream_id: stream_id,
    };

    const response = await fetch(url_radio + api_add_to_favorites, {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  reset_password: async (email, code, newPassword) => {
    const data = {
      email,
      code,
      newPassword,
    };

    const response = await fetch(url_radio + api_reset_password, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await handleResponce(response);
  },
  get_stream_urls: async (parseUrl) => {
    const response = await fetch(
      url_radio + stream_urls + "?url=" + utils.getClearUrl(parseUrl),
    );
    const json = await response.json();
    if (json.streams)
      return {
        stream_type: json.type,
        data: json.streams,
        originUrl: json.origin,
      };
    else return { error: "No stream found" };
  },
  get_stream_meta: async (stream) => {
    const response = await fetch(
      url_radio +
        "metadata/?url=" +
        utils.getClearUrl(stream.parse_url) +
        "&stream_type=" +
        stream.stream_type +
        "&stream_id=" +
        stream.id +
        "&stream_url=" +
        stream.stream_url,
    );
    const json = await response.json();
    if (json.data) return json.data;
    else return { error: "No meta found" };
  },
  get_azura_nowplaying: async (url) => {
    const response = await fetch(url_radio + api_proxy + "?url=" + url);
    const json = await response.json();

    return json;
  },
  get_proxy_text: async (url) => {
    const response = await fetch(url_radio + api_proxy + "?url=" + url);
    return await response.text();
  },
  create_track_by_name: async (track_name, track_info, stream_id) => {
    const data = {
      track_name: track_name,
      track_info: track_info,
      stream_id: stream_id,
    };
    const response = await fetch(url_radio + api_track_by_name, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });

    return await handleResponce(response);
  },
  change_username: async (username) => {
    const data = {
      username,
    };
    const response = await fetch(url_radio + api_change_username, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    let result = await handleResponce(response);
    return await result;
  },
  change_user_info: async (info) => {
    const data = {
      info,
    };
    const response = await fetch(url_radio + api_change_user_info, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    });
    let result = await handleResponce(response);
    return await result;
  },
};

const handleResponce = async (response) => {
  if (response.ok) {
    // if HTTP-status is 200-299
    let result = await response.json();
    return result;
  } else {
    let result = {
      error: response.status,
      msg: await response.text(),
    };
    return result;
  }
};
