import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { commentApi } from "./services/comment";
import { appStateSlice } from "./services/appState";
import { userApi, userSlice } from "./services/user";
import { searchStateSlice } from "./services/searchState";
import { genresApi, genresSlice } from "./services/genres";
import { tracksApi, tracksSlice } from "./services/tracks";
import { streamApi, streamSlice } from "./services/streams";
import { privacyApi, privacySlice } from "./services/privacy";

export default configureStore({
  reducer: combineReducers({
    [userSlice.name]: userSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [tracksSlice.name]: tracksSlice.reducer,
    [streamSlice.name]: streamSlice.reducer,
    [genresSlice.name]: genresSlice.reducer,
    [privacySlice.name]: privacySlice.reducer,
    [tracksApi.reducerPath]: tracksApi.reducer,
    [streamApi.reducerPath]: streamApi.reducer,
    [genresApi.reducerPath]: genresApi.reducer,
    [appStateSlice.name]: appStateSlice.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [privacyApi.reducerPath]: privacyApi.reducer,
    [searchStateSlice.name]: searchStateSlice.reducer,
  }),

  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    userApi.middleware,
    genresApi.middleware,
    streamApi.middleware,
    tracksApi.middleware,
    commentApi.middleware,
    privacyApi.middleware,
  ],
});
