import React from "react";
import FacebookLoginWithButton from "react-facebook-login";
import api from "../network/api";
import styles from "../constants/styles";

export default function FacebookButton(props) {
  const responseFacebook = async (response) => {
    let result = await api.facebook_login(response.accessToken);
    if (result.token) props.onSuccess(result.token);
    else if (
      result.error &&
      result.error === "Email not found" &&
      props.onEmailNotFound
    ) {
      props.onEmailNotFound(response.accessToken);
    }
  };

  const onFacebook = () => {
    // TODO: Analytics
  };

  return (
    <FacebookLoginWithButton
      containerStyle={{ marginTop: 40 }}
      disableMobileRedirect={true}
      textButton={props.text}
      buttonStyle={{ ...styleButton, ...props.styleButton }}
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email"
      onClick={onFacebook}
      callback={responseFacebook}
      icon="fa-facebook"
    />
  );
}

const styleButton = {
  ...styles.text16DemiBold,
  display: "flex",
  width: 400,
  justifyContent: "center",
  alignItems: "center",
  background: "#3B5998",
  borderWidth: 0,
  borderRadius: 4,
  textTransform: "none",
  fontSize: 16,
  color: "white",
  padding: "13px 16px",
};
