import React from "react";
import { ReactComponent as Heart } from "../assets/images/heart.svg";
import { ReactComponent as CommentsIcon } from "../assets/images/comments.svg";
import { ReactComponent as Headphones } from "../assets/images/headphones.svg";
import { ReactComponent as Alert } from "../assets/images/alert-circle-red.svg";
import { ReactComponent as Clock } from "../assets/images/clock.svg";
import StreamInfo from "./StreamInfo";
import styles from "../constants/styles";
import colors from "../constants/colors";
import utils from "../utils/utils";

export default class StreamItem extends React.Component {
  render() {
    let {
      img,
      name,
      role,
      genre,
      isApproved,
      isBlocked,
      likes,
      comments,
      last_track_name,
      last_track_info,
      last_listeners,
    } = this.props.item;
    let { t, isMobile } = this.props;
    let background = isApproved ? "white" : "transparent";
    let borderWidth = isApproved && !isBlocked ? 0 : 1;
    let borderColor = isBlocked ? "#ED4545" : "#E9E9E9";

    return (
      <div
        className={"clickable"}
        style={
          isMobile
            ? {
                ...containerMobile,
                ...{ background, borderWidth, borderColor },
              }
            : { ...container, ...{ background, borderWidth, borderColor } }
        }
        onClick={this.props.onClick}
      >
        <div style={captionContainer}>
          <StreamInfo
            styleImg={{ height: 72, width: 72 }}
            styleTitle={{
              ...styles.text16,
              ...{ opacity: 0.6, cursor: "pointer" },
            }}
            styleSubtitle={{ ...styles.text24Bold, ...{ cursor: "pointer" } }}
            styleTag={{ ...styles.text16, ...{ cursor: "pointer" } }}
            img={img}
            title={utils.getStreamRole(role)}
            subtitle={name}
            tag={genre}
          />
        </div>
        <div style={statisticContainer}>
          <div style={statisticItem}>
            <Headphones style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {last_listeners}
            </label>
          </div>

          <div style={{ ...statisticItem, ...statisticBorders }}>
            <CommentsIcon style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {comments}
            </label>
          </div>

          <div style={statisticItem}>
            <Heart style={statisticImg} />
            <label style={{ ...styles.text16, ...statisticText }}>
              {likes}
            </label>
          </div>
        </div>
        {isApproved && !isBlocked && (
          <div style={statusContainer}>
            <StreamInfo
              styleTitle={{ ...styles.text16, ...{ cursor: "pointer" } }}
              styleSubtitle={{
                ...styles.text16,
                ...{ opacity: 0.6, cursor: "pointer" },
              }}
              title={last_track_name ? last_track_name : "Unknown"}
              subtitle={last_track_info?.length ? last_track_info : "Unknown"}
            />
          </div>
        )}
        {!isApproved && !isBlocked && (
          <div style={statusContainer}>
            <Clock style={statisticImg} />
            <label style={{ ...styles.text16, ...{ color: colors.gray } }}>
              {t("streamUnderReview")}
            </label>
          </div>
        )}
        {isBlocked && (
          <div style={{ ...statusContainer, ...{ background: "#F8E7E7" } }}>
            <Alert style={statisticImg} />
            <label style={{ ...styles.text16, ...{ color: "#ED4545" } }}>
              {t("rejected")}
            </label>
          </div>
        )}
      </div>
    );
  }
}

const container = {
  height: 248,
  width: 400,
  borderStyle: "solid",
  borderRadius: 16,
  marginLeft: 40,
  marginBottom: 40,
  cursor: "pointer",
  overflow: "hidden",
};

const containerMobile = {
  height: 248,
  width: "100%",
  borderStyle: "solid",
  borderRadius: 16,
  marginBottom: 24,
  cursor: "pointer",
  overflow: "hidden",
};
const captionContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: 24,
  height: 112,
};

const statisticContainer = {
  height: 56,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  borderColor: "#E9E9E9",
  borderStyle: "solid",
  borderWidth: 1,
  borderLeft: 0,
  borderRight: 0,
};

const statisticItem = {
  width: "33.3%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const statisticImg = {
  height: 24,
  width: 24,
  marginRight: 8,
};

const statisticText = {
  fontSize: 16,
  color: "#1B1B1B",
  cursor: "pointer",
};

const statisticBorders = {
  borderColor: "#E9E9E9",
  borderStyle: "solid",
  borderWidth: 1,
  borderTop: 0,
  borderBottom: 0,
};

const statusContainer = {
  height: 80,
  paddingLeft: 24,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
};
