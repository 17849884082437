import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AcceptManager from "./routers/auth/AcceptManager";
import Login from "./routers/auth/Login";
import Signup from "./routers/auth/Signup";

import ForgotPassword from "./routers/auth/ForgotPassword";
import CreateStream from "./routers/stream/CreateStream";
import MyStreams from "./routers/stream/MyStreams";
import CreateVideo from "./routers/video/CreateVideo";
import EditVideo from "./routers/video/EditVideo";

import EditStream from "./routers/stream/EditStream";
import StreamDj from "./routers/stream/StreamDj";
import StreamInApprove from "./routers/stream/StreamInApprove";
import StreamRadiostation from "./routers/stream/StreamRadio";
import StreamTrack from "./routers/stream/StreamTrack";
import VideoPage from "./routers/video/VideoPage";

import "./App.scss";
import ConfirmEmail from "./routers/ConfirmEmail";
import Privacy from "./routers/privacy";
import EditProfile from "./routers/profile/EditProfile";
import EmailConfirmed from "./routers/stream/EmailConfirmed";
import PostAdd from "./routers/stream/PostAdd";
import PostEdit from "./routers/stream/PostEdit";
import Terms from "./routers/stream/Terms";
import { Provider } from "react-redux";
import store from "./store";

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  const { t } = useTranslation();
  const [width] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      if (width !== window.innerWidth) {
        window.location.reload();
      }
    }

    if (window.attachEvent) {
      window.attachEvent("onresize", handleResize);
    } else if (window.addEventListener) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [width]);

  const isMobile = width < 992;

  return (
    <HttpsRedirect>
      <Router history={history}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <Provider store={store}>
          <div>
            <div>
              <Switch>
                <Route exact path="/">
                  <Login isMobile={isMobile} />
                </Route>
                <Route path="/signup">
                  <Signup isMobile={isMobile} />
                </Route>
                <Route path="/confirmemail">
                  <ConfirmEmail isMobile={isMobile} />
                </Route>
                <Route path="/email_confirmed">
                  {" "}
                  {/* server link */}
                  <EmailConfirmed isMobile={isMobile} />
                </Route>
                <Route path="/accept_manager">
                  {" "}
                  {/* server link */}
                  <AcceptManager isMobile={isMobile} />
                </Route>
                <Route path="/login">
                  <Login isMobile={isMobile} />
                </Route>
                <Route path="/forgotpassword">
                  <ForgotPassword isMobile={isMobile} />
                </Route>
                <Route path="/mystreams">
                  <MyStreams isMobile={isMobile} />
                </Route>
                <Route path="/createstream">
                  <CreateStream isMobile={isMobile} />
                </Route>
                <Route path="/createvideo">
                  <CreateVideo isMobile={isMobile} />
                </Route>
                <Route path="/video_edit">
                  <EditVideo isMobile={isMobile} />
                </Route>
                <Route path="/streamedit">
                  <EditStream isMobile={isMobile} />
                </Route>
                <Route path="/streaminapprove">
                  <StreamInApprove isMobile={isMobile} />
                </Route>
                <Route path="/streamradiostation">
                  <StreamRadiostation isMobile={isMobile} />
                </Route>
                <Route path="/streamdj">
                  <StreamDj isMobile={isMobile} />
                </Route>
                <Route path="/video">
                  <VideoPage isMobile={isMobile} />
                </Route>
                <Route path="/streamtrack">
                  <StreamTrack isMobile={isMobile} />
                </Route>
                <Route path="/postadd">
                  <PostAdd isMobile={isMobile} />
                </Route>
                <Route path="/postedit">
                  <PostEdit isMobile={isMobile} />
                </Route>
                <Route path="/editprofile">
                  <EditProfile isMobile={isMobile} />
                </Route>
                <Route path="/terms">
                  <Terms isMobile={isMobile} />
                </Route>
                <Route path="/privacy">
                  <Privacy isMobile={isMobile} />
                </Route>
              </Switch>
            </div>
          </div>
        </Provider>
      </Router>
    </HttpsRedirect>
  );
}

export default App;
