import React, { useState, useImperativeHandle } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Icon from "./Icon";
import Dropdown from "./CustomDropdown";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as icon } from "../assets/images/edm-icon.svg";
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Logout } from "../assets/images/logout.svg";
import "./Header.css";
import { useMeQuery } from "../store/services/user";

export default React.forwardRef((props, ref) => {
  const { data: me, isFetching, refetch } = useMeQuery();

  const history = useHistory();
  const [isAuthorize, setIsAuthorize] = useState(localStorage.getItem("token"));
  const [isReturn, setReturn] = useState(false);

  useImperativeHandle(ref, () => ({
    update() {
      refetch();
    },
  }));

  const handleDropDown = (key) => {
    switch (key) {
      case "account":
        history.push("/editprofile");
        break;
      case "logout":
        localStorage.removeItem("token");
        if (window.FB) {
          window.FB.logout();
        }
        setIsAuthorize(false);
        break;
      default:
        break;
    }
  };

  const isMobile = props.isMobile;

  let defaultAvatar = me?.username ? icon : Logo;
  let Avatar = (
    <Icon
      width={32}
      height={32}
      img={me?.img ?? undefined}
      SVG={defaultAvatar}
      style={{ marginRight: isMobile ? 0 : 16 }}
    />
  );

  if (!isAuthorize) return <Redirect to="login" />;
  if (me && !me?.isConfirmed) return <Redirect to="confirmemail" />;
  if (isReturn && !props.notReturn) {
    return <Redirect to="mystreams" />;
  }

  return (
    <div className={isMobile ? "header-mobile" : "header"}>
      <Logo
        alt={"logo-image"}
        className={"logo-image"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setReturn(true);
        }}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          Icon={!isFetching ? Avatar : null}
          label={!isMobile ? me?.username : undefined}
          options={[
            { value: "account", label: "Account", SVG: Edit },
            { value: "logout", label: "Logout", SVG: Logout },
          ]}
          styleList={{ marginRight: isMobile ? 36 : 0 }}
          onChange={handleDropDown}
        />
      </div>
    </div>
  );
});
