import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../components/";
import utils from "../utils/utils";

export const useAlert = ({ isMobile = false, onSuccess }) => {
  const { t } = useTranslation();

  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [customAlert, setCustomAlert] = useState();

  const handleConfirm = useCallback(() => {
    if (successMsg !== undefined) {
      if (onSuccess) {
        onSuccess();
      }
    }
    setErrorMsg(undefined);
    setSuccessMsg(undefined);
  }, [successMsg, onSuccess]);

  const renderAlert = () => {
    return (
      <Alert
        error={errorMsg !== undefined}
        success={successMsg !== undefined}
        title={successMsg ? successMsg : t("error")}
        message={utils.clearResponceMsg(errorMsg)}
        onConfirm={handleConfirm}
      />
    );
  };

  const handleCustomAlertConfirm = useCallback(() => {
    if (customAlert?.onSubmit) {
      customAlert.onSubmit();
    }
    setCustomAlert(undefined);
  }, [customAlert]);

  const handleCustomAlertCancel = useCallback(() => {
    setCustomAlert(undefined);
  }, []);

  const renderCustomAlert = () => {
    return (
      customAlert && (
        <Alert
          isMobile={isMobile}
          title={customAlert.title ? customAlert.title : t("titleConfirmation")}
          message={customAlert.msg}
          onConfirm={customAlert.onSubmit}
          positiveBtn={{
            title: t("yes"),
            onClick: handleCustomAlertConfirm,
          }}
          negativeBtn={{
            title: t("cancel"),
            onClick: handleCustomAlertCancel,
          }}
        />
      )
    );
  };

  return {
    errorMsg,
    successMsg,
    setErrorMsg,
    renderAlert,
    setSuccessMsg,
    renderCustomAlert,
  };
};
