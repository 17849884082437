import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import NewPassword from "./NewPassword";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import api from "../../network/api";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import { linkStyle, linkContainerStyle } from "./Login";

import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";

class ForgotPassword extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      code: "",
      newPassword: "",
      newPassword2: "",
      step: 0,
      resetting: false,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handleCode = this.handleCode.bind(this);
  }

  onEmail = async () => {
    this.setState({ resetting: true });
    let result = await api.reset_password(this.state.email);
    this.setState({ resetting: false });
    if (!result.error) this.changeStep(1);
    else this.setState({ errorMsg: result.error });
  };

  onCode = async () => {
    this.setState({ resetting: true });
    let result = await api.reset_password(this.state.email, this.state.code);
    this.setState({ resetting: false });
    if (!result.error) this.changeStep(2);
    else this.setState({ errorMsg: result.error });
  };

  changeStep = (step) => {
    this.setState({ step });
  };

  openLogin = () => {
    this.props.history.push("/login");
  };

  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleCode(event) {
    this.setState({ code: event.target.value });
  }

  render() {
    let content;
    const { t, isMobile } = this.props;
    const backLogin = (
      <div style={linkContainerStyle}>
        <label
          style={{ ...styles.text16DemiBold, ...linkStyle }}
          onClick={() => this.openLogin()}
        >
          {t("backToLogin")}
        </label>
      </div>
    );

    if (this.state.step === 0) {
      content = (
        <div className={"container"}>
          <Helmet>
            <title>{t("titleForgotPswd")}</title>
          </Helmet>
          <div className={"logo-container"}>
            <Logo className={"auth-logo-image"} alt={"logo-image"} />
          </div>
          <div
            className={isMobile ? "form-container-mobile" : "form-container"}
          >
            <div className={isMobile ? "formMobile" : "form"}>
              <label style={{ ...styles.text16, textAlign: "center" }}>
                {t("descForgotPswd")}
              </label>
              <label style={{ ...styles.text16, textAlign: "center" }}>
                {t("descWillSendLink")}
              </label>
              <Input
                name={"email"}
                onChange={this.handleEmail}
                style={{ marginTop: 40 }}
                placeholder={t("emailPlaceholder")}
                label={t("email")}
                value={this.state.email}
              />
              <Button
                variant={"blue"}
                isLoading={this.state.resetting}
                onClick={this.onEmail}
                style={{ marginTop: 40 }}
                title={t("continue")}
              />

              {backLogin}
            </div>
          </div>
        </div>
      );
    } else if (this.state.step === 1) {
      content = (
        <div className={"container"}>
          <Helmet>
            <title>{t("titleResetCode")}</title>
          </Helmet>
          <div
            className={isMobile ? "logo-container-mobile" : "logo-container"}
          >
            <Logo className={"auth-logo-image"} alt={"logo-image"} />
          </div>
          <div
            className={isMobile ? "form-container-mobile" : "form-container"}
          >
            <div className={isMobile ? "formMobile" : "form"}>
              <label style={{ ...styles.text16 }}>{t("descResetCode")}</label>
              <Input
                name={"email"}
                onChange={this.handleCode}
                style={{ marginTop: 40 }}
                placeholder={t("codePlaceholder")}
                label={t("code")}
                value={this.state.code}
              />
              <Button
                variant={"blue"}
                isLoading={this.state.resetting}
                onClick={this.onCode}
                style={{ marginTop: 40 }}
                title={t("continue")}
              />

              {backLogin}
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <NewPassword
          isMobile={isMobile}
          email={this.state.email}
          code={this.state.code}
        />
      );
    }

    return (
      <div>
        {content}
        <Footer t={t} />
        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}
export default withRouter(withTranslation()(ForgotPassword));
