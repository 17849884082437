import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {
  return (
    <div className="container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          checked={props.checked}
          onClick={() => props.handleClick()}
          name={"1"}
          id={"1"}
        />
        <label className="label" htmlFor={"1"}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
