import React, { useState, useEffect } from "react";
import StreamInfo from "../components/StreamInfo";
import { ReactComponent as approved } from "../assets/images/status-approved.svg";
import { ReactComponent as pending } from "../assets/images/status-pending.svg";
import { ReactComponent as rejected } from "../assets/images/status-rejected.svg";
import styles from "../constants/styles";
import Button from "./Button";
import styled from "styled-components";
import Roles from "../constants/Roles";

const states = {
  approved: {
    icon: approved,
    bg_color: "#DFF2D4",
    title_color: "#5ABB22",
    subtitle_color: "#5ABB22",
    title: "Stream approved",
    subTitle:
      "Now you are able to share your music with thousands of people from our community.",
  },
  pending: {
    icon: pending,
    bg_color: "#FCEBD8",
    title_color: "#EC9D41",
    subtitle_color: "#f4c48d",
    title: "Stream pending for approval",
    subTitle:
      "Please wait. Soon administator confirms the data about your stream",
  },
  blocked: {
    icon: rejected,
    bg_color: "#F8E7E7",
    title_color: "#ED4545",
    subtitle_color: "#ED4545",
    title: "Stream has been rejected",
    subTitle: "Reason not entered",
  },
};

export default function (props) {
  const [status, setStatus] = useState({});
  const isMobile = props.isMobile;

  const handleType = (value) => {
    if (props.stream?.role === Roles.podcast) {
      return value?.replace("Stream", "Podcast").replace("stream", "podcast");
    }
    return value;
  };

  useEffect(() => {
    switch (props.status) {
      case "approved":
        setStatus(states.approved);
        break;
      case "pending":
        setStatus(states.pending);
        break;
      case "blocked":
        let it = states.blocked;
        it.subTitle = props.text;
        setStatus(it);
        break;
      default:
        break;
    }
  }, [props]);

  const Container = styled.div`
    display: flex;
    align-items: center;
    margin-right: 40px;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  `;

  return (
    <div
      onClick={props.onClick}
      style={
        isMobile
          ? { ...containerMobile, ...{ backgroundColor: status.bg_color } }
          : { ...container, ...{ backgroundColor: status.bg_color } }
      }
    >
      <StreamInfo
        styleContainer={{
          ...(isMobile
            ? { width: "100%", flexDirection: "column" }
            : { width: "70%" }),
        }}
        styleTextContainer={{
          ...(isMobile ? { width: "100%", marginTop: 24 } : { width: "80%" }),
        }}
        styleIcon={{ backgroundColor: "transparent" }}
        styleTitle={{ ...styles.text16Bold, ...{ color: status.title_color } }}
        styleSubtitle={{
          ...styles.text16,
          ...{ color: status.subtitle_color },
        }}
        styleImg={{ height: 64, width: 64 }}
        SVG={status.icon}
        title={handleType(status.title)}
        subtitle={handleType(status.subTitle)}
      />
      {props.status !== "blocked" ? (
        <Container style={{ marginRight: isMobile ? 0 : 40 }}>
          <Button
            style={{
              width: isMobile ? "100%" : 137,
              marginTop: isMobile ? 24 : 0,
              backgroundColor:
                props.status === "pending"
                  ? "rgb(244, 196, 141, 0.6)"
                  : "rgba(90, 187, 34, 0.2)",
              color:
                props.status === "pending" ? "rgb(236, 157, 65)" : "#5ABB22",
            }}
            title={"Dismiss"}
          ></Button>
        </Container>
      ) : null}
    </div>
  );
}

const container = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 40,
  marginRight: 80,
  marginLeft: 80,
  padding: 24,
  borderRadius: 4,
};

const containerMobile = {
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
  marginRight: "5%",
  marginLeft: "5%",
  padding: 24,
  borderRadius: 4,
};
