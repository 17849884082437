import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import api from "../../network/api";
import { Alert } from "../../components";
import Button from "../../components/Button";
import "./style.scss";

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmed: false,
      result: undefined,
      isLoading: false,
      isLogOut: false,
    };
  }

  async componentDidMount() {
    let me = await api.get_me();
    this.setState({ isConfirmed: me.isConfirmed });
    if (
      !me.isConfirmed &&
      (new Date().getTime() - new Date(me.created_at).getTime()) / 1000 <= 60
    ) {
      await this.send();
    }
  }

  send = async () => {
    this.setState({
      isLoading: true,
    });
    let result = await api.confirm_email();
    this.setState({
      result,
      isLoading: false,
    });
  };

  render() {
    let { isConfirmed, result, isLoading, isLogOut } = this.state;
    let { t } = this.props;

    if (isLogOut) return <Redirect to="login" />;
    if (isConfirmed) return <Redirect to="mystreams" />;
    return (
      <React.Fragment>
        <div className="confirm-email">
          <div className="confirm-email-header">
            <label className="text-40-bold">{t("confirmation")}</label>
          </div>
          <label className="text-24-bold">{t("confirmationTitle")}</label>
          <label className="text-16">{t("visitInbox")}</label>
          <div className="confirm-email-buttons">
            <Button
              className="confirm-email-send"
              title={t("send")}
              variant={"blue"}
              onClick={this.send}
              isLoading={isLoading}
            />
            {!isLoading && (
              <Button
                className="confirm-email-log-out"
                title={t("logout")}
                variant={"light-red"}
                onClick={() => {
                  localStorage.removeItem("token");
                  if (window.FB) {
                    window.FB.logout();
                  }
                  this.setState({ isLogOut: true });
                }}
              />
            )}
          </div>
        </div>
        {result && !result.error && (
          <Alert
            success
            title={t("emailSent")}
            message={t("visitInbox")}
            onConfirm={() => this.setState({ result: undefined })}
          />
        )}
        {result && result.error && (
          <Alert
            error
            title={t("error")}
            message={result.error}
            onConfirm={() => this.setState({ result: undefined })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(ConfirmEmail));
