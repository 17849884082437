import { createSelector } from "reselect";
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";

import { URLS } from "../../api/constants";

import { apiQuery } from "../query";

const tagTypes = ["Tracks"];

export const tracksApi = createApi({
  reducerPath: "tracksApi",
  tagTypes,
  baseQuery: apiQuery,
  endpoints: (build) => ({
    list: build.query({
      query: ({ id }) => ({
        url: `${URLS.STREAM_TRACKS}${id}`,
        method: "get",
      }),
      invalidatesTags: tagTypes,
    }),
  }),
});

export const tracksSlice = createSlice({
  name: "tracks",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      tracksApi.endpoints.list.matchFulfilled,
      (state, { payload }) => ({ ...state, tracks: payload }),
    );
  },
});

const tracksStore = (store) => store.tracks;

export const selectTracks = () =>
  createSelector([tracksStore], (store) => store.tracks || []);

export const { useListQuery } = tracksApi;
