import React, { useState, useEffect } from "react";
import colors from "../constants/colors";
import { imgStyle } from "./Button";
import "./svg.css";

export default (props) => {
  let { SVG, active, title, style, onClick } = props;

  const [isHover, setHover] = useState();

  const [isActive, setActive] = useState();

  useEffect(() => {
    setActive(active);
  }, [active]);

  const color = isActive ? colors.blue : colors.black;

  const borderColor = isActive
    ? colors.blue
    : isHover
    ? colors.black
    : colors.trans;

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ ...btnStyle, ...style, ...{ color } }}
      onClick={onClick}
    >
      <div style={btnSContainer}>
        {title}
        {SVG ? (
          <SVG
            className={isActive ? "svgBlue" : "svgBlack"}
            style={{ ...imgStyle }}
          />
        ) : null}
      </div>

      <div style={{ ...divider, ...{ background: borderColor } }}></div>
    </button>
  );
};
const divider = {
  width: "100%",
  height: 2,
  marginTop: 6,
};

const btnStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: 48,
  borderWidth: 0,
  fontSize: 16,
  cursor: "pointer",
  outline: "none",
  background: "none",
  fontWeight: 700,
  fontFamily: "AvenirNext-DemiBold",
};

const btnSContainer = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "center",
  alignItems: "center",
  borderBottomColor: "black",
  borderBottomWidth: 2,
};
