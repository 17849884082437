import { apiInstance } from "../api/instance";
import { parseAxiosError } from "../utils/error";

const axiosBaseQuery =
  ({ instance }) =>
  async ({ url, method, body }) => {
    try {
      const result = await instance({ url, method, data: body });
      return { data: result.data };
    } catch (axiosError) {
      return {
        error: {
          data: axiosError.response?.data,
          status: axiosError.response?.status,
          message: parseAxiosError(axiosError),
        },
      };
    }
  };

export const apiQuery = axiosBaseQuery({
  instance: apiInstance,
});
