export const parseAxiosError = (error) => {
  const axiosError =
    error?.response?.data?.error?.msg || error?.response?.data?.debug?.message;
  if (axiosError) {
    return axiosError;
  }
  return error.message;
};

export const getErrorMessage = (error) => {
  if (typeof error === "string") return error;
  return (
    error?.response?.data?.error?.msg ||
    error?.response?.data?.debug?.message ||
    error?.message ||
    "Something went wrong"
  );
};

export const controlError = (meta, name, label) => {
  let error = meta && meta.touched && meta.error;
  if (!error) return undefined;

  return error.replace(name, label || "Value");
};
