import { Formik } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { InputField } from "../../components";
import Button from "../../components/Button";
import FacebookButton from "../../components/FacebookButton";
import Footer from "../../components/Footer";
import colors from "../../constants/colors";
import { isFacebookLoginEnabled } from "../../constants/config";
import styles from "../../constants/styles";
import { useAlert } from "../../hooks";
import api from "../../network/api";
import { useRegisterMutation } from "../../store/services/user";
import {
  initialValues,
  model,
  validationSchema,
} from "../../yup-configs/signup";
import "./Auth.css";
import { linkContainerStyle, linkStyle } from "./Login";
import { parseAxiosError } from "../../utils/error";

function Registration({ t, isMobile }) {
  const history = useHistory();

  const [signup, mutation] = useRegisterMutation();
  const isLoading = mutation.isLoading;

  const [isAuthorized, setIsAuthorized] = useState(
    localStorage.getItem("token"),
  );
  const [fbRegisterToken, setFbRegisterToken] = useState(undefined);

  const { renderAlert, errorMsg, setErrorMsg } = useAlert({ isMobile });

  const onSignup = async ({ email, username, password }) => {
    if (fbRegisterToken) {
      const result = await api.facebook_login(fbRegisterToken, email);
      if (result.token) auth(result.token);
      else {
        setErrorMsg(result.error);
        setFbRegisterToken(undefined);
      }
      return;
    }

    if (password.length < 8) {
      setErrorMsg(t("signPassLengthError"));
      return;
    }

    const result = await signup({ email, username, password });
    if (!result.error) {
      await auth(result?.data?.token);
    } else {
      setErrorMsg(parseAxiosError(result.error));
    }
  };

  const auth = async (token) => {
    await localStorage.setItem("token", token);
    setIsAuthorized(true);
  };

  const openLogin = () => {
    history.push("/login");
  };

  const onSubmit = async (values) => {
    await onSignup(values);
  };

  if (isAuthorized) return <Redirect to="mystreams" />;

  return (
    <div className={"container"}>
      <Helmet>
        <title>{t("titleSignup")}</title>
      </Helmet>

      <div className={isMobile ? "logo-container-mobile" : "logo-container"}>
        <Logo className={"auth-logo-image"} alt={"logo-image"} />
      </div>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting, errors, submitForm }) => (
          <div
            className={isMobile ? "form-container-mobile" : "form-container"}
          >
            <div className={isMobile ? "formMobile" : "form"}>
              {!fbRegisterToken && (
                <InputField
                  onKeyDown={(e) => e.key === "Enter" && submitForm()}
                  {...model.username}
                />
              )}
              <InputField
                onKeyDown={(e) => e.key === "Enter" && submitForm()}
                {...model.email}
              />
              {!fbRegisterToken && (
                <InputField
                  onKeyDown={(e) => e.key === "Enter" && submitForm()}
                  {...model.password}
                />
              )}
              <div className={"buttons-container"}>
                <Button
                  variant={"blue"}
                  onClick={submitForm}
                  style={{ width: isMobile ? "100%" : 400, height: 56 }}
                  title={isLoading ? `${t("Loading")}...` : t("signup")}
                  disabled={
                    !dirty || isSubmitting || Object.keys(errors).length > 0
                  }
                />
              </div>
              {!fbRegisterToken && (
                <div className={"divider-container"}>
                  <div className={"line"} />
                  <label
                    style={{
                      ...styles.text16,
                      ...{ color: colors.gray, paddingBottom: 2 },
                    }}
                  >
                    {t("or")}
                  </label>
                  <div className={"line"} />
                </div>
              )}
              {isFacebookLoginEnabled && !fbRegisterToken && (
                <FacebookButton
                  styleButton={{ width: isMobile ? "100%" : "400px" }}
                  onSuccess={auth}
                  text={t("signupFB")}
                  onEmailNotFound={(token) => {
                    setFbRegisterToken(token);
                  }}
                />
              )}
              {!fbRegisterToken && (
                <div style={linkContainerStyle}>
                  <label style={{ ...styles.text16 }}>{t("haveAccount")}</label>
                  <label
                    style={{ ...styles.text16DemiBold, ...linkStyle }}
                    onClick={() => openLogin()}
                  >
                    {t("login")}
                  </label>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>

      <Footer t={t} />

      {errorMsg && renderAlert()}
    </div>
  );
}

export default withTranslation()(Registration);
