import React from "react";
import Icon from "./Icon";
import { ReactComponent as icon } from "../assets/images/edm-icon.svg";
import { ReactComponent as More } from "../assets/images/btn-more.svg";
import { ReactComponent as Report } from "../assets/images/report.svg";
import { ReactComponent as Delete } from "../assets/images/trash-red.svg";
import Dropdown from "../components/CustomDropdown";
import styles from "../constants/styles";
import colors from "../constants/colors";

import { format } from "date-fns";
import api from "../network/api";
import "./List.css";

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: undefined,
      img: undefined,
      isLoaded: false,
      isMenu: false,
    };
  }

  async componentDidMount() {
    let { user } = this.props.item;
    let result = await api.get_user_by_id(user);
    if (!result.error) {
      this.setState({
        username: result.username,
        avatar: result.img,
        isLoaded: true,
      });
    }
  }

  handleDropDown = async (key, value) => {
    switch (key) {
      case "report":
        this.props.onReport(value);
        break;
      case "delete":
        this.props.onDelete(value);
        break;
      default:
        break;
    }
  };

  render() {
    let { id, text, created_at } = this.props.item;
    let { t, deleted } = this.props;
    let lightStyle =
      this.props.isHost || this.props.isManager
        ? { color: colors.blue }
        : undefined;
    if (!this.state.isLoaded) return <div></div>;
    return (
      <div
        className="list-container"
        onMouseLeave={() => {
          if (!deleted) this.dropdownRef.close();
        }}
        onClick={() => {
          if (!deleted) this.dropdownRef.handleClick();
        }}
        style={{ cursor: "pointer", opacity: deleted ? 0.5 : 1.0 }}
      >
        <div style={{ marginLeft: 16 }}>
          <Icon
            height={40}
            width={40}
            img={this.state.avatar ? this.state.avatar : undefined}
            SVG={icon}
          />
        </div>
        <div style={textContainer}>
          {!deleted && (
            <div style={{ display: "flex", cursor: "pointer" }}>
              <label
                style={{
                  ...styles.text16Bold,
                  ...{ marginTop: 0, cursor: "pointer" },
                  ...lightStyle,
                }}
              >
                {this.state.username}
              </label>
              <label
                style={{
                  ...styles.text16,
                  ...{ marginLeft: 12, color: "gray", cursor: "pointer" },
                }}
              >
                {format(new Date(created_at), "hh:mm")}
              </label>
            </div>
          )}
          <label
            style={{
              ...styles.text16,
              ...{ marginBottom: 0, cursor: "pointer" },
            }}
          >
            {deleted ? t("commentDeleted") : text}
          </label>
        </div>
        {!deleted && (
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingRight: 16,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className="list-manageBox">
              <Dropdown
                ref={(ref) => (this.dropdownRef = ref)}
                Icon={
                  <div className="list-menu">
                    <More />
                  </div>
                }
                styleList={{ width: 180 }}
                options={[
                  { value: "delete", label: "Delete comment", SVG: Delete },
                  { value: "report", label: "Report spam", SVG: Report },
                ]}
                onChange={(key) => this.handleDropDown(key, id)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const textContainer = {
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  marginLeft: 16,
};
