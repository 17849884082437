import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as CommentsIcon } from "../../assets/images/comments.svg";
import { ReactComponent as LikeIcon } from "../../assets/images/like-solid.svg";
import { withTranslation } from "react-i18next";
import api from "../../network/api";

import StreamBase from "./StreamBase";
import Overview from "../../components/Overview";
import StreamInfo from "../../components/StreamInfo";
import styles from "../../constants/styles";
import colors from "../../constants/colors";
import InfiniteScroll from "react-infinite-scroller";

class StreamDj extends StreamBase {
  constructor(props) {
    super(props);
    this.getStreamComments();
    this.state.segments = true;
  }

  getStreamComments = async () => {
    let lResult = await api.get_stream_comments(this.stream_id);
    if (!lResult.error) {
      this.setState({
        commentsCount: lResult.length,
      });
    }
  };

  renderOverview = () => {
    return (
      <Overview
        t={this.props.t}
        isMobile={this.isMobile}
        item={this.state.stream}
        meta={this.state.meta}
        history={this.props.history}
        comments={this.state.commentsCount}
        notLike={true}
      />
    );
  };

  getLeftItems = async (loadMore) => {
    let res = await api.get_tracks(this.stream_id, this.offset);
    this.handleLeftResult(res, loadMore);
  };

  randomRGB = () =>
    new Array(3).fill(0).map((el) => Math.floor(Math.random() * 256));

  openTrack = (el) => {
    this.props.history.push(
      "/streamtrack?id=" + this.stream_id + "&track_id=" + el.id,
    );
  };

  Letter = (it) => {
    return (
      <div
        style={{
          height: 64,
          width: 64,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ ...styles.text24Bold, ...{ color: "white" } }}>
          {it ? it : ""}
        </span>
      </div>
    );
  };

  renderLeftPanel = () => {
    const { t, isMobile } = this.props;
    return (
      <div style={{ width: this.isMobile ? "100%" : "57%" }}>
        {!isMobile && (
          <div style={titleContainer}>
            <label style={{ ...styles.text24Bold }}>{t("tracks")}</label>
          </div>
        )}
        <div style={{ ...{ display: "flex", flexDirection: "column" } }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={this.hasMore}
          >
            {this.state.leftItems.map((el) => {
              let commentsCount = el.comments_count;
              let likesCount = el.likes.length;
              return (
                <div
                  key={el.id}
                  className={"clickable"}
                  onClick={() => this.openTrack(el)}
                  style={{
                    display: "flex",
                    flexDirection: this.isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    minHeight: 80,
                    marginTop: 16,
                    cursor: "pointer",
                    borderRadius: 4,
                  }}
                >
                  <StreamInfo
                    styleContainer={{
                      height: 80,
                      width: "80%",
                      marginLeft: 16,
                      cursor: "pointer",
                    }}
                    styleTextContainer={{ width: "70%" }}
                    styleTitle={{ ...styles.text16Bold, cursor: "pointer" }}
                    styleSubtitle={{
                      ...styles.text16,
                      ...{
                        color: colors.gray,
                        fontWeight: "normal",
                        cursor: "pointer",
                      },
                    }}
                    styleIcon={{ backgroundColor: `rgb(${this.randomRGB()})` }}
                    styleImg={{ height: 64, width: 64 }}
                    img={null}
                    SVG={() => this.Letter(el.track_name.charAt(0))}
                    title={el.track_name}
                    subtitle={el.track_info}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 16,
                      marginBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CommentsIcon style={{ marginRight: 8 }} />
                        <label style={{ ...styles.text16 }}>
                          {commentsCount}
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16,
                      }}
                    >
                      <LikeIcon style={likeStyle} />
                      <label style={{ ...styles.text16 }}>{likesCount}</label>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    );
  };
}

export default withRouter(withTranslation()(StreamDj));

const titleContainer = {
  display: "flex",
  height: 96,
  alignItems: "center",
};

const likeStyle = {
  marginRight: 8,
  fill: "#000",
};
