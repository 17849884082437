import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import api from "../../network/api";
import styles from "../../constants/styles";
import StreamBase from "./StreamBase";
import Comments from "../../components/Comments";

class StreamRadio extends StreamBase {
  getLeftItems = async (loadMore) => {
    let res = await api.get_stream_comments(this.state.stream.id, this.offset);
    this.handleLeftResult(res, loadMore);
  };
  onCommentAdded = async (comment) => {
    let data = this.state.leftItems;
    data.unshift(comment);
    this.setState({ leftItems: data });
  };

  renderLeftPanel = () => {
    const { t, isMobile } = this.props;
    return (
      <div style={{ width: this.isMobile ? "100%" : "57%" }}>
        <div style={titleContainer}>
          <label style={{ ...styles.text24Bold }}>{t("comments")}</label>
        </div>
        <Comments
          t={t}
          isMobile={isMobile}
          stream={this.state.stream}
          items={this.state.leftItems}
          onUpdate={this.onCommentAdded}
          loadMore={this.loadMore}
          hasMore={this.hasMore}
        />
      </div>
    );
  };
}

const titleContainer = {
  display: "flex",
  height: 96,
  alignItems: "center",
};

export default withRouter(withTranslation()(StreamRadio));
