import styled, { css } from "styled-components";
import colors from "../../constants/colors";

export const ErrorContainer = styled.div`
  position: relative;
`;

export const Error = styled.div`
  position: absolute;
  top: 4px;
  font-size: 12px;
  font-style: italic;
  padding: 0px 0px 8px;
  ${() => css`
    color: ${colors.red};
  `}
`;
