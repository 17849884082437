import React from "react";
import { ReactComponent as Delete } from "../assets/images/trash-red.svg";
import { ReactComponent as More } from "../assets/images/btn-more.svg";

import Dropdown from "./CustomDropdown";
import styles from "../constants/styles";
import colors from "../constants/colors";
import "./List.css";

export default class Podcast extends React.Component {
  handleDropDown = async () => {
    this.props.onDelete();
  };

  render() {
    let { title, url, author, duration } = this.props.item;
    return (
      <div
        className="list-container"
        onMouseLeave={() => {
          this.dropdownRef.close();
        }}
        onClick={() => {
          this.dropdownRef.handleClick();
        }}
        style={{ cursor: "pointer" }}
      >
        <div style={textContainer}>
          <div style={{ display: "flex", cursor: "pointer", width: 500 }}>
            <label
              onClick={() => {
                window.open(url);
              }}
              style={{
                ...styles.text16Bold,
                ...{
                  cursor: "pointer",
                  color: colors.blue,
                  overflow: "hidden",
                },
              }}
            >
              {title}
            </label>
            <label
              style={{
                ...styles.text16,
                ...{ marginLeft: 12, color: "gray", cursor: "pointer" },
              }}
            >
              {duration}
            </label>
          </div>
          <label
            style={{
              ...styles.text16,
              ...{ marginBottom: 0, cursor: "pointer" },
            }}
          >
            {author}
          </label>
        </div>
        {
          <div
            style={{
              display: "flex",
              width: "100%",
              marginRight: 16,
              marginLeft: 16,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className="list-manageBox">
              <Dropdown
                ref={(ref) => (this.dropdownRef = ref)}
                Icon={
                  <div className="list-menu">
                    <More />
                  </div>
                }
                styleList={{ width: 180 }}
                options={[{ value: "delete", label: "Delete", SVG: Delete }]}
                onChange={this.handleDropDown}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

const textContainer = {
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  marginLeft: 16,
};
