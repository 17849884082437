import * as Yup from "yup";

export const model = {
  username: {
    name: "username",
    label: "Email",
    placeholder: "Your Email",
  },
  password: {
    name: "password",
    type: "password",
    label: "Password",
    style: { marginTop: 40 },
    placeholder: "maxSymbols",
  },
};

export const validationSchema = Yup.object().shape({
  [model.username.name]: Yup.string().required(),
  [model.password.name]: Yup.string().required().max(64),
});

export const initialValues = {
  [model.username.name]: "",
  [model.password.name]: "",
};
