import React from "react";
import Header from "../../components/Header";
import Overview from "../../components/Overview";
import StreamInfo from "../../components/StreamInfo";
import Status from "../../components/Status";
import Button from "../../components/Button";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";
import "./Segment.css";
import colors from "../../constants/colors";
import styles from "../../constants/styles";
import api from "../../network/api";
import { Helmet } from "react-helmet";
import { SegmentedControl } from "segmented-control";
import { ReactComponent as PlusIcon } from "../../assets/images/plus-blue.svg";
import NewsItem from "../../components/NewsItem";
import roles from "../../constants/Roles";
import InfiniteScroll from "react-infinite-scroller";
import ToggleSwitch from "../../components/ToggleSwitch";
import { Loader } from "../../components";

export default class StreamBase extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      rightItems: [],
      leftItems: [],
      stream: {},
      meta: { cur_song: "Loading...", desc: "Loading..." },
      isApprovedChecked: false,
      isPendingChecked: false,
      isLoaded: false,
      commentsCount: 0,
      likesCount: 0,
      selected: 0,
      isNews: false,
    };
    this.isMobile = this.props.isMobile;
    this.offset = 0;
    this.hasMore = true;
    this.offsetRight = 0;
    this.hasMoreRight = true;
    this.stream_id = new URLSearchParams(window.location.search).get("id");
  }

  handleLeftResult = async (result, loadMore) => {
    if (!result.error) {
      let data = this.state.leftItems;
      if (loadMore) {
        this.hasMore = result.length > 0;
        if (this.hasMore) this.state.leftItems.push(...result);
      } else {
        data = result;
      }
      this.setState({ leftItems: data });
    } else {
      this.hasMore = false;
    }
  };

  loadMore = async () => {
    if (this.state.leftItems.length >= api.def_limit) {
      this.offset += api.def_limit;
      this.getLeftItems(true);
    }
  };

  handleRightResult = async (result, loadMore) => {
    if (!result.error) {
      let data = this.state.rightItems;
      if (loadMore) {
        this.hasMoreRight = result.length > 0;
        if (this.hasMoreRight) this.state.rightItems.push(...result);
      } else {
        data = result;
      }
      this.setState({ rightItems: data });
    } else {
      this.hasMoreRight = false;
    }
  };

  loadMoreRight = async () => {
    if (this.state.rightItems.length >= api.def_limit) {
      this.offsetRight += api.def_limit;
      this.getRightItems(true);
    }
  };

  async componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    this.state.leftItems = [];
    this.state.rightItems = [];
    this.checkApproved();
    this.checkPending();
    let stream = await api.get_stream_by_id(this.stream_id);
    this.setState({ stream: stream }, () => {
      this.getLeftItems();
      this.getIsNews(stream);
      this.getRightItems();
      this.setState({ isLoaded: true });
    });
    this.getMeta();
  };

  getLeftItems = async (loadMore) => {};

  renderLeftPanel = () => {};

  getIsNews = async (stream) => {
    this.setState({ isNews: stream.isNews });
    console.log(this.state.isNews);
  };

  getRightItems = async (loadMore) => {
    let res = await api.get_news_by_stream(
      this.state.stream.id,
      this.offsetRight
    );
    this.handleRightResult(res, loadMore);
  };

  changeIsNews = async () => {
    this.setState({ isNews: !this.state.isNews });

    let data = new FormData();
    console.log(this.state.stream);
    data.append("stream_id", this.state.stream.id);
    data.append("stream_url", this.state.stream.stream_url);
    data.append("isNews", !this.state.isNews ? 1 : 0);
    let result = await api.edit_stream(data);
    if (!result.error) {
      console.log("success");
    }
  };

  renderRightPanel = () => {
    const { t } = this.props;
    return (
      <div style={{ width: this.isMobile ? "100%" : "43%" }}>
        {!this.isMobile && (
          <div
            style={{
              ...titleContainer,
              ...{ justifyContent: "space-between" },
            }}
          >
            <div style={{ display: "flex" }}>
              <label style={{ ...styles.text24Bold }}>{t("news")}</label>
              <div style={{ marginLeft: 30 }}>
                <ToggleSwitch
                  checked={this.state.isNews}
                  handleClick={() => this.changeIsNews()}
                />
              </div>
            </div>
            <Button
              variant={"light"}
              onClick={this.postAdd}
              style={{ width: 144 }}
              SVG={PlusIcon}
              title={t("post")}
            />
          </div>
        )}

        {this.isMobile && (
          <div>
            <Button
              variant={"light"}
              onClick={this.postAdd}
              style={{ width: "100%", marginBottom: 24 }}
              SVG={PlusIcon}
              title={t("post")}
            />
          </div>
        )}

        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreRight}
            hasMore={this.hasMoreRight}
          >
            {this.state.rightItems.map((el, index) => {
              return (
                <NewsItem
                  key={index.toString()}
                  item={el}
                  onDelete={this.handleDelete}
                  stream_id={this.stream_id}
                />
              );
            })}
          </InfiniteScroll>
          {this.state.rightItems && this.state.rightItems.length === 0 && (
            <label style={{ ...styles.text16, ...{ color: "#999999" } }}>
              {t("emptyNews")}
            </label>
          )}
        </div>
      </div>
    );
  };

  handleDelete = async (error) => {
    if (!error) {
      this.offsetRight = 0;
      this.state.rightItems = [];
      this.getRightItems();
    } else {
      this.setState({
        errorMsg: error,
      });
    }
  };

  getMeta = async () => {
    let meta;
    let { stream } = this.state;
    if (stream.isApproved) {
      if (stream.role === roles.star) {
        meta = await api.get_azura_nowplaying(stream.parse_url);
        meta.cur_song = meta.now_playing.song.title;
        meta.desc = meta.now_playing.song.artist;
        meta.listeners = meta.listeners.current;
      } else {
        meta = await api.get_stream_meta(stream);
      }
      if (!meta.error) {
        this.setState({ meta });
        if (stream.role === roles.star) {
          await api.create_track_by_name(
            meta.now_playing.song.title,
            meta.now_playing.song.artist,
            this.id
          );
        }
        this.state.leftItems = [];
        this.getLeftItems();
      }
    }
  };

  checkApproved = () => {
    let isApprovedChecks = JSON.parse(localStorage.getItem("approved_checks"));
    if (!isApprovedChecks) {
      isApprovedChecks = [];
      localStorage.setItem("approved_checks", JSON.stringify(isApprovedChecks));
    }
    for (let el of isApprovedChecks) {
      if (el === this.stream_id) this.setState({ isApprovedChecked: true });
    }
  };

  setApproved = () => {
    let isApprovedChecks = JSON.parse(localStorage.getItem("approved_checks"));
    if (!isApprovedChecks.includes(this.stream_id)) {
      isApprovedChecks.push(this.stream_id);
      localStorage.setItem("approved_checks", JSON.stringify(isApprovedChecks));
    }
    this.checkApproved();
  };

  checkPending = () => {
    let isPendingChecks = JSON.parse(localStorage.getItem("pending_checks"));
    if (!isPendingChecks) {
      isPendingChecks = [];
      localStorage.setItem("pending_checks", JSON.stringify(isPendingChecks));
    }
    for (let el of isPendingChecks) {
      if (el === this.stream_id) this.setState({ isPendingChecked: true });
    }
  };

  setPending = () => {
    let isPendingChecks = JSON.parse(localStorage.getItem("pending_checks"));
    if (!isPendingChecks.includes(this.stream_id)) {
      isPendingChecks.push(this.stream_id);
      localStorage.setItem("pending_checks", JSON.stringify(isPendingChecks));
    }
    this.checkPending();
  };

  postAdd = () => {
    this.props.history.push("/postadd?id=" + this.stream_id);
  };

  renderOverview = () => {
    return (
      <Overview
        t={this.props.t}
        isMobile={this.isMobile}
        item={this.state.stream}
        meta={this.state.meta}
        history={this.props.history}
        comments={this.state.commentsCount}
      />
    );
  };

  renderInfo = () => {
    const { t, isMobile } = this.props;
    return (
      this.state.stream.isApproved &&
      !this.state.stream.isBlocked && (
        <div style={this.isMobile ? liveContainerMobile : liveContainer}>
          <StreamInfo
            styleContainer={{ backgroundColor: "white", width: "100%" }}
            styleTitle={styles.text16Bold}
            styleSubtitle={{
              ...styles.text16,
              ...{ color: colors.gray, fontWeight: "normal" },
            }}
            styleImg={{ height: 64, width: 64 }}
            img={this.state.stream.img}
            title={
              this.state.meta.cur_song?.length
                ? this.state.meta.cur_song
                : "Unknown"
            }
            subtitle={
              this.state.meta.desc?.length ? this.state.meta.desc : "Unknown"
            }
          />
          <Button
            variant={"red"}
            onClick={() => window.open(this.state.stream.stream_url, "_blank")}
            style={isMobile ? liveIndicatorMobile : liveIndicator}
            title={t("liveNow")}
          />
        </div>
      )
    );
  };

  renderStatus = () => {
    if (
      !this.state.isApprovedChecked &&
      this.state.stream.isApproved &&
      !this.state.stream.isBlocked
    ) {
      return (
        <Status
          status="approved"
          isMobile={this.isMobile}
          stream={this.state.stream}
          onClick={this.setApproved}
        />
      );
    }

    if (
      !this.state.isPendingChecked &&
      !this.state.stream.isApproved &&
      !this.state.stream.isBlocked
    ) {
      return (
        <Status
          status="pending"
          isMobile={this.isMobile}
          stream={this.state.stream}
          onClick={this.setPending}
        />
      );
    }

    if (this.state.stream.isBlocked) {
      return (
        <Status
          status="blocked"
          isMobile={this.isMobile}
          onClick={this.setApproved}
          stream={this.state.stream}
          text={this.state.stream.blocked_message}
        />
      );
    }
  };

  handleChange = (index) =>
    this.setState({
      selected: index,
    });

  render() {
    if (!this.state.isLoaded)
      return (
        <div
          style={{
            flex: 1,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      );
    const { t } = this.props;
    if (this.state.segments) {
      this.state.segments = [
        { label: t("trackHistory"), value: 0, default: true },
        { label: t("news"), value: 1 },
      ];
    }
    return (
      <div style={{ marginBottom: 80 }}>
        <Helmet>
          <title>{`${
            this.state.stream.name ? this.state.stream.name : ""
          } | ${t("title")}`}</title>
        </Helmet>

        <Header isMobile={this.isMobile} />
        {this.renderOverview()}
        {this.renderInfo()}
        {this.renderStatus()}

        {this.isMobile && (
          <div style={contentMobile}>
            {this.state.segments && (
              <SegmentedControl
                name="oneDisabled"
                options={this.state.segments}
                setValue={this.handleChange}
                className={"my-segment-control"}
              />
            )}
            {this.state.selected === 0 && this.renderLeftPanel()}
            {this.state.selected === 1 && this.renderRightPanel()}
          </div>
        )}
        {!this.isMobile && (
          <div style={content}>
            {this.renderLeftPanel()}
            <div style={divider} />
            {this.renderRightPanel()}
          </div>
        )}
        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}

const liveContainer = {
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 24,
  paddingRight: 40,
  marginTop: 40,
  marginLeft: 80,
  marginRight: 80,
  height: 112,
  borderRadius: 4,
  backgroundColor: "white",
};
const liveContainerMobile = {
  display: "flex",
  flexDirection: "column",
  padding: 24,
  marginTop: 24,
  marginLeft: "5%",
  marginRight: "5%",
  minHeight: 112,
  borderRadius: 4,
  backgroundColor: "white",
};
const liveIndicator = {
  height: 32,
  width: 100,
  borderRadius: 4,
  alignSelf: "center",
};
const liveIndicatorMobile = {
  height: 32,
  width: 100,
  display: "inline-block",
  borderRadius: 4,
  marginTop: 24,
};

const content = {
  display: "flex",
  flexDirection: "row",
  marginLeft: 80,
  marginRight: 80,
  marginTop: 40,
};

const contentMobile = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "5%",
  marginRight: "5%",
  marginTop: 24,
};

const divider = {
  backgroundColor: "black",
  opacity: 0.08,
  width: 1,
  marginRight: 40,
  marginLeft: 40,
};

const titleContainer = {
  display: "flex",
  height: 96,
  alignItems: "center",
};
