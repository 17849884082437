import React from "react";
import Dropdown from "react-dropdown";
import "./Dropdown.css";
import styles from "../constants/styles";
import colors from "../constants/colors";

export default class DropDown extends React.Component {
  render() {
    let { options, onSelect, title, current } = this.props;
    options = options ? options : [""];

    return (
      <div style={{ ...this.props.style }} className={"container"}>
        <label style={{ ...styles.text16, ...{ color: colors.gray } }}>
          {title}
        </label>
        <Dropdown
          options={options}
          onChange={(evt) => onSelect(evt)}
          value={current}
          placeholder={options[0]}
        />
      </div>
    );
  }
}
