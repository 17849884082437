import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";

export default function CheckBox(props) {
  const Icon = styled.svg`
    fill: none;
    stroke: ${colors.blue};
    stroke-width: 3px;
    margin-bottom: 1px;

    &:hover {
      stroke: #0047b3;
    }
  `;

  const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `;

  const StyledCheckbox = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border: 2px solid ${(props) => (props.checked ? colors.blue : colors.gray)};
    background: white;
    border-radius: 4px;
    transition: all 150ms;
    margin-top: 1px;
    cursor: pointer;

    &:hover {
      border: 2px solid ${(props) => (props.checked ? "#0047B3" : "#666666")};
      ${Icon} {
        stroke: #0047b3;
      }
    }

    ${HiddenCheckbox}:focus + & {
      box-shadow: 0 0 0 3px ${colors.blue};
    }

    ${Icon} {
      visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
  `;

  const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <CheckboxContainer style={props.style}>
      <HiddenCheckbox checked={props.checked} readOnly={true} />
      <StyledCheckbox checked={props.checked} onClick={props.onClick}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
}
