import React from "react";
import MyDropZone from "../../components/MyDropzone";
import Dropdown from "../../components/DropDown";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as webIcon } from "../../assets/images/web.svg";
import { ReactComponent as twitterIcon } from "../../assets/images/twitter.svg";
import { ReactComponent as instagramIcon } from "../../assets/images/instagram.svg";
import ArrowBack from "../../components/ArrowBack";
import { ReactComponent as AlertCircle } from "../../assets/images/alert-circle.svg";
import Input from "../../components/Input";
import InputIcon from "../../components/InputIcon";
import Button from "../../components/Button";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";
import Header from "../../components/Header";
import "./CreateStream.css";
import utils from "../../utils/utils";
import roles from "../../constants/Roles";
import api from "../../network/api";
import "../../components/Dropdown.css";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";
import XMLParser from "react-xml-parser";
import Podcast from "../../components/Podcast";
import CheckBox from "../../components/Checkbox";

export default class StreamManageBase extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      streamUrls: [],
      streamType: undefined,
      linkId: 0,
      img: undefined,
      imgFile: undefined,
      name: "",
      streamUrl: undefined,
      parseUrl: "",
      description: "",
      genres: [],
      genre: "",
      role: roles.radiostation,
      web: "",
      facebook: "",
      twitter: "",
      instagram: "",
      checking: false,
      submitting: false,
      checkedTerms: false,
      managers: [],
      podcasts: [],
      isPodcast:
        new URLSearchParams(window.location.search).get("role") ===
        roles.podcast,
    };
    this.hasNewPodcastTracks = false;
    this.isMobile = this.props.isMobile;
    this.stream_id = new URLSearchParams(window.location.search).get("id");
  }

  async componentDidMount() {
    this.getGenres();
    this.refresh();
  }

  getGenres = async () => {
    let genres = await api.get_genres();
    let sorted = genres.sort((a, b) => a.order - b.order).map((el) => el.name);
    this.setState({
      genres: sorted,
      genre: sorted && sorted.length > 0 ? sorted[0] : "",
    });
  };

  refresh = async () => {};

  submitStream = async () => {};

  checkInfo = () => {
    let {
      name,
      streamUrl,
      description,
      genre,
      role,
      parseUrl,
      img,
      checkedTerms,
      isPodcast,
      podcasts,
    } = this.state;
    const { t } = this.props;
    let message = [];
    if (!name) message.push(t("streamEmptyNameError"));
    if (!parseUrl && !isPodcast) message.push(t("streamEmptyUrlError"));
    if (!description) message.push(t("streamEmptyDescriptionError"));
    if (!genre) message.push(t("streamEmptyGenreError"));
    if (!role && !isPodcast) message.push(t("streamEmptyRoleError"));
    if (!streamUrl && !isPodcast) message.push(t("streamUrlTypeError"));

    if (isPodcast && podcasts.length === 0)
      message.push(t("streamEmptyPodcastsError"));

    if (!img) message.push(t("streamEmptyImgError"));
    if (!checkedTerms) message.push(t("streamTermsError"));
    return message.join("\n");
  };

  checkStream = async () => {
    let { parseUrl, isPodcast } = this.state;
    const { t } = this.props;
    if (parseUrl) {
      this.setState({
        checking: true,
      });
      if (isPodcast) {
        this.handlePodcast(parseUrl);
      } else {
        let result = await api.get_stream_urls(parseUrl);
        let parse_url = result.originUrl
          ? result.originUrl
          : utils.getClearUrl(parseUrl);
        if (result.stream_type === "SHOUTCAST") {
          parse_url += "/stats?sid=1";
        }
        if (!result.error && result.data.length) {
          this.setState({
            streamType: result.stream_type,
            streamUrl: result.data[0] ?? "",
            streamUrls: result.data ?? [],
            parseUrl: parse_url,
            checking: false,
          });
        } else {
          this.setState({
            errorMsg: t("streamUrlConnectError"),
            checking: false,
          });
        }
      }
    } else {
      this.setState({
        errorMsg: t("streamEmptyUrlError"),
      });
    }
  };

  onChangeImage = (image, file) => {
    this.setState({
      img: image,
      imgFile: file,
    });
  };

  goApprove = (id) => {
    this.props.history.push(`/streaminapprove?id=${id}`);
  };

  goTerms = (id) => {
    this.props.history.push("/terms");
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderHeader = () => {
    const { t } = this.props;
    return (
      <div
        className={
          this.isMobile
            ? "my-streams-create-container-mobile"
            : "my-streams-create-container"
        }
      >
        <ArrowBack
          onClick={this.goBack}
          style={{ marginRight: this.isMobile ? 8 : 24 }}
        />
        <label
          style={
            this.isMobile ? { ...styles.text24Bold } : { ...styles.text40Bold }
          }
        >
          {this.state.isPodcast ? t("createPodcast") : t("createStream")}
        </label>
      </div>
    );
  };

  renderManagers = () => {};

  renderTerms = () => {
    const { t } = this.props;
    return (
      <div
        onClick={() =>
          this.setState({ checkedTerms: !this.state.checkedTerms })
        }
        style={
          this.isMobile
            ? {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: 24,
                alignSelf: "center",
                width: "100%",
              }
            : {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "10%",
                alignSelf: "center",
                width: "50%",
              }
        }
      >
        <CheckBox checked={this.state.checkedTerms} />
        <span style={{ ...styles.text16, marginLeft: 16 }}>
          {t("agreeTo")}
          <a href="/terms" target="_blank">
            {t("termsTitle")}
          </a>
        </span>
      </div>
    );
  };

  renderBlocked = () => {};

  renderButtons = () => {
    const { t } = this.props;
    return (
      <div
        className={
          this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
        }
        style={{ marginBottom: 80, minHeight: 0 }}
      >
        <Button
          variant={"blue"}
          isLoading={this.state.submitting}
          onClick={this.submitStream}
          style={{ ...{ width: this.isMobile ? "100%" : 211 } }}
          styleImg={{ width: 16, height: 20, marginTop: 1 }}
          title={t("submitReview")}
        />

        {this.renderTerms()}
      </div>
    );
  };
  onSelectSreamUrl = (index) => {
    let url = this.state.parseUrl;
    this.setState({
      linkId: index,
      streamUrl: this.state.streamUrls[index],
      parseUrl: url.includes("sid=")
        ? url.substring(0, url.lastIndexOf("sid=") + 4) + ++index
        : url,
    });
  };

  handlePodcast = async (url) => {
    const text = await api.get_proxy_text(url);
    var xml = new XMLParser().parseFromString(text);
    let title = xml.getElementsByTagName("title")[0];
    if (title) this.setState({ name: title.value });
    let description = xml.getElementsByTagName("description")[0];
    if (description) this.setState({ description: description.value });

    let imgUrl = xml.getElementsByTagName("itunes:image")[0]?.attributes?.href;
    if (imgUrl) this.setState({ img: { preview: imgUrl } });

    let podcasts = xml.getElementsByTagName("item").map((item) => ({
      title: item.getElementsByTagName("title")[0]?.value,
      author: item.getElementsByTagName("author")[0]?.value,
      description: item.getElementsByTagName("description")[0]?.value,
      duration: item.getElementsByTagName("itunes:duration")[0]?.value,
      url: item.getElementsByTagName("enclosure")[0]?.attributes?.url,
    }));

    if (podcasts.length > 0) {
      this.setState({ podcasts: podcasts, checking: false });
      this.hasNewPodcastTracks = true;
    } else {
      this.setState({
        errorMsg: this.props.t("uploadXmlError"),
        checking: false,
      });
    }
  };

  removePodcastTrack(item) {
    let podcasts = this.state.podcasts;
    const index = podcasts.indexOf(item);
    if (index > -1) {
      podcasts.splice(index, 1);
    }
    this.setState({ podcasts: podcasts });
  }

  render() {
    let {
      name,
      streamUrl,
      description,
      streamUrls,
      web,
      parseUrl,
      facebook,
      twitter,
      instagram,
      genre,
      img,
      isPodcast,
      podcasts,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {name ? name : t("createStream")} | {t("title")}
          </title>
        </Helmet>

        <Header isMobile={this.isMobile} />
        {this.renderHeader()}
        <div
          className={
            this.isMobile ? "infobox-container-mobile" : "infobox-container"
          }
        >
          <AlertCircle style={{ marginRight: 8 }} />
          <label style={{ ...styles.text16, ...{ color: "black" } }}>
            {t(
              this.state.isPodcast
                ? "afterFillingPodcastFormInfo"
                : "afterFillingFormInfo",
            )}
          </label>
        </div>

        <div
          className={
            this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
          }
          style={{ ...{ marginBottom: 24 } }}
        >
          <Input
            name={"parse_url"}
            onChange={(e) => {
              this.setState({
                parseUrl: e.target.value,
              });
            }}
            style={this.isMobile ? { width: "100%" } : { width: "75%" }}
            label={isPodcast ? t("podcastUrl") : t("streamUrl")}
            placeholder={t("max200Symbols")}
            value={parseUrl}
          />
          <Button
            variant={"light"}
            isLoading={this.state.checking}
            onClick={this.checkStream}
            style={
              this.isMobile
                ? { ...{ width: "100%", marginTop: 24 } }
                : { ...{ width: "20%", marginLeft: "5%" } }
            }
            title={t("checkLink")}
          />
        </div>

        {!isPodcast && streamUrls && streamUrls.length > 0 && false && (
          <div
            className={
              this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
            }
            style={{ ...{ marginBottom: 24 } }}
          >
            <Dropdown
              style={this.isMobile ? { width: "100%" } : { width: "75%" }}
              styleDropDown={
                this.isMobile ? { width: "100%" } : { width: "75%" }
              }
              title={t("selectStream")}
              onSelect={(key) => {
                this.onSelectSreamUrl(key.value);
              }}
              options={streamUrls.map((el, index) => {
                return { value: index, label: el };
              })}
              current={streamUrl}
            />
            <Button
              variant={"light-red"}
              onClick={() => {
                this.setState({
                  streamUrl: "",
                  streamType: undefined,
                  streamUrls: [],
                });
              }}
              style={
                this.isMobile
                  ? { ...{ width: "100%", marginTop: 24 } }
                  : { ...{ width: "20%", marginLeft: "5%" } }
              }
              title={t("delete")}
            />
          </div>
        )}

        {isPodcast && podcasts.length > 0 && (
          <div
            className={
              this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
            }
            style={{
              ...{
                marginBottom: 24,
                maxHeight: 400,
                minHeight: 200,
                overflowY: "scroll",
                flexDirection: "column",
              },
            }}
          >
            {podcasts.map((podcast, index) => (
              <div key={index} style={{ width: "100%" }}>
                <Podcast
                  item={podcast}
                  onDelete={() => this.removePodcastTrack(podcast)}
                />
              </div>
            ))}
          </div>
        )}

        {(!isPodcast || podcasts.length > 0) && (
          <>
            <MyDropZone
              isMobile={this.isMobile}
              style={
                this.isMobile ? dropzoneContainerMobile : dropzoneContainer
              }
              onChangeImage={this.onChangeImage}
              img={img}
            />

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <Input
                value={name}
                name={"stream_name"}
                style={{ width: "100%" }}
                placeholder={t("max32Symbols")}
                label={t(isPodcast ? "podcastName" : "streamName")}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <Dropdown
                style={this.isMobile ? { width: "100%" } : { width: "45%" }}
                title={t("genre")}
                options={this.state.genres}
                onSelect={(key) => this.setState({ genre: key.value })}
                current={genre}
              />

              {!isPodcast && (
                <Dropdown
                  title={t("category")}
                  style={
                    this.isMobile
                      ? { width: "100%" }
                      : { marginLeft: "10%", width: "45%" }
                  }
                  onSelect={(key) => this.setState({ role: key.value })}
                  options={[
                    { value: roles.radiostation, label: t("radiostation") },
                    { value: roles.dj, label: t("dj") },
                  ]}
                  current={utils.getStreamRole(roles.radiostation)}
                />
              )}
            </div>

            {!isPodcast && (
              <div style={this.isMobile ? infoBoxMobile : infoBox}>
                <label
                  style={{ ...styles.text16, ...{ whiteSpace: "pre-wrap" } }}
                >
                  {utils.getStreamRole(this.state.role) === "Radiostation" &&
                    t("radiostationRoleInfo")}
                  {utils.getStreamRole(this.state.role) === "Dj" &&
                    t("djRoleInfo")}
                </label>
              </div>
            )}

            {!isPodcast && (
              <div style={this.isMobile ? infoBoxMobile : infoBox}>
                <label
                  style={{ ...styles.text16, ...{ whiteSpace: "pre-wrap" } }}
                >
                  {t("checkLinkInfo")}
                </label>
              </div>
            )}

            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              <Input
                name={"description"}
                multiline
                onChange={(e) => this.setState({ description: e.target.value })}
                style={{ width: "100%", maxWidth: 620 }}
                inputStyle={{ minHeight: 120 }}
                label={t("description")}
                placeholder={t("descPlaceholder")}
                value={description}
              />
            </div>
            <div
              className={
                this.isMobile
                  ? "create-stream-info-mobile"
                  : "create-stream-info"
              }
            >
              {this.renderBlocked()}
            </div>

            <div style={dividerContainer}>
              <div className={"create-stream-divider"} />
            </div>

            <div
              style={this.isMobile ? { marginLeft: "5%" } : { marginLeft: 80 }}
              className={"create-stream-social-titile-container"}
            >
              <label style={{ ...styles.text24Bold }}>{t("social")}</label>
            </div>
            <div
              style={this.isMobile ? socialContainerMobile : socialContainer}
            >
              <InputIcon
                name={"url"}
                onChange={(e) => this.setState({ web: e.target.value })}
                svg={webIcon}
                inputStyle={this.isMobile ? { width: "100%" } : { width: 290 }}
                placeholder={"https://www"}
                value={web}
              />
              <InputIcon
                name={"facebook"}
                style={{ marginTop: 40 }}
                onChange={(e) => this.setState({ facebook: e.target.value })}
                svg={FacebookIcon}
                inputStyle={this.isMobile ? { width: "100%" } : { width: 290 }}
                placeholder={"facebook.com/"}
                value={facebook}
              />

              <InputIcon
                name={"twitter"}
                style={{ marginTop: 40 }}
                svg={twitterIcon}
                onChange={(e) => this.setState({ twitter: e.target.value })}
                inputStyle={this.isMobile ? { width: "100%" } : { width: 290 }}
                placeholder={"twitter.com/"}
                value={twitter}
              />
              <InputIcon
                name={"instagram"}
                style={{ marginTop: 40 }}
                svg={instagramIcon}
                onChange={(e) => this.setState({ instagram: e.target.value })}
                inputStyle={this.isMobile ? { width: "100%" } : { width: 290 }}
                placeholder={"instagram.com/"}
                value={instagram}
              />
            </div>
          </>
        )}

        <div style={dividerContainer}>
          <div className={"create-stream-divider"} />
        </div>

        {this.renderManagers()}
        {this.renderButtons()}

        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
        {this.state.customAlert && this.renderCustomAlert()}
      </div>
    );
  }
}

const dropzoneContainer = {
  height: 360,
  width: 360,
  marginLeft: 80,
  marginTop: 40,
};
const dropzoneContainerMobile = {
  height: 360,
  width: "90%",
  marginLeft: "5%",
  marginRight: "5%",
  marginTop: 24,
};
const socialContainer = {
  marginLeft: 80,
};

const socialContainerMobile = {
  marginLeft: "5%",
  width: "90%",
};

const dividerContainer = {
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
  height: 40,
  justifyContent: "center",
};

const infoBox = {
  display: "flex",
  marginTop: 24,
  marginLeft: 80,
  padding: 24,
  width: 572,
  borderRadius: 4,
  background: "rgba(153, 153, 153, 0.1)",
};
const infoBoxMobile = {
  display: "flex",
  marginTop: 24,
  marginLeft: "5%",
  padding: "5%",
  width: "80%",
  borderRadius: 4,
  background: "rgba(153, 153, 153, 0.1)",
};
