import React from "react";
import { ReactComponent as Left } from "../assets/images/left.svg";

export default function (props) {
  return (
    <div style={{ ...{ display: "flex", cursor: "pointer" }, ...props.style }}>
      <Left style={style} onClick={props.onClick} />
    </div>
  );
}

const style = {
  height: 24,
  width: 24,
  cursor: "pointer",
  alignSelf: "center",
};
