import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Alert, InputField } from "../../components";
import ArrowBack from "../../components/ArrowBack";
import Button from "../../components/Button";
import Header from "../../components/Header";
import MyDropZone from "../../components/MyDropzone";
import styles from "../../constants/styles";
import api from "../../network/api";
import { useMeQuery } from "../../store/services/user";
import utils from "../../utils/utils";
import {
  initialValues,
  model,
  validationSchema,
} from "../../yup-configs/profile-edit";

export default function EditProfile(props) {
  const { data: me } = useMeQuery();

  const header = useRef();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [img, setImg] = useState();
  const [img_file, setImgFile] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const { t } = useTranslation();
  const isMobile = props.isMobile;

  useEffect(() => {
    if (me?.img) {
      setImg({ preview: me.img });
    }
  }, [me?.img]);

  const renderAlert = () => {
    return (
      <Alert
        error={errorMsg !== undefined}
        success={successMsg !== undefined}
        title={successMsg ? t("success") : t("error")}
        message={utils.clearResponceMsg(successMsg ? successMsg : errorMsg)}
        onConfirm={() => {
          setErrorMsg(undefined);
          setSuccessMsg(undefined);
        }}
      />
    );
  };
  const update = async ({
    info,
    email,
    username,
    newPassword,
    currentPassword,
    confirmPassword,
  }) => {
    setIsLoading(true);
    let error = [];
    let success = [];
    if (currentPassword && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword)
        error.push(t("wrongConfirmPassword"));
      else if (currentPassword.length < 6 || newPassword.length < 6)
        error.push(t("tooShortPassword"));
      else {
        const response = await api.change_password(
          currentPassword,
          newPassword,
        );
        if (response && response.error) {
          error.push(t("wrongPassword"));
        } else {
          success.push(t("changedPassword"));
        }
      }
    }

    if (img_file) {
      const response = await api.add_avatar(img_file);
      if (response && response.error) {
        error.push(t("updateAvatarError"));
      } else {
        header.current.update();
        success.push(t("updateAvatarSuccess"));
        setIsImageChanged(false);
      }
    } else if (!img) {
      const response = await api.delete_avatar();
      if (response && response.error) {
        error.push(t("updateAvatarError"));
      } else {
        setImg({});
        header.current.update();
        success.push(t("removeAvatarSuccess"));
        setIsImageChanged(false);
      }
    }

    if (email !== me?.email) {
      if (email && email.length > 0) {
        const response = await api.change_email(email);
        if (response && response.error) {
          error.push(t("wrongEmail"));
        } else {
          success.push(t("emailChanged"));
        }
      } else {
        error.push(t("wrongEmail"));
      }
    }

    if (me.username !== username) {
      const res = await api.change_username(username);
      if (res.error) {
        error.push(res.error);
      } else {
        success.push(t("Username changed"));
        header.current.update();
      }
    }
    if (me.info !== info) {
      const res = await api.change_user_info(info);
      if (res.error) {
        error.push(res.error);
      } else {
        success.push(t("Info changed"));
        header.current.update();
      }
    }
    if (error.length > 0) {
      setErrorMsg(error.join("\n") + "\n" + success.join("\n"));
    } else {
      setSuccessMsg(success.join("\n"));
    }

    setIsLoading(false);
  };

  const onChangeImage = (image, file) => {
    setImg(image);
    setImgFile(file);
    setIsImageChanged(true);
  };

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async (values) => {
    await update(values);
  };

  return (
    <div style={container}>
      <Helmet>
        <title>{t("titleProfile")}</title>
      </Helmet>
      <Header ref={header} isMobile={isMobile} />
      <div
        className={
          isMobile
            ? "my-streams-create-container-mobile"
            : "my-streams-create-container"
        }
      >
        <ArrowBack
          onClick={goBack}
          style={{ marginRight: isMobile ? 8 : 24 }}
        />
        <label style={styles.text40Bold}>{t("editProfile")}</label>
      </div>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={initialValues(me)}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting, submitForm }) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <div>
                <div style={isMobile ? column : row}>
                  <InputField
                    {...model.username}
                    style={{ width: isMobile ? "100%" : 290 }}
                  />

                  <InputField
                    {...model.email}
                    style={
                      isMobile
                        ? { width: "100%", marginTop: 24 }
                        : { width: 290, marginLeft: 40 }
                    }
                  />
                </div>
                <InputField
                  {...model.info}
                  minRows={6}
                  inputStyle={{ minHeight: 160 }}
                  style={
                    isMobile
                      ? { width: "90%", marginLeft: "5%", marginTop: 24 }
                      : { width: 620, marginLeft: 80, marginTop: 40 }
                  }
                />

                <InputField
                  {...model.oldPassword}
                  style={
                    isMobile
                      ? { width: "90%", marginLeft: "5%", marginTop: 24 }
                      : { width: 290, marginLeft: 80, marginTop: 40 }
                  }
                />

                <div
                  style={
                    isMobile
                      ? { ...column, ...{ marginTop: 24 } }
                      : { ...row, ...{ marginTop: 40 } }
                  }
                >
                  <InputField
                    {...model.newPassword}
                    style={{ width: isMobile ? "100%" : 290 }}
                  />

                  <InputField
                    {...model.confirmPassword}
                    style={
                      isMobile
                        ? { width: "100%", marginTop: 24 }
                        : { width: 290, marginLeft: 40 }
                    }
                  />
                </div>

                <label
                  style={{
                    ...styles.text16,
                    ...(isMobile ? textMobile : text),
                  }}
                >
                  {t("emailRequestInfo")}
                </label>
              </div>

              <div
                style={
                  isMobile
                    ? {
                        marginLeft: "5%",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                      }
                    : { marginLeft: 40, display: "flex", alignItems: "center" }
                }
              >
                <MyDropZone
                  isMobile={isMobile}
                  style={isMobile ? dropzoneContainerMobile : dropzoneContainer}
                  onChangeImage={onChangeImage}
                  img={img}
                />
              </div>
            </div>
            <Button
              variant={"blue"}
              onClick={submitForm}
              title={isLoading ? t`${t("Loading")}...` : t("save")}
              disabled={
                (!dirty || isSubmitting) && (!isImageChanged || isSubmitting)
              }
              style={
                isMobile
                  ? {
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: 24,
                      marginBottom: 40,
                    }
                  : {
                      width: 116,
                      marginLeft: 80,
                      marginTop: 40,
                      marginBottom: 40,
                    }
              }
            />
          </>
        )}
      </Formik>

      {(errorMsg || successMsg) && renderAlert()}
    </div>
  );
}

const container = {
  display: "flex",
  flexDirection: "column",
};

const row = {
  display: "flex",
  flexDirection: "row",
  marginLeft: 80,
};

const column = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "5%",
  width: "90%",
};

const text = {
  lineHeight: 1.5,
  marginTop: 40,
  marginLeft: 80,
  maxWidth: 620,
};
const textMobile = {
  lineHeight: 1.5,
  marginTop: 24,
  marginBottom: 24,
  marginLeft: "5%",
  width: "90%",
};
const dropzoneContainer = {
  height: 360,
  width: 360,
  cursor: "pointer",
};
const dropzoneContainerMobile = {
  height: 360,
  width: "100%",
};
