import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Input from "../../components/Input";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";

import Button from "../../components/Button";
import api from "../../network/api";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";

class NewPassword extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      newPassword2: "",
      resetting: false,
    };

    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleNewPassword2 = this.handleNewPassword2.bind(this);
  }

  onReset = async () => {
    if (this.state.newPassword !== this.state.newPassword2) {
      this.setState({ errorMsg: this.props.t("wrongConfirmPassword") });
    } else if (this.state.newPassword.length < 6) {
      this.setState({ errorMsg: this.props.t("tooShortPassword") });
    } else {
      this.setState({ resetting: true });
      let result = await api.reset_password(
        this.props.email,
        this.props.code,
        this.state.newPassword,
      );
      this.setState({ resetting: false });
      if (!result.error) this.setState({ successMsg: result.response });
      else this.setState({ errorMsg: result.error });
    }
  };

  onSuccess = () => {
    this.openLogin();
  };

  openLogin = () => {
    this.props.history.push("/login");
  };

  handleNewPassword(event) {
    this.setState({ newPassword: event.target.value });
  }

  handleNewPassword2(event) {
    this.setState({ newPassword2: event.target.value });
  }

  render() {
    let { t, isMobile } = this.props;
    return (
      <div>
        <Helmet>
          <title>{t("titlePassword")}</title>
        </Helmet>
        <div className={isMobile ? "logo-container-mobile" : "logo-container"}>
          <Logo className={"auth-logo-image"} alt={"logo-image"} />
        </div>
        <div className={isMobile ? "form-container-mobile" : "form-container"}>
          <div className={isMobile ? "formMobile" : "form"}>
            <label style={styles.text16}>{t("setNewPswd")}</label>
            <Input
              name={"password"}
              type="password"
              onChange={this.handleNewPassword}
              style={{ marginTop: 40 }}
              placeholder={t("maxSymbols")}
              label={t("newPswd")}
              value={this.state.newPassword}
            />
            <Input
              name={"password"}
              type="password"
              onChange={this.handleNewPassword2}
              style={{ marginTop: 40 }}
              placeholder={t("toBeSure")}
              label={t("confirmPswd")}
              value={this.state.newPassword2}
            />
            <Button
              variant={"blue"}
              onClick={this.onReset}
              isLoading={this.state.resetting}
              style={{ marginTop: 40 }}
              title={t("setPswd")}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant={"light"}
            onClick={this.openLogin}
            style={{
              width: isMobile ? "90%" : 400,
              marginBottom: 40,
              marginTop: 40,
            }}
            title={t("backToLogin")}
          />
        </div>
        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}
export default withRouter(withTranslation()(NewPassword));
