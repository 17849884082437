import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Button from "../../components/Button";
import PostBase from "./PostBase";
import api from "../../network/api";

class PostAdd extends PostBase {
  async componentDidMount() {
    this.setState({
      headerTitle: this.props.t("editPost"),
    });

    let result = await api.get_news_by_id(this.news_id);
    this.setState({
      title: result.title,
      text: result.text,
      img: { preview: result.img },
    });
  }

  post = async () => {
    let message = this.check();
    if (message) {
      this.setState({
        errorMsg: message,
      });
      return;
    }
    this.setState({ posting: true });
    let data = new FormData();
    data.append("news_id", this.news_id);
    data.append("stream", this.stream_id);
    data.append("title", this.state.title);
    data.append("text", this.state.text);
    if (this.state.img_file) data.append("img", this.state.img_file);

    let result = await api.edit_news(data);
    if (!result.error) {
      this.goBack();
    } else {
      this.setState({
        errorMsg: result.error,
      });
      this.setState({ posting: false });
    }
  };

  delete = async () => {
    this.setState({ deleting: true });
    let data = new FormData();
    data.append("news_id", this.news_id);
    data.append("stream", this.stream_id);
    let result = await api.delete_news(data);
    if (!result.error) {
      this.goBack();
    } else {
      this.setState({
        errorMsg: result.error,
      });
      this.setState({ deleting: false });
    }
  };

  renderButtons = () => {
    const { t } = this.props;
    return [
      <Button
        variant={"light-red"}
        isLoading={this.state.deleting}
        style={{ ...{ width: this.isMobile ? "100%" : 170 } }}
        onClick={this.delete}
        title={t("deletePost")}
      />,
      <Button
        variant={"blue"}
        onClick={this.post}
        isLoading={this.state.posting}
        style={
          this.isMobile
            ? { width: "100%", marginTop: 24 }
            : { width: 170, marginLeft: 40 }
        }
        title={t("saveChanges")}
      />,
    ];
  };
}

export default withRouter(withTranslation()(PostAdd));
