import React from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "../components/CustomDropdown";
import styles from "../constants/styles";
import colors from "../constants/colors";
import { ReactComponent as More } from "../assets/images/btn-more.svg";
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { ReactComponent as Delete } from "../assets/images/trash-red.svg";
import { format } from "date-fns";
import api from "../network/api";
import styled from "styled-components";
import Icon from "./Icon";

export default function NewsItem(props) {
  const history = useHistory();

  const handleDropDown = async (key) => {
    switch (key) {
      case "edit":
        history.push("postedit/?n_id=" + id + "&id=" + props.stream_id);
        break;
      case "delete":
        let data = new FormData();
        data.append("news_id", id);
        data.append("stream", props.stream_id);
        let result = await api.delete_news(data);
        props.onDelete(result.error);
        break;
      default:
        break;
    }
  };

  const Menu = styled(More)`
    &: hover {
      path {
        fill: ${colors.blue};
      }
    }
  `;

  let { id, title, text, img, created_at } = props.item;
  return (
    <div style={container}>
      <div style={containerTitle}>
        <label style={{ ...styles.text24Bold, ...titleStyle }}>{title}</label>
        <Dropdown
          Icon={<Menu />}
          styleList={{ width: 140 }}
          options={[
            { value: "edit", label: "Edit Post", SVG: Edit },
            { value: "delete", label: "Delete Post", SVG: Delete },
          ]}
          onChange={handleDropDown}
        />
      </div>

      <label style={{ ...styles.text16, ...dateStyle }}>
        {format(new Date(created_at), "dd MMM yyyy")}
      </label>

      <Icon style={{ ...imgContainer }} styleImg={{ ...imgStyle }} img={img} />

      <label
        style={{
          ...styles.text16,
          ...dateStyle,
          ...{
            color: colors.black,
            overflow: "hidden",
            marginBottom: 24,
            whiteSpace: "pre-wrap",
          },
        }}
      >
        {" "}
        {text}
      </label>
    </div>
  );
}

const container = {
  display: "flex",
  flexDirection: "column",
  marginBottom: 24,
  backgroundColor: "white",
};

const containerTitle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: 48,
  marginTop: 24,
};

const titleStyle = {
  marginLeft: 24,
};

const dateStyle = {
  marginTop: 16,
  marginLeft: 24,
  marginRight: 24,
  color: colors.gray,
};

const imgContainer = {
  display: "flex",
  marginTop: 16,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 16,
  borderRadius: 0,
};

const imgStyle = {
  display: "flex",
  width: "100%",
  borderRadius: 0,
};
