import { createApi } from "@reduxjs/toolkit/query/react";

import { URLS, LIMITS } from "../../api/constants";

import { apiQuery } from "../query";

const tagTypes = ["Comment"];

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: apiQuery,
  tagTypes,
  endpoints: (build) => ({
    sendComment: build.mutation({
      query: (body) => ({
        body,
        method: "post",
        url: URL.SEND_COMMENT,
      }),
      invalidatesTags: tagTypes,
    }),

    streamComments: build.query({
      query: ({ id, offset, limit = LIMITS.COMMENT_LIMIT }) => {
        const params = new URLSearchParams({
          limit,
          offset,
        });

        return {
          method: "get",
          url: `${URLS.STREAM_COMMENTS}${id}/?${params.toString()}`,
        };
      },
    }),

    trackComments: build.query({
      query: ({ id, offset, limit = LIMITS.COMMENT_LIMIT }) => {
        const params = new URLSearchParams({
          limit,
          offset,
        });

        return {
          method: "get",
          url: `${URLS.TRACK_COMMENTS}${id}/?${params.toString()}`,
        };
      },
    }),
  }),
});

export const {
  useSendCommentMutation,
  useLazyTrackCommentsQuery,
  useLazyStreamCommentsQuery,
} = commentApi;
