import * as Yup from "yup";

export const model = {
  username: {
    name: "username",
    label: "username",
    placeholder: "username",
  },
  email: {
    name: "email",
    label: "Email",
    placeholder: "email",
  },
  info: {
    name: "info",
    label: "info",
    multiline: true,
    placeholder: "infoExample",
  },
  oldPassword: {
    name: "oldPassword",
    type: "password",
    label: "curPassword",
    placeholder: "••••••••",
  },
  newPassword: {
    name: "newPassword",
    type: "password",
    label: "newPassword",
    placeholder: "••••••••",
  },
  confirmPassword: {
    name: "confirmPassword",
    type: "password",
    label: "confirmNewPswd",
    placeholder: "••••••••",
  },
};

export const validationSchema = Yup.object().shape({
  [model.username.name]: Yup.string(),
  [model.info.name]: Yup.string().max(260),
  [model.email.name]: Yup.string().email(),
  [model.oldPassword.name]: Yup.string().min(8).max(64),
  [model.newPassword.name]: Yup.string().min(8).max(64),
  [model.confirmPassword.name]: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent[model.newPassword.name] === value;
    },
  ),
});

export const initialValues = (me) => {
  return {
    [model.username.name]: me?.username ?? "",
    [model.email.name]: me?.email ?? "",
    [model.oldPassword.name]: "",
    [model.newPassword.name]: "",
    [model.confirmPassword.name]: "",
    [model.info.name]: me?.info ?? "",
  };
};
