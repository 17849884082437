import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import styles from "../../constants/styles";
import colors from "../../constants/colors";
import Button from "../../components/Button";
import api from "../../network/api";
import utils from "../../utils/utils";

class EmailConfirmed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmed: false,
      isHome: false,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    let result = await api.get_confirm_email(utils.getParam("secret"));
    this.setState({ isConfirmed: result.error === undefined });
    this.setState({ isLoaded: true });
  }

  render() {
    if (this.state.isHome) return <Redirect to="mystreams" />;
    if (!this.state.isLoaded) return <div></div>;
    const { t } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          left: "0%",
          top: "50%",
          transform: "translate(0%, -50%)",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 80 }}
        >
          {this.state.isConfirmed ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ ...styles.text40Bold, ...{ marginTop: 40 } }}>
                {t("success")}
              </label>
              <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
                {t("emailConfirmed")}
              </label>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ ...styles.text40Bold, ...{ marginTop: 40 } }}>
                {t("error")}
              </label>
              <label style={{ ...styles.text24Bold, ...{ marginTop: 60 } }}>
                {t("emailNotConfirmed")}
              </label>
              <label
                style={{
                  ...styles.text16Bold,
                  ...{ marginTop: 8, color: colors.gray },
                }}
              >
                {t("tryAnotherLink")}
              </label>
            </div>
          )}

          <Button
            style={{ width: 152, marginBottom: 40, marginTop: 40 }}
            title={this.state.isConfirmed ? t("start") : t("goHome")}
            variant={"blue"}
            onClick={() => this.setState({ isHome: true })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(EmailConfirmed));
