import React from "react";
import "./Input.css";
import styles from "../constants/styles";
import colors from "../constants/colors";

export default class Input extends React.Component {
  render() {
    let { label, multiline } = this.props;

    return (
      <div style={this.props.style} className={"container"}>
        <label
          style={{
            ...styles.text16,
            ...{ color: colors.gray, alignSelf: "flex-start" },
          }}
        >
          {label}
        </label>
        {multiline ? (
          <textarea
            {...this.props}
            style={{ ...this.props.inputStyle, ...{ paddingTop: 16 } }}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            type={this.props.type ? this.props.type : "text"}
            placeholder={this.props.placeholder}
            name={this.props.name}
            value={this.props.value}
          />
        ) : (
          <input
            {...this.props}
            style={{
              ...this.props.inputStyle,
              ...{ background: this.props.disabled && "#EFEFEF" },
            }}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            type={this.props.type ? this.props.type : "text"}
            placeholder={this.props.placeholder}
            name={this.props.name}
            value={this.props.value}
          />
        )}
      </div>
    );
  }
}
