import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as Like } from "../../assets/images/like-solid.svg";
import { withTranslation } from "react-i18next";
import StreamBase from "./StreamBase";
import Comments from "../../components/Comments";
import Overview from "../../components/Overview";
import styles from "../../constants/styles";
import api from "../../network/api";

class StreamTrack extends StreamBase {
  constructor(props) {
    super(props);

    this.track_id = new URLSearchParams(window.location.search).get("track_id");
  }
  renderOverview = () => {
    return (
      <Overview
        t={this.props.t}
        isMobile={this.isMobile}
        item={this.state.stream}
        meta={this.state.meta}
        history={this.props.history}
        comments={this.state.commentsCount}
        notLike={true}
      />
    );
  };
  onCommentAdded = async (comment) => {
    let data = this.state.leftItems;
    data.unshift(comment);
    this.setState({ leftItems: data });
  };

  renderInfo = () => {};

  renderRightPanel = () => {};

  getRightItems = async () => {
    this.setState({
      track: await api.get_track_by_id(this.track_id),
    });
  };

  getLeftItems = async (loadMore) => {
    let res = await api.get_track_comments(this.track_id, this.offset);
    this.handleLeftResult(res, loadMore);
  };

  renderLeftPanel = () => {
    let trackName = this.state.track ? this.state.track.track_name : "";
    let likesCount = this.state.track ? this.state.track.likes.length : 0;
    const { t, isMobile } = this.props;
    return (
      <div style={{ width: this.isMobile ? "100%" : "57%" }}>
        <div style={titleContainer}>
          <label style={{ ...styles.text24Bold }}>{trackName}</label>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
          >
            <Like style={likeStyle} />
            <label style={{ ...styles.text16 }}>{likesCount}</label>
          </div>
        </div>

        <Comments
          t={t}
          isMobile={isMobile}
          stream={this.state.stream}
          track_id={this.track_id}
          items={this.state.leftItems}
          onUpdate={this.onCommentAdded}
          loadMore={this.loadMore}
          hasMore={this.hasMore}
        />
      </div>
    );
  };
}

export default withRouter(withTranslation()(StreamTrack));

const titleContainer = {
  display: "flex",
  height: 96,
  alignItems: "center",
};

const likeStyle = {
  marginRight: 8,
  fill: "#000",
};
