import React from "react";
import BaseAlertableComponent from "../../components/base/BaseAlertableComponent";

import MyDropZone from "../../components/MyDropzone";
import ArrowBack from "../../components/ArrowBack";
import Header from "../../components/Header";
import Input from "../../components/Input";
import styles from "../../constants/styles";
import { Helmet } from "react-helmet";

export default class PostBase extends BaseAlertableComponent {
  constructor(props) {
    super(props);

    this.state = {
      headerTitle: "",
      title: undefined,
      text: undefined,
      img: undefined,
      posting: false,
      img_file: undefined,
    };
    this.isMobile = this.props.isMobile;

    this.stream_id = new URLSearchParams(window.location.search).get("id");
    this.news_id = new URLSearchParams(window.location.search).get("n_id");
  }

  check = () => {
    let message = "";
    if (!this.state.title) message += "\nEnter title";
    if (!this.state.text) message += "\nEnter Description";
    return message;
  };

  handleTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  handleText = (event) => {
    this.setState({ text: event.target.value });
  };

  onChangeImage = async (image, file) => {
    this.setState({
      img: image,
      img_file: file,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderButtons = () => {};

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>{t("titleNews")}</title>
        </Helmet>

        <Header isMobile={this.isMobile} />

        <div
          className={
            this.isMobile
              ? "my-streams-create-container-mobile"
              : "my-streams-create-container"
          }
        >
          <ArrowBack
            onClick={this.goBack}
            style={{ marginRight: this.isMobile ? 8 : 24 }}
          />
          <label style={{ ...styles.text40Bold }}>
            {this.state.headerTitle}
          </label>
        </div>
        <div style={this.isMobile ? contentMobile : content}>
          <div>
            <Input
              name={"title"}
              onChange={this.handleTitle}
              style={{ width: this.isMobile ? "100%" : 620 }}
              label={t("postTitle")}
              placeholder={t("max32Symbols")}
              value={this.state.title}
            />
            <Input
              name={"description"}
              multiline
              onChange={this.handleText}
              style={
                this.isMobile
                  ? { width: "100%", marginTop: 24 }
                  : { width: 620, marginTop: 40 }
              }
              inputStyle={{ height: 246 }}
              label={t("description")}
              placeholder={t("descPlaceholder")}
              value={this.state.text}
            />
          </div>

          <div>
            <MyDropZone
              isMobile={this.isMobile}
              style={
                this.isMobile ? dropzoneContainerMobile : dropzoneContainer
              }
              onChangeImage={this.onChangeImage}
              img={this.state.img}
            />
          </div>
        </div>
        <div style={this.isMobile ? buttonsContainerMobile : buttonsContainer}>
          {this.renderButtons()}
        </div>
        {(this.state.errorMsg || this.state.successMsg) && this.renderAlert()}
      </div>
    );
  }
}

const content = {
  display: "flex",
  marginLeft: 80,
};
const contentMobile = {
  display: "flex",
  flexDirection: "column",
  width: "90%",
  marginLeft: "5%",
};
const buttonsContainer = {
  display: "flex",
  flexDirection: "row",
  marginTop: 40,
  marginBottom: 40,
  marginLeft: 80,
};
const buttonsContainerMobile = {
  display: "flex",
  flexDirection: "column",
  width: "90%",
  marginTop: 24,
  marginBottom: 40,
  marginLeft: "5%",
};
const dropzoneContainer = {
  height: 360,
  width: 360,
  marginLeft: 40,
  marginTop: 32,
};
const dropzoneContainerMobile = {
  height: 360,
  width: "100%",
  marginTop: 24,
};
