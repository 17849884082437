import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Button from "../../components/Button";
import styles from "../../constants/styles";

import api from "../../network/api";
import VideoManageBase from "./VideoManageBase";
import CheckBox from "../../components/Checkbox";

class EditVideo extends VideoManageBase {
  constructor(props) {
    super(props);
    this.pageTitle = this.props.t("editVideo");
    this.video_id = new URLSearchParams(window.location.search).get("id");
  }

  renderBlocked = () => {
    const { t } = this.props;
    return (
      <div
        onClick={() => this.setState({ isBlocked: !this.state.isBlocked })}
        style={{ marginTop: 40 }}
      >
        <CheckBox checked={this.state.isBlocked} />
        <span style={{ ...styles.text16, marginLeft: 16 }}>
          {t("isBlocked")}
        </span>
      </div>
    );
  };

  async componentDidMount() {
    this.refresh();
  }

  fetchTags = async () => {
    let result = await api.get_relative_tags(this.video_id, "video");
    if (!result.error && result.length > 0) {
      this.setState({
        tags: result.map((el) => el.name),
      });
    }
  };

  refresh = async () => {
    this.fetchTags();

    let stream = await api.get_video(this.video_id);

    this.setState({
      name: stream.name,
      videoUrl: stream.video,
      description: stream.description,
      genres: [],
      genre: stream.genre,
      source: stream.source,
      checking: false,
      submitting: false,
      checkedTerms: false,
      isLive: stream.isLive,
      views: stream.views,
      duration: stream.duration,
      image: stream.image_url,
      isBlocked: stream.isBlocked,
    });
  };

  sendData = async (data) => {
    this.sendTags(this.video_id);
    return await api.edit_video(this.video_id, data);
  };

  showDeleteDialog = () =>
    this.setState({
      customAlert: {
        msg: this.props.t("msgDeleteStream"),
        onSubmit: this.delete,
      },
    });

  delete = async () => {
    this.setState({ removing: true });
    let result = await api.delete_video(this.video_id);
    this.setState({ removing: false });
    if (!result.error) {
      this.props.history.push("/mystreams");
    } else {
      this.setState({ errorMsg: result.error });
    }
  };

  renderButtons = () => {
    const { t } = this.props;
    return (
      <div style={{ marginTop: 40 }}>
        <div
          className={
            this.isMobile ? "create-stream-info-mobile" : "create-stream-info"
          }
          style={{ minHeight: 0 }}
        >
          <Button
            variant={"blue"}
            isLoading={this.state.submitting}
            onClick={this.submit}
            style={{ ...{ width: this.isMobile ? "100%" : 211 } }}
            styleImg={{ width: 16, height: 20, marginTop: 1 }}
            title={t("submit")}
          />

          {this.renderTerms()}
        </div>
        <Button
          variant={"light-red"}
          isLoading={this.state.removing}
          style={{
            ...{
              width: this.isMobile ? "90%" : 211,
              marginLeft: this.isMobile ? "5%" : 80,
              marginTop: 40,
              marginBottom: 80,
            },
          }}
          onClick={this.showDeleteDialog}
          title={t("delete")}
        />
      </div>
    );
  };
}

export default withRouter(withTranslation()(EditVideo));
