export const API_URL = process.env.REACT_APP_API_URL;

export const URLS = {
  ME: "me/",
  LOGIN: "login/",
  STREAMS: "streams/",
  GET_GENRES: "genres",
  TEA_LIST: "teaList/",
  METADATA: "metadata",
  GET_TEA_LIST: "teas/",
  REGISTER: "register/",
  MAKE_A_BREAK: "break/",
  BREAK_LIST: "breakList/",
  TEA_EFFECTS: "teaEffects",
  BREAK_TYPES: "breakTypes",
  STREAM_LIST: "streamList/",
  DELETE_USER: "delete_user",
  IAP_VALIDTE: "iapValidate/",
  GET_STREAMS: "get_streams/",
  SEND_COMMENT: "add_comment/",
  USER_STREAMS: "user_streams/",
  STREAM_TRACKS: "stream_tracks/",
  TEA_FAVORITES: "teas_favorites/",
  TOOGLE_TRACK_LIKE: "like_track/",
  SEARCH_STREAMS: "search_streams/",
  TRACK_COMMENTS: "track_comments/",
  TOOGLE_STREAM_LIKE: "like_stream/",
  STREAM_COMMENTS: "stream_comments/",
  GET_EXTENDED_BANNERS: "extendedBanners",
  FAVORITES_STREAMS: "favorites_manager/",
  PODCAST_CLICK: (id) => `podcasts/${id}/click/`,

  PRIVACY: "privacy/",

  SURVEY: "survey",
  SEND_SURVEY_REPORT: "createSurveySheet/",
};

export const LIMITS = {
  DEF_LIMIT: 10,
  COMMENT_LIMIT: 15,
  HOME_STREAM_LIMIT: 5,
};
