import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { URLS } from "../../api/constants";

import { apiQuery } from "../query";

export const privacyApi = createApi({
  reducerPath: "privacyApi",
  baseQuery: apiQuery,
  endpoints: (build) => ({
    data: build.query({
      query: () => ({
        method: "get",
        url: URLS.PRIVACY,
      }),
    }),
  }),
});

export const privacySlice = createSlice({
  name: "privacy",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      privacyApi.endpoints.data.matchFulfilled,
      (state, { payload }) => ({ ...state, payload }),
    );
  },
});

export const { useDataQuery } = privacyApi;
