import React from "react";
import Icon from "./Icon";
import colors from "../constants/colors";
import styles from "../constants/styles";
import ArrowBack from "./ArrowBack";

export default class StreamInfoMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { img, SVG, title, subtitle, styleIcon, tag, isMobile, onBackClick } =
      this.props;
    let {
      styleContainer,
      styleTextContainer,
      styleImg,
      styleTitle,
      styleTag,
      styleSubtitle,
    } = this.props;

    return (
      <div
        style={{
          ...(isMobile ? containerMobile : container),
          ...styleContainer,
        }}
      >
        <div
          style={{
            display: "flex",
            width: isMobile ? "100%" : 150,
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <ArrowBack
            onClick={() => onBackClick()}
            style={{ marginRight: 24 }}
          />
          <div style={{ ...styleIcon, width: styleImg.width }}>
            <Icon
              height={styleImg.height}
              width={styleImg.width}
              img={img}
              SVG={SVG}
            />
          </div>
        </div>

        <div
          style={{
            ...textContainer,
            ...styleTextContainer,
            width: isMobile ? "100%" : "auto",
            overflow: "hidden",
          }}
        >
          <label
            style={{
              ...styles.text12,
              ...textStyle,
              ...{ marginBottom: 2 },
              ...styleTitle,
            }}
          >
            {title}
          </label>
          <label
            style={{
              ...styles.text24Bold,
              ...textStyle,
              ...styleSubtitle,
              ...{ marginTop: 2 },
            }}
          >
            {subtitle}
          </label>
          {tag && (
            <label
              style={{
                ...styles.text16,
                ...textStyle,
                ...styleTag,
                ...{ marginTop: 4, color: colors.blue },
              }}
            >
              {tag}
            </label>
          )}
        </div>
      </div>
    );
  }
}

const container = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 112,
  width: "100%",
};
const containerMobile = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 24,
  width: "100%",
};
const textContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: 16,
  whiteSpace: "nowrap",
};

const textStyle = {
  overflow: "hidden",
};
