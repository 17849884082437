import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import styles from "../../constants/styles";

import "./style.scss";

function Privacy() {
  React.useEffect(() => {
    document.body.classList.add("privacy");

    return () => document.body.classList.remove("privacy");
  }, []);
  const { t } = useTranslation();
  return (
    <div style={sctn}>
      <Helmet>
        <title>{t("titlePrivacy")}</title>
      </Helmet>
      <section style={sctn}>
        <div style={wrapper}>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.1")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.2")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.3")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.4")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.5")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.6")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.7")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.8")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.9")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.10")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.11")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.12")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.13")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.14")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.15")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.16")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.17")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.18")}</p>
          <h2 style={{ ...styles.text24Bold, ...textSubtitle }}>
            {t("privacy.19")}
          </h2>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.20")}</p>
          <p style={{ ...styles.text16, ...textSection }}>{t("privacy.21")}</p>
        </div>
      </section>
    </div>
  );
}

const sctn = {
  backgroundColor: "white",
};

const wrapper = {
  maxWidth: "836px",
  paddingLeft: 24,
  paddingRight: 24,
  marginLeft: "auto",
  marginRight: "auto",
};

const textSection = {
  paddingTop: 10,
  lineHeight: 2.2,
};

const textSubtitle = {
  paddingTop: 30,
};

export default withRouter(Privacy);
