import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { controlError } from "../../../utils/controlError";
import Input from "../../Input";
import { ErrorLabel } from "../../error-label";

export const InputMasks = {};

export const InputField = ({
  name,
  mask,
  label,
  style,
  onChange,
  multiline,
  inputStyle,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const error = t(
    controlError(meta, name, t(label ?? placeholder ?? "") || "") || "",
  );

  const handleChange = (e) => {
    const value = e.target.value;
    if (mask) {
      helpers.setValue(mask(value));
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <div style={style}>
      <Input
        error={error}
        value={field.value}
        multiline={multiline}
        inputStyle={inputStyle}
        label={t(label ?? "") || ""}
        onBlur={() => helpers.setTouched(true)}
        placeholder={t(placeholder ?? "") || ""}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            handleChange(e);
          }
        }}
        {...props}
      />
      {error && <ErrorLabel text={error} />}
    </div>
  );
};
