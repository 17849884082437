import axios from "axios";

import { tokens } from "../utils/tokens";
import { logoutThunk } from "../store/services/user";
import { setAuthorizationRequired } from "../store/services/appState";
import { API_URL } from "./constants";

export const apiInstance = axios.create({
  baseURL: API_URL,
});

apiInstance.interceptors.request.use(async (config) => {
  const token = await tokens.readAccessToken();
  const isValidToken = token && token !== "null";

  const newConfig = { ...config };

  if (isValidToken) newConfig.headers.Authorization = `Token ${token}`;
  else delete newConfig.headers.Authorization;

  return newConfig;
});

const handleResponseError = async (dispatch, error) => {
  if (error && error.response && error.response.status === 401) {
    await dispatch(logoutThunk());
    dispatch(setAuthorizationRequired(true));
  }
  return Promise.reject(error);
};

export const interceptor = (dispatch) => {
  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => handleResponseError(dispatch, error),
  );
};
