import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../Button";
import "./style.scss";

const Alert = ({
  title,
  message,
  onConfirm,
  error = false,
  success = false,
  positiveBtn = undefined,
  negativeBtn = undefined,
  isMobile = false,
}) => {
  return (
    <SweetAlert
      error={error}
      success={success}
      title={<p className="alert-title text-24-demi-bold">{title}</p>}
      onConfirm={onConfirm}
      customButtons={
        negativeBtn && positiveBtn ? (
          <label
            style={{
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              title={negativeBtn.title}
              variant="red"
              style={{ minWidth: 156, margin: 24 }}
              onClick={negativeBtn.onClick}
            />
            <Button
              title={positiveBtn.title}
              variant="blue"
              style={{ minWidth: 156, margin: 24 }}
              onClick={positiveBtn.onClick}
            />
          </label>
        ) : (
          <Button
            title={positiveBtn ? positiveBtn.title : "OK"}
            variant="blue"
            style={{ width: 156 }}
            onClick={onConfirm}
          />
        )
      }
    >
      <p className="alert-message text-16">{message}</p>
    </SweetAlert>
  );
};

export default Alert;
