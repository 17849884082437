import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Header from "../../components/Header";
import Button from "../../components/Button";
import utils from "../../utils/utils";
import styles from "../../constants/styles";

class StreamInApprove extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };
  }

  clearHistory = () => {
    window.location.reload();
  };

  openMyStreams = () => {
    this.props.history.entries = [];
    this.props.history.index = -1;
    this.props.history.push("/mystreams");
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    if (this.state.edit)
      return <Redirect to={"streamedit?id=" + utils.getParam("id")} />;
    const { t, isMobile } = this.props;

    return (
      <div>
        <Header isMobile={isMobile} />
        <div
          className={
            isMobile
              ? "my-create-streams-container-mobile"
              : "my-create-streams-container"
          }
        >
          <label
            style={{
              ...(isMobile
                ? { ...styles.text24Bold, marginTop: 40 }
                : styles.text40Bold),
            }}
          >
            {t("streamSentOnReview")}
          </label>
        </div>
        <div style={isMobile ? subTitleContainerMobile : subTitleContainer}>
          <label style={{ ...styles.text16, ...{ marginTop: 8 } }}>
            {t("streamSentOnReviewDesc")}
          </label>
        </div>
        <div style={isMobile ? buttonsContainerMobile : buttonsContainer}>
          <Button
            variant={"light"}
            onClick={() => this.setState({ edit: true })}
            style={{ width: isMobile ? "100%" : 197 }}
            title={t("editStreamInfo")}
          />
          <Button
            variant={"blue"}
            onClick={this.openMyStreams}
            style={isMobile ? { width: "100%", marginTop: 24 } : { width: 148 }}
            title={t("continue")}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(StreamInApprove));

const subTitleContainer = {
  width: 867,
  height: 48,
  marginLeft: 80,
};

const subTitleContainerMobile = {
  width: "90%",
  marginLeft: "5%",
  display: "flex",
  marginBottom: 24,
};

const buttonsContainer = {
  display: "flex",
  justifyContent: "space-between",
  width: 385,
  marginTop: 40,
  height: 48,
  marginLeft: 80,
};
const buttonsContainerMobile = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "90%",
  marginTop: 24,
  marginLeft: "5%",
};
