import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Button from "../../components/Button";
import PostBase from "./PostBase";
import api from "../../network/api";

class PostAdd extends PostBase {
  componentDidMount() {
    this.setState({
      headerTitle: this.props.t("newPost"),
    });
  }

  post = async () => {
    let message = this.check();
    if (message) {
      this.setState({
        errorMsg: message,
      });
      return;
    }
    this.setState({ posting: true });
    let data = new FormData();
    data.append("stream", this.stream_id);
    data.append("title", this.state.title);
    data.append("text", this.state.text);
    if (this.state.img) data.append("img", this.state.img_file);

    let result = await api.create_news(data);
    if (!result.error) {
      this.goBack();
    } else {
      this.setState({
        errorMsg: result.error,
      });
      this.setState({ posting: false });
    }
  };

  renderButtons = () => {
    return (
      <Button
        variant={"blue"}
        onClick={this.post}
        isLoading={this.state.posting}
        style={{ width: this.isMobile ? "100%" : 170 }}
        title={this.props.t("publishPost")}
      />
    );
  };
}

export default withRouter(withTranslation()(PostAdd));
