import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { InputField } from "../../components";
import Button from "../../components/Button";
import FacebookButton from "../../components/FacebookButton";
import Footer from "../../components/Footer";
import colors from "../../constants/colors";
import { isFacebookLoginEnabled } from "../../constants/config";
import styles from "../../constants/styles";
import { useAlert } from "../../hooks";
import api from "../../network/api";
import { useLazyMeQuery, useLoginMutation } from "../../store/services/user";
import { tokens } from "../../utils/tokens"; // Your Tokens implementation
import {
  initialValues,
  model,
  validationSchema,
} from "../../yup-configs/login";

function Login({ history, isMobile }) {
  const { t } = useTranslation();
  const [isAuthorized, setIsAuthorized] = useState(
    localStorage.getItem("token"),
  );
  const [fbRegisterToken, setFbRegisterToken] = useState(undefined);

  const [handleLogin, mutation] = useLoginMutation();
  const [loadUser] = useLazyMeQuery();
  const isLoading = mutation.isLoading;

  const { renderAlert, errorMsg, setErrorMsg } = useAlert({ isMobile });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 992) {
        window.location.reload();
      }
    }

    if (window.attachEvent) {
      window.attachEvent("onresize", handleResize);
    } else if (window.addEventListener) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  const saveUser = async (token) => {
    await tokens.saveAccessToken(token);
    await loadUser();
    setIsAuthorized(true);
  };

  const submitLogin = async ({ username, password }) => {
    if (fbRegisterToken) {
      const result = await api.facebook_login(fbRegisterToken, username);
      if (result.token) {
        saveUser(result.token);
      } else {
        setErrorMsg(result.error);
        setFbRegisterToken(undefined);
      }
      return;
    }

    const { data } = await handleLogin({ username, password });
    if (!data?.error) {
      saveUser(data.token);
      return;
    }

    setErrorMsg(t("invalidDataError"));
  };

  const openSignup = () => {
    history.push("/signup");
  };

  const openForgotPassword = () => {
    history.push("/forgotpassword");
  };

  const onSubmit = async (values) => {
    await submitLogin(values);
  };

  if (isAuthorized) {
    return <Redirect to="mystreams" />;
  }

  return (
    <div className={"container"}>
      <Helmet>
        <title>{t("titleLogin")}</title>
      </Helmet>

      <div className={isMobile ? "logo-container-mobile" : "logo-container"}>
        <Logo className={"auth-logo-image"} alt={"logo-image"} />
      </div>

      <div className={isMobile ? "form-container-mobile" : "form-container"}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ dirty, isSubmitting, errors, submitForm }) => (
            <div className={isMobile ? "formMobile" : "form"}>
              <InputField
                onKeyDown={(e) => e.key === "Enter" && submitForm()}
                {...model.username}
              />

              {!fbRegisterToken && (
                <InputField
                  onKeyDown={(e) => e.key === "Enter" && submitForm()}
                  {...model.password}
                />
              )}
              {!fbRegisterToken && (
                <div
                  className={
                    isMobile ? "buttons-container-mobile" : "buttons-container"
                  }
                >
                  <label
                    onClick={openForgotPassword}
                    style={{
                      ...styles.text16,
                      ...{ color: colors.blue, fontWeight: "bold" },
                    }}
                    className={
                      isMobile
                        ? "login-container-label-mobile"
                        : "login-container-label"
                    }
                  >
                    {t("forgotPassword")}
                  </label>
                  <Button
                    variant={"blue"}
                    onClick={submitForm}
                    style={{ width: isMobile ? "100%" : 160 }}
                    title={isLoading ? `${t("Loading")}...` : t("login")}
                    disabled={
                      !dirty || isSubmitting || Object.keys(errors).length > 0
                    }
                  />
                </div>
              )}
              {fbRegisterToken ? (
                <div className={"buttons-container"}>
                  <Button
                    variant={"blue"}
                    onClick={submitLogin}
                    style={{ width: 400, height: 56 }}
                    title={t("signup")}
                  />
                </div>
              ) : (
                <div className={"divider-container"}>
                  <div className={"line"} />
                  <label
                    style={{
                      ...styles.text16,
                      ...{ color: colors.gray, paddingBottom: 2 },
                    }}
                  >
                    {t("or")}
                  </label>
                  <div className={"line"} />
                </div>
              )}
              {isFacebookLoginEnabled && !fbRegisterToken && (
                <FacebookButton
                  onSuccess={saveUser}
                  styleButton={{ width: isMobile ? "100%" : "400px" }}
                  text={isLoading ? `${t("Loading")}...` : t("loginFB")}
                  onEmailNotFound={(token) => {
                    setFbRegisterToken(token);
                  }}
                />
              )}
              {!fbRegisterToken && (
                <div style={linkContainerStyle}>
                  <label style={{ ...styles.text16 }}>{t("noAccount")}</label>
                  <label
                    style={{ ...styles.text16DemiBold, ...linkStyle }}
                    onClick={() => openSignup()}
                  >
                    {t("signup")}
                  </label>
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
      <Footer t={t} />
      {errorMsg && renderAlert()}
    </div>
  );
}

export const linkStyle = {
  color: colors.blue,
  marginLeft: 8,
  cursor: "pointer",
};

export const linkContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: 40,
};

export default withRouter(Login);
