import React from "react";
import styled from "styled-components";
import styles from "../constants/styles";

export default class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      options: this.props.options,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClick = () => {
    if (this.wrapperRef) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.close();
    }
  };

  close() {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    let { onChange, Icon, label } = this.props;
    let { styleList } = this.props;
    return (
      <div ref={this.setWrapperRef} style={container}>
        <div
          style={header}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          {Icon ? Icon : null}
          <label
            style={{
              ...styles.text16,
              ...{ color: "black", cursor: "pointer" },
            }}
          >
            {label}
          </label>
        </div>
        {this.state.isOpen ? (
          <div style={{ ...list, ...styleList }}>
            {this.state.options.map((el, index) => {
              return (
                <Item
                  key={index.toString()}
                  onClick={() => {
                    onChange(el.value);
                    this.setState({ isOpen: false });
                  }}
                >
                  <el.SVG style={listIcon} />
                  <label style={{ ...styles.text16, ...listItem }}>
                    {el.label}
                  </label>
                </Item>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

const container = {
  position: "relative",
};

const header = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  cursor: "pointer",
};

const list = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "absolute",
  backgroundColor: "white",
  top: 60,
  padding: 16,
  paddingBottom: 16,
  borderRadius: 16,
  right: -10,
  boxShadow: `0px 24px 48px rgba(0, 0, 0, 0.04)`,
  zIndex: 9999,
};

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
`;

const listIcon = {
  height: 24,
  width: 24,
  marginRight: 16,
};

const listItem = {
  color: "black",
  fontSize: 16,
  cursor: "pointer",
};
