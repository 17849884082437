import * as Styles from "./styles";

export const ErrorLabel = ({ text }) => {
  return (
    <div>
      <Styles.ErrorContainer>
        <Styles.Error>{`${text}`}</Styles.Error>
      </Styles.ErrorContainer>
    </div>
  );
};
