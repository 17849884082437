import { createSelector } from "reselect";
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";

import Roles from "../../constants/Roles";
import { URLS, LIMITS } from "../../api/constants";

import { apiQuery } from "../query";

const tagTypes = ["FavoriteStream"];

export const streamApi = createApi({
  reducerPath: "streamApi",
  tagTypes,
  baseQuery: apiQuery,
  endpoints: (build) => ({
    streamById: build.query({
      query: ({ id }) => ({
        url: `${URLS.STREAMS}${id}`,
        method: "get",
      }),
    }),

    list: build.query({
      query: (payload) => {
        const { values = "" } = payload ?? {};
        const params = new URLSearchParams({
          values: values?.toString(),
        });

        return {
          url: `${URLS.STREAM_LIST}?${params}`,
          method: "get",
        };
      },
    }),

    favoriteList: build.query({
      query: () => ({
        url: URLS.FAVORITES_STREAMS,
        method: "get",
      }),
      providesTags: tagTypes,
    }),

    favorite: build.mutation({
      query: ({ id }) => ({
        url: URLS.FAVORITES_STREAMS,
        method: "post",
        body: { stream_id: id },
      }),
      invalidatesTags: tagTypes,
    }),

    search: build.query({
      query: ({
        role,
        genre,
        filter,
        action,
        offset = 0,
        isTrending,
        limit = LIMITS.DEF_LIMIT,
      }) => {
        const params = new URLSearchParams({
          limit,
          offset,
          action,
          role: role ?? "",
          tag: filter ?? "",
          genre: genre ?? "",
          trending: isTrending,
        });

        return {
          url: `${URLS.SEARCH_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    star: build.query({
      query: () => ({
        url: `${URL.SEARCH_STREAMS}?limit=${1}&role=${Roles.star}`,
        method: "get",
      }),
    }),

    stations: build.query({
      query: (payload) => {
        const { isRandom } = payload ?? {};

        const params = new URLSearchParams({
          role: Roles.radiostation,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        if (isRandom) {
          params.append("isRandom", true);
        }

        return {
          url: `${URLS.SEARCH_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    sounds: build.query({
      query: (payload) => {
        const { isRandom } = payload ?? {};

        const params = new URLSearchParams({
          role: Roles.sounds,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        if (isRandom) {
          params.append("isRandom", true);
        }

        return {
          url: `${URL.SEARCH_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    podcasts: build.query({
      query: (payload) => {
        const { isRandom } = payload ?? {};

        const params = new URLSearchParams({
          role: Roles.podcast,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        if (isRandom) {
          params.append("isRandom", true);
        }

        return {
          url: `${URL.SEARCH_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    mixedSounds: build.query({
      query: () => {
        const params = new URLSearchParams({
          isRandom: true,
          role: Roles.sounds,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        return {
          url: `${URL.SEARCH_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    trending: build.query({
      query: () => {
        const params = new URLSearchParams({
          trending: true,
          role: Roles.radiostation,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        return {
          url: `${URLS.GET_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    trendingPodcasts: build.query({
      query: () => {
        const params = new URLSearchParams({
          trending: true,
          role: Roles.podcast,
          limit: LIMITS.HOME_STREAM_LIMIT,
        });

        return {
          url: `${URLS.GET_STREAMS}?${params.toString()}`,
          method: "get",
        };
      },
    }),

    metadata: build.query({
      query: ({ stream }) => {
        let url = stream?.stream_url;
        const regex = /^https?:\/\/[^/]+/;
        [url] = url.match(regex);

        const params = new URLSearchParams({
          url,
          stream_id: stream?.id,
        });

        return {
          method: "get",
          url: `${URLS.METADATA}?${params.toString()}`,
        };
      },
    }),

    podcastClick: build.mutation({
      query: ({ id }) => ({
        method: "post",
        url: URLS.PODCAST_CLICK(id),
      }),
    }),

    toogleStreamLike: build.mutation({
      query: ({ id }) => ({
        body: { id },
        method: "post",
        url: URLS.TOOGLE_STREAM_LIKE,
      }),
    }),

    toogleTrackLike: build.mutation({
      query: ({ id }) => ({
        method: "post",
        body: { track_id: id },
        url: URLS.TOOGLE_TRACK_LIKE,
      }),
    }),
    userStreams: build.query({
      query: ({ userId, role }) => ({
        method: "get",
        url: URLS.USER_STREAMS + userId + "/?role=" + role.toString(),
      }),
    }),
  }),
});

export const streamSlice = createSlice({
  name: "stream",
  initialState: {
    userStreams: {},
  },
  reducers: {
    clearSearch: (state) => ({ ...state, search: [] }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      streamApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        if (state.search)
          return { ...state, search: [...state.search, ...payload] };
        return { ...state, search: payload };
      },
    );

    builder.addMatcher(
      streamApi.endpoints.streamById.matchFulfilled,
      (state, { payload }) => {
        if (payload.role === Roles.radiostation) {
          const filtered = state.stations.filter(
            (item) => item?.id !== payload.id,
          );
          return { ...state, stations: [...filtered, payload] };
        }
        if (payload.role === Roles.sounds) {
          const filtered = state.sounds.filter(
            (item) => item?.id !== payload.id,
          );
          return { ...state, sounds: [...filtered, payload] };
        }
      },
    );

    builder.addMatcher(
      streamApi.endpoints.list.matchFulfilled,
      (state, { payload }) => ({ ...state, favorites: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.star.matchFulfilled,
      (state, { payload }) => ({ ...state, star: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.trending.matchFulfilled,
      (state, { payload }) => ({ ...state, trending: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.trendingPodcasts.matchFulfilled,
      (state, { payload }) => ({ ...state, trendingPodcasts: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.stations.matchFulfilled,
      (state, { payload }) => ({ ...state, stations: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.sounds.matchFulfilled,
      (state, { payload }) => ({ ...state, sounds: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.podcasts.matchFulfilled,
      (state, { payload }) => ({ ...state, podcasts: payload }),
    );

    builder.addMatcher(
      streamApi.endpoints.mixedSounds.matchFulfilled,
      (state, { payload }) => ({ ...state, mixedSounds: payload }),
    );
    builder.addMatcher(
      streamApi.endpoints.userStreams.matchFulfilled,
      (state, { payload, meta }) => {
        const { role } = meta?.arg?.originalArgs ?? {};
        return {
          ...state,
          userStreams: { ...state.userStreams, [role]: payload },
        };
      },
    );
  },
});

const {
  actions: { clearSearch },
} = streamSlice;

const streamStore = (store) => store.stream;

export const selectSearchList = () =>
  createSelector([streamStore], (store) => store.search || []);

export const selectTrendingList = () =>
  createSelector([streamStore], (store) => store.trending || []);

export const selectFavoritesStreams = () =>
  createSelector([streamStore], (store) => store.favorites);

export const selectAllStreams = () =>
  createSelector([streamStore], (store) => store || []);

export const selectUserStreams = () =>
  createSelector([streamStore], (store) => store.userStreams || {});

export { clearSearch };
export const {
  useStarQuery,
  useListQuery,
  useSoundsQuery,
  useMetadataQuery,
  useTrendingQuery,
  useStationsQuery,
  usePodcastsQuery,
  useLazySearchQuery,
  useLazySoundsQuery,
  useFavoriteMutation,
  useMixedSoundsQuery,
  useFavoriteListQuery,
  useLazyStationsQuery,
  useLazyStreamByIdQuery,
  useLazyUserStreamsQuery,
  usePodcastClickMutation,
  useTrendingPodcastsQuery,
  useToogleTrackLikeMutation,
  useToogleStreamLikeMutation,
} = streamApi;
